@use 'sass:math';

.p-chip {
    background-color: $chipBg;
    color: $chipTextColor;
    border-radius: $chipBorderRadius;
    padding: 0 nth($inputPadding, 2);

    .p-chip-text {
        line-height: 1.5;
        margin-top: math.div(nth($inputPadding, 1), 2);
        margin-bottom: math.div(nth($inputPadding, 1), 2);
    }

    .p-chip-icon {
        margin-right: $inlineSpacing;
    }

    .pi-chip-remove-icon {
        margin-left: $inlineSpacing;
    }

    img {
        width: 1.5 + nth($inputPadding, 1);
        height: 1.5 + nth($inputPadding, 1);
        margin-left: -1 * nth($inputPadding, 2);
        margin-right: $inlineSpacing;
    }

    .pi-chip-remove-icon {
        border-radius: $borderRadius;
        transition: $actionIconTransition;

        &:focus-visible {
            @include focused();
        }

        &:focus {
            outline: 0 none;
        }
    }
}
