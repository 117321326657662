ngx-extended-pdf-viewer {
    .html {
        #mainContainer {
            input {
                accent-color: $primaryColor;
            }

            #toolbarViewer {
                button.toolbarButton {
                    &:hover {
                        color: $primaryColor;
                    }

                    &:focus {
                        border: 1px solid $primaryColor;
                    }

                    @media screen and (max-width: 436px), (max-width: 768px) {
                        width: 28px;
                    }
                }
            }

            #findbar {
                button.toolbarButton {
                    &:hover {
                        color: $primaryColor;
                    }

                    &:focus {
                        border: 1px solid $primaryColor;
                    }
                }

                input.toolbarField[type='checkbox'] {
                    &:focus {
                        outline: none;
                    }
                }
            }
        }
    }
}

.toolbarField.pageNumber {
    @media screen and (max-width: 576px) {
        width: 16px !important;
    }
}

@media screen and (max-width: 480px) {
    #toolbarViewerMiddle {
        position: inherit !important;
    }
}
