.p-sidebar {
    background: $sidebarBg;
    color: $sidebarTextColor;
    border: $overlayContentBorder;
    box-shadow: $overlayContainerShadow;

    .p-sidebar-header {
        padding: $panelHeaderPadding;

        .p-sidebar-close,
        .p-sidebar-icon {
            @include action-icon();
        }

        & + .p-sidebar-content {
            padding-top: 0;
        }
    }

    .p-sidebar-content {
        padding: $panelContentPadding;
    }

    .p-sidebar-footer {
        padding: $panelHeaderPadding;
    }

    .p-panelmenu .p-panelmenu-header-content > a {
        background-color: $sidebarBg;
        color: $sidebarTextColor !important;
        border: none;
        border-radius: 0 !important;
        font-weight: 400;
        font-size: 1rem;
    }

    .p-panelmenu {
        .p-panelmenu-header-content > a {
            &.p-menuitem-link-active {
                background-color: $white;
                color: $activeSidebarMenuItemTextColor !important;
                border: none;
            }
        }
    }
}

.desktop-sidebar {
    .p-panelmenu {
        .p-panelmenu-header-content > a {
            &:hover,
            &:active {
                color: $activeSidebarMenuItemTextColor !important;
                background-color: $white;
                border: none;
            }
        }
    }
}
