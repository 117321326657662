.display-lg-block {
    display: none !important;
}

@media screen and (max-width: 992px) {
    .display-lg-block {
        display: block !important;
    }

    table {
        table-layout: fixed;
    }

    .show-on-mobile {
        display: inline-flex;
    }

    .p-datatable {
        &.p-datatable-sm {
            .p-datatable-thead > tr > th,
            .p-datatable-tfoot > tr > td {
                display: none !important;
            }

            .p-datatable-tbody {
                td.document-name {
                    display: table-cell;
                    width: 100%;
                    align-self: stretch;
                    align-content: center;
                    white-space: nowrap;
                    text-overflow: ellipsis;
                    overflow: hidden;
                }

                span.document-name {
                    display: table-cell;
                    width: 100%;
                    align-self: stretch;
                    align-content: center;
                    white-space: nowrap;
                    text-overflow: ellipsis;
                    overflow: hidden;
                }
            }

            .p-datatable-tbody > tr > td {
                text-align: left;
                display: block;
                float: left;
                clear: left;
                border: 0 none;

                &.hide-on-mobile {
                    display: none;
                }

                &.context-expand-buttons-holder {
                    display: flex;
                }
            }

            .p-datatable-tbody > tr {
                display: flex;
                align-items: center;
                border-bottom: 1px solid $primaryLightColor;

                &:last-child {
                    border-bottom: 1px solid $primaryMediumColor;
                }
            }
        }
    }
}

.p-datatable-thead {
    th.p-sortable-column {
        span.p-sortable-column-badge {
            display: none !important;
        }
    }
}

.p-datatable .p-sortable-column:focus {
    box-shadow: none;
}

.p-datatable .p-sortable-column.p-highlight {
    color: $primaryColor;

    .p-sortable-column-icon {
        color: $primaryColor;
    }
}

.p-button.p-button-success.p-button-text,
.p-buttonset.p-button-success > .p-button.p-button-text,
.p-button-success > .p-button.p-button-text {
    color: $primaryColor;

    &:hover {
        color: $primaryColor;
    }
}
