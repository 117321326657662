@import '_variables.scss';

.breadcrumb {
    display: flex;
    align-items: center;
    background: var(--headline-bg-color);
    height: 58px;
    overflow-y: auto;

    .p-breadcrumb {
        font-style: normal;
        overflow: hidden;

        .p-breadcrumb-list {
            height: 100%;
        }

        & ol > li {
            white-space: nowrap;

            .p-menuitem-link {
                font-weight: 600;
                font-size: 1rem;
                &:focus {
                    box-shadow: none;
                }
            }
            .p-menuitem-text {
                line-height: normal;
                overflow: hidden;
                text-overflow: ellipsis;
                max-width: 9rem;
            }

            .p-icon {
                width: 20px;
                height: 20px;
            }
        }
    }

    .p-breadcrumb-home {
        homeicon {
            display: none;
        }
        .p-menuitem-text {
            font-size: 1.25rem;
            line-height: 1.25rem;
        }
    }

    .p-menuitem-separator:last-of-type {
        // TODO: Check when there's just one chevron and no others after it then hide it.
        display: none !important;
    }

    @media (orientation: landscape) and (max-width: $lg-size) {
        height: 40px;
        overflow: hidden !important;
    }
}

@media screen and (max-width: 768px) {
    .breadCrumb {
        .p-breadcrumb-home {
            span {
                font-size: 14px;
                font-weight: 400;
            }
        }

        .p-breadcrumb ul li .p-menuitem-link .p-menuitem-text {
            font-size: 14px;
            font-weight: 400;
        }
    }
}

@media screen and (max-width: 576px) {
    .breadCrumb {
        .p-breadcrumb.p-component {
            height: 40px;
        }
    }
}
