/// Border of an overlay container element such as dialog or overlaypanel
/// @group overlay
$overlayContentBorder: 0 none;

/// Background of an overlay container element such as dialog or overlaypanel
/// @group overlay
$overlayContentBg: $primaryDarkestColor;

/// Box shadow of an overlay container element such as dialog or overlaypanel
/// @group overlay
$overlayContainerShadow: 0 0 14px 0 rgba(0, 0, 0, 0.1);

/// Background of a dialog header
/// @group overlay
$dialogHeaderBg: $primaryDarkestColor;

/// Border of a dialog header
/// @group overlay
$dialogHeaderBorder: 1px solid $primaryDarkestColor;

/// Text color of a dialog header
/// @group overlay
$dialogHeaderTextColor: $textColor;

/// Font weight of a dialog header
/// @group overlay
$dialogHeaderFontWeight: 400;

/// Font size of a dialog header
/// @group overlay
$dialogHeaderFontSize: 1.125rem;

/// Padding of a dialog header
/// @group overlay
$dialogHeaderPadding: 1rem 1.5rem;

/// Padding of a dialog content
/// @group overlay
$dialogContentPadding: 0 1.5rem 1rem;

/// Border of a dialog footer
/// @group overlay
$dialogFooterBorder: 1px solid $primaryDarkestColor;

/// Padding of a dialog footer
/// @group overlay
$dialogFooterPadding: 0.5rem 1.5rem 1.5rem;

/// Background of a tooltip
/// @group overlay
$tooltipBg: $primaryDarkerColor;

/// Text color of a tooltip
/// @group overlay
$tooltipTextColor: #ffffff;

/// Padding of a tooltip
/// @group overlay
$tooltipPadding: $inputPadding;
