@import './general-module';
@import './pdf-display';
@import './tables';
@import './breadcrumb';
@import './forwarding';

html {
    //Prevent font scaling in landscape while allowing user zoom
    -webkit-text-size-adjust: 100%;

    #main-wrapper {
        min-height: 100%;
        height: 100%;
        min-width: 320px;
    }
}

body {
    margin: 0;
    min-width: 320px;

    font-family: $fontFamily !important;
    background-color: var(--background-color);
    color: var(--text-color);

    a {
        color: var(--link-color);
        cursor: pointer;

        &:hover {
            color: var(--link-color-hover);
        }
    }

    .cursor-default {
        a {
            &.p-menuitem-link {
                cursor: default;
            }
        }
    }
}

// outlined buttons
.p-button {
    &.p-button-outlined {
        &:not(.p-button-success):not(.p-button-secondary):not(.p-button-danger):not(.menu-burger):not(.p-button-filled) {
            .p-button-icon,
            .p-button-label {
                color: var(--outlined-btn-color);
            }
        }

        &:not(.p-button-danger):not(.p-button-secondary) {
            &:enabled:hover,
            &:enabled:active {
                border-color: var(--outlined-btn-color);
                background-color: transparent;
            }
        }
    }

    &.p-button-filled {
        background-color: var(--surface-20) !important;

        .p-button-label {
            color: var(--surface-10);
        }
    }
}

//p-tree styles
.p-tree {
    border: none;
    .p-tree-container {
        .p-treenode {
            .p-treenode-content {
                border-bottom: 2px solid $primaryLightColor;
                border-radius: 0;
            }
        }
    }
}

//disabled inputs
input {
    & .p-disabled {
        background-color: $primaryLightColor;
        color: $charcoal;
    }
}

// max width 600px (e.g. "Zustimmung zur digitalen Zustellung" dialog, panels in settings (old cards), "Erstbescheinigung einreichen" (online services) wrapper)
.max-w-600px {
    max-width: 600px;
}

// dialog
.dynamic-optin-optout-dialog {
    .p-dialog-header {
        background-color: var(--surface-f);
        border-bottom: 1px solid var(--surface-700);

        .p-dialog-title {
            color: var(--text-color);
        }
    }

    .p-dialog-content {
        padding-bottom: 0;
    }
}

.p-dialog-header {
    align-items: flex-start;

    .p-dialog-header-close {
        &.p-link {
            margin-top: -2px;
        }
    }
}

@media screen and (max-width: 768px) {
    .dynamic-optin-optout-dialog {
        width: 90%;
        max-height: 90%;
    }
}

//IDnow dialog
@media (max-width: $xs-size) {
    .idnow-dialog {
        &.p-dialog {
            height: 100vh !important;
            max-height: 100vh;

            .p-dialog-content {
                padding: 0;
            }
        }
    }
}

.idnow-dialog {
    &.p-dialog {
        .p-dialog-header {
            padding: 0;
        }
    }
}

//NPA dialog
.npa-dialog {
    .p-dialog-content {
        padding-top: 0.5rem;
    }
}

// preserve header height when hiding close button
.bk-dialog-header-min-height .p-dialog-header {
    min-height: 65px;
}

//p-card
.p-card {
    border: 1px solid var(--surface-400);

    .p-card-separator {
        border: 1px solid var(--surface-400);
    }
}

//calendar width on smaller views
@media screen and (max-width: $lg-size) {
    .p-calendar {
        .p-datepicker {
            width: 19rem;

            &:has(.p-timepicker) {
                width: auto;
            }
        }
    }
}

// migration from
// new-db-registration-shared.scss
.db-register-container {
    &.wrapper {
        max-width: 1080px;
    }
}

// represent readonly user data
.custom-paragraph-reg-stepper {
    border: 1px solid #d3d3d3;
    border-radius: 5px;
    padding: 0.6rem;
    min-height: 43px;
}

.break {
    word-break: normal;
    overflow-wrap: anywhere;
}

.grid {
    display: flex !important;
}

.text-danger {
    color: var(--danger-color);
}

.text-success {
    color: var(--success-color);
}

.invert-image-colors {
    filter: invert(0.6);
}

// TODO do we really need this?
// if not clean up.
.color-bk-dark-gray {
    color: $charcoal;
}

.read-only-data {
    background: var(--surface-f);
    color: var(--surface-800);

    &:hover {
        border-color: $light-pewter;
    }
}

.word-break-all {
    word-break: break-all;
}

// Please use this to break long text smarter
.text-break {
    overflow-wrap: anywhere;
}

.white-space-pre-wrap {
    white-space: pre-wrap;
}

.white-space-break-spaces {
    white-space: break-spaces;
}

//Toast message position
@media screen and (max-width: $md-size) {
    p-toast {
        .p-toast {
            width: 100%;
            top: 0;
            right: 0;
            left: 0;
        }

        @media (orientation: portrait) {
            &.is-ios-native {
                .p-toast {
                    top: 50px;
                }
            }
        }
    }
}

//style for truncating text
.truncate-text {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}

//style for break long (file)names
.break-word {
    word-break: break-word;
}

.visually-hidden,
.visually-hidden-focusable:not(:focus):not(:focus-within) {
    position: absolute !important;
    width: 1px !important;
    height: 1px !important;
    padding: 0 !important;
    margin: -1px !important;
    overflow: hidden !important;
    clip: rect(0, 0, 0, 0) !important;
    white-space: nowrap !important;
    border: 0 !important;
}

.p-inputtext::placeholder {
    color: var(--input-placeholder);
}

//p-tooltip style for file name tooltip
.p-tooltip.file-name {
    max-width: 50%;
}

//  archive-storage-widget
.archive-storage-widget {
    background-color: var(--background-color);
    color: var(--text-color);
}

// file upload
.file-upload-modal {
    .drag-and-drop-area {
        &.draggable-area {
            border: 1px dashed #cccccc;

            &.error {
                border-color: var(--danger-color);
            }
        }
    }

    .p-dialog-header {
        border: none;
    }

    .p-fileupload .p-fileupload-buttonbar {
        background-color: transparent;
        color: var(--text-color);
    }

    .upload-icon {
        path {
            fill: var(--text-color);
        }
    }

    .error {
        svg {
            path {
                fill: var(--danger-color);
            }
        }

        .error-text {
            color: var(--danger-color);
        }
    }

    .warning-text {
        color: var(--warning-color);
    }

    .quote-red {
        .p-progressbar {
            .p-progressbar-value {
                background: var(--danger-color);
                .p-progressbar-label {
                    color: var(--surface-50);
                }
            }
        }
    }
}

// error page
.error-page-title {
    color: var(--danger-color);
}
