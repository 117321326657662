:root,
[data-theme='light'] {
    font-family: #{$fontFamily};
    --font-family: #{$fontFamily};
    --surface-a: #{$white};
    --surface-b: #f8f9fa;
    --surface-c: #e9ecef;
    --surface-d: #dee2e6;
    --surface-e: #{$white};
    --surface-f: #{$primaryLightColor}; //$pearl-white: #eeeeee;

    --text-color: #{$black};
    --text-color-secondary: #{$primaryDarkerColor};
    --primary-color: #{$primaryColor};
    --primary-color-text: #{$white};

    --surface-0: #{$white};
    --surface-10: #{$primaryDarkerColor};
    --surface-20: #{$white};
    --surface-50: #{$white};
    --surface-60: #{$white};
    --surface-100: #{$white};
    --surface-200: #{$primaryLightColor};
    --surface-300: #{$primaryDarkerColor};
    --surface-400: #{$primaryMediumColor}; // #999999;
    --surface-500: #9e9e9e;
    --surface-600: #000000;
    --surface-700: #{$textColor};
    --surface-800: #{$gunmetal-gray}; // #303030
    --surface-900: #{$black};

    --content-padding: #{$panelContentPadding};
    --inline-spacing: #{$inlineSpacing};
    --border-radius: #{$borderRadius};

    --surface-ground: #{$primaryLightColor};
    --surface-section: #{$white};
    --surface-card: #{$white};
    --surface-overlay: #{$white};
    --surface-sidebar: #{$primaryDarkerColor};
    --surface-border: #{$primaryLightColor};
    --surface-hover: #{$primaryLightColor};

    --maskbg: #{$maskBg};
    --highlight-bg: #{$highlightBg};
    --highlight-text-color: #{$highlightTextColor};
    --focus-ring: #{$focusShadow};

    // custom
    --primary-color: #{$primaryColor};
    --secondary-color: #{$primaryDarkColor};
    --box-shadow-color: #{$boxShadow};

    --primary-dark-color: #{$primaryDarkColor};
    --primary-darker-color: #{$primaryDarkerColor}; // $bk-dark-gray
    --primary-medium-color: #{$primaryMediumColor};
    --primary-light-color: #{$primaryLightColor}; // $bk-light-gray
    --primary-text-color: #{$primaryTextColor};

    // custom
    --white-color: #{$whiteColor};
    --success-color: #{$primaryColor};
    --info-color: #{$infoColor};
    --warning-color: #{$warningColor};
    --error-color: #{$errorColor};
    --danger-color: #{$dangerColor};
    --help-color: #{$helpColor};

    // custom
    --input-border-color: #{$inputBorderColor};

    // main background color
    --background-color: #{$white};

    --headline-bg-color: #{$light-charcoal};

    //link
    --link-color: #{$black};
    --link-color-hover: #{$primaryColor};

    --shadow-card: #0000001f;

    --outlined-btn-color: #{$primaryDarkerColor};

    --input-placeholder: #{$primaryMediumColor};

    color-scheme: light;

    @if $enable-dark-mode {
        @include color-mode(dark, true) {
            --surface-a: #eeeeee;
            --surface-b: #e4e4e4;
            --surface-c: #dbdbdb;
            --surface-d: #cccccc;
            --surface-e: #eeeeee;
            --surface-f: #{$graphite-gray};
            --text-color: #eeeeee;

            --surface-0: #{$black};
            --surface-10: #{$white};
            --surface-20: #{$graphite-gray}; //#424242
            --surface-50: #{$gunmetal-gray}; //#303030
            --surface-60: #{$primaryDarkestColor}; //#424242
            --surface-100: #{$graphite-gray};
            --surface-200: #{$primaryDarkerColor}; // $charcoal: #616061;
            --surface-300: #{$white};
            --surface-400: #{$primaryDarkerColor}; // $charcoal: #616061;
            --surface-500: #a7a7a7;
            --surface-600: #ffffff;
            --surface-700: #{$white};
            --surface-800: #{$primaryLightColor}; // #eeeeee
            --surface-900: #{$white};

            --content-padding: #{$panelContentPadding};
            --inline-spacing: #{$inlineSpacing};
            --border-radius: #{$borderRadius};

            --surface-ground: #303030;
            --surface-section: #{$primaryDarkerColor};
            --surface-card: #{$primaryDarkerColor};
            --surface-overlay: #{$primaryDarkerColor};
            --surface-sidebar: #{$primaryDarkestColor};
            --surface-border: #{$primaryMediumColor};
            --surface-hover: #{$primaryMediumColor};
            --maskbg: #{$maskBg};

            --text-color-secondary: #{$primaryMediumColor};
            --primary-color-text: #ffffff;

            --highlight-bg: #{$highlightBg};
            --highlight-text-color: #{$highlightTextColor};
            --focus-ring: #{$focusShadow};

            // main background color
            --background-color: #{$gunmetal-gray};

            --headline-bg-color: #{$charcoal};

            //link
            --link-color: #{$textColor};
            --link-color-hover: #{$primaryColor};

            --danger-color: #{$pale-red};
            --warning-color: #{$pale-golden};

            --shadow-card: #ffffffcc;
            --outlined-btn-color: #{$white};

            --input-placeholder: #{$primaryMediumColor};

            color-scheme: dark;
        }
    }
}
