// prime green color
$lime-green: #76b729;

// prime green hover color
$fern-green: #6aa424;

// not active state for prime green color
$pistachio: #a0cd6a;

// disabled state for prime green color
$translucent-lime-green: #76b72924;

// box shadow
// from themeService.
$serenity-green: #d2e2c1;

// main border cocolor and text color in ligh theme.
$steel-grey: #999999;

// main background and text color in light theme.
$pearl-white: #eeeeee;

// used as background and text color
$charcoal: #616061;

// used as background in light theme
$light-charcoal: #999999;

// error message red.
$crimson: #f44336;

// danger massages red.
$rust-red: #c7362b;

// error messages in dark mode
$pale-red: #fdced4;

// error massage background
$pale-pink: #f7e3e1;

// warning message orange
$golden-rod: #a56609;

// warning message orange in dark mode
$pale-golden: #eac98f;

// warning message background orange
$champagne: #f8eee0;

// input border color in dark theme
$light-pewter: #c6c6c6;

// main border cocolor and text color in ligh theme.
$steel-gray: #999999;

// used as border and background color in dark palele.
$graphite-gray: #424242;

// main background color in dark theme.
// borders, table and pogination
$gunmetal-gray: #303030; // (Darkest)

// dark text color
$midnight-black: #121212;

// default white;
$white: #ffffff;

// default black
$black: #000000;

//screen size
$xxxs-size: 356px;
$xxs-size: 436px;
$xs-size: 480px;
$sm-size: 576px;
$md-size: 768px;
$lg-size: 992px;
$xl-size: 1280px;

// Base color palete
// based on provided style guide
// figma -> https://t.ly/fu5UW
$palette: (
    green: $lime-green,
    // #76b729,
    green-dark: $fern-green,
    // #6aa424,
    // hover
    green-light: $pistachio,
    // #a0cd6a,
    // 70% from green
    green-thin: $translucent-lime-green,
    //#76b72924,
    // 14% from green
    gray: $steel-gray,
    // #999999,
    gray-light: $pearl-white,
    // #eeeeee,
    gray-dark: $charcoal,
    // #616061,
    red: $crimson,
    // #f44336,
    red-dark: $rust-red,
    //#c7362b,
    // hover
    red-thin: $pale-pink,
    // #f7e3e1,
    // 14% from red
    oragne: $golden-rod,
    // #cc8925,
    orange-thin: $champagne,
    // #f8eee0,
    // 14% from orange
    white: $white,
    black: $black,
);

// Theme Specific Variables
$primaryColor: $lime-green; // #76b729
$primaryDarkColor: $fern-green; // #6aa424;

$primaryMediumColor: $steel-gray; // #999999;
$primaryDarkerColor: $charcoal; // #616061; // $bk-dark-gray
$primaryDarkestColor: $graphite-gray; // #424242;

$primaryLightColor: $pearl-white; // #eeeeee; // $bk-light-gray
$primaryTextColor: $white;
$darkTextColor: $midnight-black; //#121212;

$textColor: $black;
$boxShadow: $serenity-green;

$whiteColor: $white;
$successColor: $primaryColor;
$infoColor: #b3e5fc; //#323233;
$warningColor: $golden-rod; // #A56609;
$errorColor: $crimson;
$errorColorPale: $pale-red;
$dangerColor: $rust-red;
$helpColor: #8d71bb;

$primaryBGColor: $gunmetal-gray; //#303030;

$inputBorderColor: $light-pewter; //#c6c6c6;

$fontFamily: 'Segoe UI';

$colors: (
    'blue': #2d71d7,
    'green': $lime-green,
    // #76b729,
    'orange': $golden-rod,
    // #cc8925,
    'red': $rust-red,
    // #c7362b,
    'violet': $helpColor,
    // #8d71bb,
    'primary': $primaryColor,
);

$enable-dark-mode: true !default;
$color-mode-type: data !default;

// it could be changed base during init.
// check ThemeService.
$primary-color: var(--primary-color);
