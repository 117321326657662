.sign {
    display: inline-block;
    width: 12px;
    height: 12px;
    border-radius: 50%;
    margin-right: 3px;

    &-red {
        background-color: $dangerColor;
    }

    &-green {
        background-color: $primaryColor;
    }
}
