// bitkasten special styles
.unread-document-dot {
    height: 10px;
    width: 10px;
}

// bitkasten special module styles
.p-button {
    &:focus {
        box-shadow: none;
    }

    &.p-button-secondary.p-button-text {
        &:enabled:hover,
        &:enabled:active {
            background-color: transparent;
        }

        &:enabled:focus {
            box-shadow: none;
        }
    }

    &.mobile-button {
        width: max-content;
        @media only screen and (max-width: 356px) {
            padding-left: 8px;
            padding-right: 8px;
        }
    }
}

.p-listbox {
    .p-listbox-header {
        display: none;
    }

    .p-listbox-list {
        .p-listbox-empty-message {
            padding-left: 0;
        }
    }
}

.p-toast {
    .p-toast-message {
        .p-toast-icon-close {
            &:hover {
                background: transparent;
            }
        }
    }
}

.p-messages .p-message-wrapper {
    a {
        color: $black;

        &:hover,
        &:focus {
            color: inherit;
        }
    }
}

// space between input and label
.field label {
    margin-bottom: 0.25rem;
}

// confirm dialog
.p-dialog {
    &:has(.accept-right) {
        .p-dialog-header-icons {
            display: none;
        }
    }

    &.p-confirm-dialog {
        .p-confirm-dialog-message {
            margin-left: 0;
        }
    }

    .p-dialog-header-icons {
        margin-left: auto;
    }

    .p-dialog-footer {
        display: flex;

        button {
            width: 50%;

            &.accept-right {
                justify-self: end;
                width: 25%;
            }

            &:last-child {
                margin-right: 0;
            }

            .p-icon {
                display: none;
            }
        }

        &:has(.accept-right) {
            display: grid;
        }

        .p-footer {
            display: flex;
            width: 100%;
        }
    }
}

.p-panelmenu {
    .p-panelmenu-panel:first-child {
        .p-panelmenu-header-content > a {
            border-radius: 0;
        }
    }

    .p-panelmenu-panel:last-child {
        .p-panelmenu-header-content:not(.p-highlight) > a {
            border-radius: 0;
        }
    }
}

.p-tabview {
    .p-tabview-nav-btn.p-link:focus {
        box-shadow:
            0 3px 1px -2px rgba(0, 0, 0, 0.2),
            0px 2px 2px 0px rgba(0, 0, 0, 0.14),
            0px 1px 5px 0px rgba(0, 0, 0, 0.12);
    }

    .p-tabview-nav {
        li {
            img {
                height: 20px;
            }
            .p-tabview-nav-link:not(.p-disabled):focus {
                box-shadow: none;
            }
        }

        .p-tabview-nav-link {
            color: var(--surface-700);
            font-weight: 400;
        }
        .p-highlight {
            .p-tabview-nav-link {
                font-weight: 600;
            }
        }
    }
}

.p-multiselect {
    .p-multiselect-label {
        color: $textColor;
    }
}

.p-treeselect {
    .p-treeselect-label {
        color: $textColor;
    }
}
