/// Padding of a button, must be defined with a shorthand for vertical and horizontal values e.g. ".5rem .5rem"
/// @group button
$buttonPadding: 0.5rem 1rem;

/// Width of a button having an icon with no label
/// @group button
$buttonIconOnlyWidth: 2.357rem;

/// Padding of a button having an icon with no label
/// @group button
$buttonIconOnlyPadding: 0.5rem 0;

/// Background of a button
/// @group button
$buttonBg: $primaryColor;

/// Text color of a button
/// @group button
$buttonTextColor: $primaryTextColor;

/// Border of a button
/// @group button
$buttonBorder: 1px solid $primaryColor;

/// Background of a button in hover state
/// @group button
$buttonHoverBg: $primaryDarkColor;

/// Text color of a button in hover state
/// @group button
$buttonTextHoverColor: $primaryTextColor;

/// Border color of a button in hover state
/// @group button
$buttonHoverBorderColor: $primaryDarkColor;

/// Background of a button in pressed state
/// @group button
$buttonActiveBg: $primaryDarkColor;

/// Color of a button in pressed state
/// @group button
$buttonTextActiveColor: $primaryTextColor;

/// Border color of a button in pressed state
/// @group button
$buttonActiveBorderColor: $primaryDarkColor;

/// Shadow of a raised button
/// @group button
$raisedButtonShadow:
    0px 3px 1px -2px rgba(0, 0, 0, 0.2),
    0px 2px 2px 0px rgba(0, 0, 0, 0.14),
    0px 1px 5px 0px rgba(0, 0, 0, 0.12);

/// Border radius of a rounded button
/// @group button
$roundedButtonBorderRadius: 2rem;

/// Alpha level of a text button background in hover state
/// @group button
$textButtonHoverBgOpacity: 0.04;

/// Alpha level of a text button background in active state
/// @group button
$textButtonActiveBgOpacity: 0.16;

/// Border style of a outlined button
/// @group button
$outlinedButtonBorder: 1px solid;

/// Text color of a plain text button
/// @group button
$plainButtonTextColor: $textColor;

/// Background color of a plain text button in hover state
/// @group button
$plainButtonHoverBgColor: $primaryDarkerColor;

/// Background color of a plain text button in active state
/// @group button
$plainButtonActiveBgColor: $primaryDarkerColor;

/// Background of a secondary button
/// @group button
$secondaryButtonBg: $whiteColor;

/// Text color of a secondary button
/// @group button
$secondaryButtonTextColor: $textColor;

/// Border of a secondary button
/// @group button
$secondaryButtonBorder: 1px solid $primaryDarkerColor;

/// Background of a secondary button in hover state
/// @group button
$secondaryButtonHoverBg: darken($primaryDarkerColor, 5%);

/// Text color of a secondary button in hover state
/// @group button
$secondaryButtonTextHoverColor: $primaryTextColor;

/// Border color of a secondary button in hover state
/// @group button
$secondaryButtonHoverBorderColor: darken($primaryDarkerColor, 5%);

/// Background of a secondary button in pressed state
/// @group button
$secondaryButtonActiveBg: darken($primaryDarkerColor, 5%);

/// Text color of a secondary button in pressed state
/// @group button
$secondaryButtonTextActiveColor: $primaryTextColor;

/// Border color of a secondary button in pressed state
/// @group button
$secondaryButtonActiveBorderColor: darken($primaryDarkerColor, 5%);

/// Box shadow of a secondary button in focused state
/// @group button
$secondaryButtonFocusShadow: 0 0 0 0.2rem $primaryDarkerColor;

/// Background of an info button
/// @group button
$infoButtonBg: $infoColor;

/// Text color of an info button
/// @group button
$infoButtonTextColor: #ffffff;

/// Border of an info button
/// @group button
$infoButtonBorder: 1px solid $infoColor;

/// Background of an info button in hover state
/// @group button
$infoButtonHoverBg: darken($infoColor, 5%);

/// Text color of an info button in hover state
/// @group button
$infoButtonTextHoverColor: #ffffff;

/// Border color of an info button in hover state
/// @group button
$infoButtonHoverBorderColor: darken($infoColor, 5%);

/// Background of an info button in pressed state
/// @group button
$infoButtonActiveBg: darken($infoColor, 5%);

/// Text color of an info button in pressed state
/// @group button
$infoButtonTextActiveColor: #ffffff;

/// Border color of an info button in pressed state
/// @group button
$infoButtonActiveBorderColor: darken($infoColor, 5%);

/// Box shadow of an info button in focused state
/// @group button
$infoButtonFocusShadow: 0 0 0 0.2rem lighten($infoButtonBg, 35%);

/// Background of a success button
/// @group button
$successButtonBg: $successColor;

/// Text color of a success button
/// @group button
$successButtonTextColor: #ffffff;

/// Border of a success button
/// @group button
$successButtonBorder: 1px solid $successColor;

/// Background of a success button in hover state
/// @group button
$successButtonHoverBg: darken($successColor, 5%);

/// Text color of a success button in hover state
/// @group button
$successButtonTextHoverColor: #ffffff;

/// Border color of a success button in hover state
/// @group button
$successButtonHoverBorderColor: darken($successColor, 5%);

/// Background of a success button in pressed state
/// @group button
$successButtonActiveBg: darken($successColor, 5%);

/// Text Color of a success button in pressed state
/// @group button
$successButtonTextActiveColor: #ffffff;

/// Border color of a success button in pressed state
/// @group button
$successButtonActiveBorderColor: darken($successColor, 5%);

/// Box shadow of a success button in focused state
/// @group button
$successButtonFocusShadow: 0 0 0 0.2rem lighten($successButtonBg, 35%);

/// Background of a warning button
/// @group button
$warningButtonBg: $warningColor;

/// Text color of a warning button
/// @group button
$warningButtonTextColor: $whiteColor;

/// Border of a warning button
/// @group button
$warningButtonBorder: 1px solid $warningColor;

/// Background of a warning button in hover state
/// @group button
$warningButtonHoverBg: darken($warningColor, 5%);

/// Text color of a warning button in hover state
/// @group button
$warningButtonTextHoverColor: $whiteColor;

/// Border color of a warning button in hover state
/// @group button
$warningButtonHoverBorderColor: darken($warningColor, 5%);

/// Background of a warning button in pressed state
/// @group button
$warningButtonActiveBg: darken($warningColor, 5%);

/// Text color of a warning button in pressed state
/// @group button
$warningButtonTextActiveColor: $whiteColor;

/// Border color of a warning button in pressed state
/// @group button
$warningButtonActiveBorderColor: darken($warningColor, 5%);

/// Box shadow of a warning button in focused state
/// @group button
$warningButtonFocusShadow: 0 0 0 0.2rem lighten($warningButtonBg, 35%);

/// Background of a help button
/// @group button
$helpButtonBg: $helpColor;

/// Text color of a help button
/// @group button
$helpButtonTextColor: #ffffff;

/// Border of a help button
/// @group button
$helpButtonBorder: 1px solid $helpColor;

/// Background of a help help in hover state
/// @group button
$helpButtonHoverBg: darken($helpColor, 5%);

/// Text color of a help button in hover state
/// @group button
$helpButtonTextHoverColor: #ffffff;

/// Border color of a help button in hover state
/// @group button
$helpButtonHoverBorderColor: darken($helpColor, 5%);

/// Background of a help button in pressed state
/// @group button
$helpButtonActiveBg: darken($helpColor, 5%);

/// Text color of a help button in pressed state
/// @group button
$helpButtonTextActiveColor: #ffffff;

/// Border color of a help button in pressed state
/// @group button
$helpButtonActiveBorderColor: darken($helpColor, 5%);

/// Box shadow of a help button in focused state
/// @group button
$helpButtonFocusShadow: 0 0 0 0.2rem lighten($helpColor, 35%);
/// Background of a danger button
/// @group button
$dangerButtonBg: $errorColor;

/// Text color of a danger button
/// @group button
$dangerButtonTextColor: #ffffff;

/// Border of a danger button
/// @group button
$dangerButtonBorder: 1px solid $errorColor;

/// Background of a danger button in hover state
/// @group button
$dangerButtonHoverBg: darken($errorColor, 5%);

/// Text color of a danger button in hover state
/// @group button
$dangerButtonTextHoverColor: #ffffff;

/// Border color of a danger button in hover state
/// @group button
$dangerButtonHoverBorderColor: darken($errorColor, 5%);

/// Background of a danger button in pressed state
/// @group button
$dangerButtonActiveBg: darken($errorColor, 5%);

/// Text color of a danger button in pressed state
/// @group button
$dangerButtonTextActiveColor: #ffffff;

/// Border color of a danger button in pressed state
/// @group button
$dangerButtonActiveBorderColor: darken($errorColor, 5%);

/// Box shadow of a danger button in focused state
/// @group button
$dangerButtonFocusShadow: 0 0 0 0.2rem lighten($dangerButtonBg, 35%);

/// Text color of a link button
/// @group button
$linkButtonColor: $primaryDarkerColor;

/// Text color of a link button in hover state
/// @group button
$linkButtonHoverColor: $primaryDarkerColor;

/// Text decoration of a link button in hover state
/// @group button
$linkButtonTextHoverDecoration: underline;

/// Box shadow of a link button in focused state
/// @group button
$linkButtonFocusShadow: 0 0 0 0.2rem $focusOutlineColor;

/// Background of a toggle button
/// @group button
$toggleButtonBg: none;

/// Border of a toggle button
/// @group button
$toggleButtonBorder: 1px solid $primaryLightColor;

/// Text color of a toggle button
/// @group button
$toggleButtonTextColor: $textColor;

/// Icon color of a toggle button
/// @group button
$toggleButtonIconColor: $textSecondaryColor;

/// Background of a toggle button in hover state
/// @group button
$toggleButtonHoverBg: $primaryDarkerColor;

/// Border color of a toggle button in hover state
/// @group button
$toggleButtonHoverBorderColor: $primaryDarkerColor;

/// Text color of a toggle button in hover state
/// @group button
$toggleButtonTextHoverColor: $textColor;

/// Icon color of a toggle button in hover state
/// @group button
$toggleButtonIconHoverColor: $textSecondaryColor;

/// Background of a toggle button in selected state
/// @group button
$toggleButtonActiveBg: $primaryColor;

/// Border color of a toggle button in selected state
/// @group button
$toggleButtonActiveBorderColor: $primaryColor;

/// Text color of a toggle button in selected state
/// @group button
$toggleButtonTextActiveColor: $primaryTextColor;

/// Icon color of a toggle button in selected state
/// @group button
$toggleButtonIconActiveColor: $primaryTextColor;

/// Hover background of a toggle button in selected state
/// @group button
$toggleButtonActiveHoverBg: $primaryDarkColor;

/// Hover border color of a toggle button in selected state
/// @group button
$toggleButtonActiveHoverBorderColor: $primaryDarkColor;

/// Hover text color of a toggle button in selected state
/// @group button
$toggleButtonTextActiveHoverColor: $primaryTextColor;

/// Hover icon of a toggle button in selected state
/// @group button
$toggleButtonIconActiveHoverColor: $primaryTextColor;

/// Width of a floating action button on speed dial
/// @group button
$speedDialButtonWidth: 4rem;

/// Height of a floating action button on speed dial
/// @group button
$speedDialButtonHeight: 4rem;

/// Font Size of a floating action button on speed dial
/// @group button
$speedDialButtonIconFontSize: 1.3rem;

/// Width of any action button on speed dial
/// @group button
$speedDialActionWidth: 3rem;

/// Height of any action button on speed dial
/// @group button
$speedDialActionHeight: 3rem;

/// Background color of any action button on speed dial
/// @group button
$speedDialActionBg: #495057;

/// Background color of any action button on speed dial in hover state
/// @group button
$speedDialActionHoverBg: #343a40;

/// Color of any action button on speed dial
/// @group button
$speedDialActionTextColor: #fff;

/// Color of any action button on speed dial in hover state
/// @group button
$speedDialActionTextHoverColor: #fff;
