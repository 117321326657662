:root,
[data-theme=light] {
  font-family: var(--font-family);
  --font-family: var(--font-family);
  --surface-a: #ffffff;
  --surface-b: #f8f9fa;
  --surface-c: #e9ecef;
  --surface-d: #dee2e6;
  --surface-e: #ffffff;
  --surface-f: #eeeeee;
  --text-color: #000000;
  --text-color-secondary: #616061;
  --primary-color: #76b729;
  --primary-color-text: #ffffff;
  --surface-0: #ffffff;
  --surface-10: #616061;
  --surface-20: #ffffff;
  --surface-50: #ffffff;
  --surface-60: #ffffff;
  --surface-100: #ffffff;
  --surface-200: #eeeeee;
  --surface-300: #616061;
  --surface-400: #999999;
  --surface-500: #9e9e9e;
  --surface-600: #000000;
  --surface-700: #ffffff;
  --surface-800: #303030;
  --surface-900: #000000;
  --content-padding: 1rem;
  --inline-spacing: 0.5rem;
  --border-radius: 3px;
  --surface-ground: #eeeeee;
  --surface-section: #ffffff;
  --surface-card: #ffffff;
  --surface-overlay: #ffffff;
  --surface-sidebar: #616061;
  --surface-border: #eeeeee;
  --surface-hover: #eeeeee;
  --maskbg: rgba(0, 0, 0, 0.4);
  --highlight-bg: #76b729;
  --highlight-text-color: #ffffff;
  --focus-ring: none;
  --primary-color: #76b729;
  --secondary-color: #6aa424;
  --box-shadow-color: #d2e2c1;
  --primary-dark-color: #6aa424;
  --primary-darker-color: #616061;
  --primary-medium-color: #999999;
  --primary-light-color: #eeeeee;
  --primary-text-color: #ffffff;
  --white-color: #ffffff;
  --success-color: #76b729;
  --info-color: #b3e5fc;
  --warning-color: #a56609;
  --error-color: #fdced4;
  --danger-color: #c7362b;
  --help-color: #8d71bb;
  --input-border-color: #c6c6c6;
  --background-color: #ffffff;
  --headline-bg-color: #999999;
  --link-color: #000000;
  --link-color-hover: #76b729;
  --shadow-card: #0000001f;
  --outlined-btn-color: #616061;
  --input-placeholder: #999999;
  color-scheme: light;
}
:root [data-theme=dark],
[data-theme=light] [data-theme=dark] {
  --surface-a: #eeeeee;
  --surface-b: #e4e4e4;
  --surface-c: #dbdbdb;
  --surface-d: #cccccc;
  --surface-e: #eeeeee;
  --surface-f: #424242;
  --text-color: #eeeeee;
  --surface-0: #000000;
  --surface-10: #ffffff;
  --surface-20: #424242;
  --surface-50: #303030;
  --surface-60: #424242;
  --surface-100: #424242;
  --surface-200: #616061;
  --surface-300: #ffffff;
  --surface-400: #616061;
  --surface-500: #a7a7a7;
  --surface-600: #ffffff;
  --surface-700: #ffffff;
  --surface-800: #eeeeee;
  --surface-900: #ffffff;
  --content-padding: 1rem;
  --inline-spacing: 0.5rem;
  --border-radius: 3px;
  --surface-ground: #303030;
  --surface-section: #616061;
  --surface-card: #616061;
  --surface-overlay: #616061;
  --surface-sidebar: #424242;
  --surface-border: #999999;
  --surface-hover: #999999;
  --maskbg: rgba(0, 0, 0, 0.4);
  --text-color-secondary: #999999;
  --primary-color-text: #ffffff;
  --highlight-bg: #76b729;
  --highlight-text-color: #ffffff;
  --focus-ring: none;
  --background-color: #303030;
  --headline-bg-color: #616061;
  --link-color: #ffffff;
  --link-color-hover: #76b729;
  --danger-color: #fdced4;
  --warning-color: #eac98f;
  --shadow-card: #ffffffcc;
  --outlined-btn-color: #ffffff;
  --input-placeholder: #999999;
  color-scheme: dark;
}

:root {
  --blue-50: #f5f8fd;
  --blue-100: #cdddf5;
  --blue-200: #a5c2ee;
  --blue-300: #7da7e6;
  --blue-400: #558cdf;
  --blue-500: #2d71d7;
  --blue-600: #2660b7;
  --blue-700: #204f97;
  --blue-800: #193e76;
  --blue-900: #122d56;
  --green-50: #f8fbf4;
  --green-100: #deeecc;
  --green-200: #c4e0a3;
  --green-300: #aad27a;
  --green-400: #90c552;
  --green-500: #76b729;
  --green-600: #649c23;
  --green-700: #53801d;
  --green-800: #416517;
  --green-900: #2f4910;
  --orange-50: #fbf7f3;
  --orange-100: #e9dac4;
  --orange-200: #d8bd95;
  --orange-300: #c7a066;
  --orange-400: #b68338;
  --orange-500: #a56609;
  --orange-600: #8c5708;
  --orange-700: #744706;
  --orange-800: #5b3805;
  --orange-900: #422904;
  --red-50: #fcf5f4;
  --red-100: #f2cfcc;
  --red-200: #e7a9a4;
  --red-300: #dc827c;
  --red-400: #d25c53;
  --red-500: #c7362b;
  --red-600: #a92e25;
  --red-700: #8b261e;
  --red-800: #6d1e18;
  --red-900: #501611;
  --violet-50: #f9f8fc;
  --violet-100: #e4ddef;
  --violet-200: #cec2e2;
  --violet-300: #b8a7d5;
  --violet-400: #a38cc8;
  --violet-500: #8d71bb;
  --violet-600: #78609f;
  --violet-700: #634f83;
  --violet-800: #4e3e67;
  --violet-900: #382d4b;
  --primary-50: #f8fbf4;
  --primary-100: #deeecc;
  --primary-200: #c4e0a3;
  --primary-300: #aad27a;
  --primary-400: #90c552;
  --primary-500: #76b729;
  --primary-600: #649c23;
  --primary-700: #53801d;
  --primary-800: #416517;
  --primary-900: #2f4910;
}

.p-editor-container .p-editor-toolbar {
  background: #eeeeee;
  border-top-right-radius: 3px;
  border-top-left-radius: 3px;
}
.p-editor-container .p-editor-toolbar.ql-snow {
  border: 1px solid #eeeeee;
}
.p-editor-container .p-editor-toolbar.ql-snow .ql-stroke {
  stroke: #999999;
}
.p-editor-container .p-editor-toolbar.ql-snow .ql-fill {
  fill: #999999;
}
.p-editor-container .p-editor-toolbar.ql-snow .ql-picker .ql-picker-label {
  border: 0 none;
  color: #999999;
}
.p-editor-container .p-editor-toolbar.ql-snow .ql-picker .ql-picker-label:hover {
  color: #616061;
}
.p-editor-container .p-editor-toolbar.ql-snow .ql-picker .ql-picker-label:hover .ql-stroke {
  stroke: #616061;
}
.p-editor-container .p-editor-toolbar.ql-snow .ql-picker .ql-picker-label:hover .ql-fill {
  fill: #616061;
}
.p-editor-container .p-editor-toolbar.ql-snow .ql-picker.ql-expanded .ql-picker-label {
  color: #616061;
}
.p-editor-container .p-editor-toolbar.ql-snow .ql-picker.ql-expanded .ql-picker-label .ql-stroke {
  stroke: #616061;
}
.p-editor-container .p-editor-toolbar.ql-snow .ql-picker.ql-expanded .ql-picker-label .ql-fill {
  fill: #616061;
}
.p-editor-container .p-editor-toolbar.ql-snow .ql-picker.ql-expanded .ql-picker-options {
  background: #424242;
  border: 0 none;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.1);
  border-radius: 3px;
  padding: 0.5rem 0;
}
.p-editor-container .p-editor-toolbar.ql-snow .ql-picker.ql-expanded .ql-picker-options .ql-picker-item {
  color: #ffffff;
}
.p-editor-container .p-editor-toolbar.ql-snow .ql-picker.ql-expanded .ql-picker-options .ql-picker-item:hover {
  color: #ffffff;
  background: #616061;
}
.p-editor-container .p-editor-toolbar.ql-snow .ql-picker.ql-expanded:not(.ql-icon-picker) .ql-picker-item {
  padding: 0.5rem 1rem;
}
.p-editor-container .p-editor-content {
  border-bottom-right-radius: 3px;
  border-bottom-left-radius: 3px;
}
.p-editor-container .p-editor-content.ql-snow {
  border: 1px solid #eeeeee;
}
.p-editor-container .p-editor-content .ql-editor {
  background: transparent;
  color: #ffffff;
  border-bottom-right-radius: 3px;
  border-bottom-left-radius: 3px;
}
.p-editor-container .ql-snow.ql-toolbar button:hover,
.p-editor-container .ql-snow.ql-toolbar button:focus {
  color: #616061;
}
.p-editor-container .ql-snow.ql-toolbar button:hover .ql-stroke,
.p-editor-container .ql-snow.ql-toolbar button:focus .ql-stroke {
  stroke: #616061;
}
.p-editor-container .ql-snow.ql-toolbar button:hover .ql-fill,
.p-editor-container .ql-snow.ql-toolbar button:focus .ql-fill {
  fill: #616061;
}
.p-editor-container .ql-snow.ql-toolbar button.ql-active,
.p-editor-container .ql-snow.ql-toolbar .ql-picker-label.ql-active,
.p-editor-container .ql-snow.ql-toolbar .ql-picker-item.ql-selected {
  color: #76b729;
}
.p-editor-container .ql-snow.ql-toolbar button.ql-active .ql-stroke,
.p-editor-container .ql-snow.ql-toolbar .ql-picker-label.ql-active .ql-stroke,
.p-editor-container .ql-snow.ql-toolbar .ql-picker-item.ql-selected .ql-stroke {
  stroke: #76b729;
}
.p-editor-container .ql-snow.ql-toolbar button.ql-active .ql-fill,
.p-editor-container .ql-snow.ql-toolbar .ql-picker-label.ql-active .ql-fill,
.p-editor-container .ql-snow.ql-toolbar .ql-picker-item.ql-selected .ql-fill {
  fill: #76b729;
}
.p-editor-container .ql-snow.ql-toolbar button.ql-active .ql-picker-label,
.p-editor-container .ql-snow.ql-toolbar .ql-picker-label.ql-active .ql-picker-label,
.p-editor-container .ql-snow.ql-toolbar .ql-picker-item.ql-selected .ql-picker-label {
  color: #76b729;
}

@layer primeng {
  * {
    box-sizing: border-box;
  }
  .p-component {
    font-family: var(--font-family);
    font-feature-settings: var(--font-feature-settings, normal);
    font-size: 1rem;
    font-weight: normal;
  }
  .p-component-overlay {
    background-color: rgba(0, 0, 0, 0.4);
    transition-duration: 0.2s;
  }
  .p-disabled,
  .p-component:disabled {
    opacity: 0.75;
  }
  .p-error {
    color: #fdced4;
  }
  .p-text-secondary {
    color: #eeeeee;
  }
  .pi {
    font-size: 1rem;
  }
  .p-icon {
    width: 1rem;
    height: 1rem;
  }
  .p-link {
    font-family: var(--font-family);
    font-feature-settings: var(--font-feature-settings, normal);
    font-size: 1rem;
    border-radius: 3px;
  }
  .p-link:focus-visible {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: none;
  }
  .p-component-overlay-enter {
    animation: p-component-overlay-enter-animation 150ms forwards;
  }
  .p-component-overlay-leave {
    animation: p-component-overlay-leave-animation 150ms forwards;
  }
  @keyframes p-component-overlay-enter-animation {
    from {
      background-color: transparent;
    }
    to {
      background-color: var(--maskbg);
    }
  }
  @keyframes p-component-overlay-leave-animation {
    from {
      background-color: var(--maskbg);
    }
    to {
      background-color: transparent;
    }
  }
  .p-autocomplete .p-autocomplete-loader {
    right: 0.5rem;
  }
  .p-autocomplete.p-autocomplete-dd .p-autocomplete-loader {
    right: 2.857rem;
  }
  .p-autocomplete:not(.p-disabled):hover .p-autocomplete-multiple-container {
    border-color: #76b729;
  }
  .p-autocomplete:not(.p-disabled).p-focus .p-autocomplete-multiple-container {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: none;
    border-color: #76b729;
  }
  .p-autocomplete .p-autocomplete-multiple-container {
    padding: 0.25rem 0.5rem;
    gap: 0.5rem;
  }
  .p-autocomplete .p-autocomplete-multiple-container .p-autocomplete-input-token {
    padding: 0.25rem 0;
  }
  .p-autocomplete .p-autocomplete-multiple-container .p-autocomplete-input-token input {
    font-family: inherit;
    font-feature-settings: inherit;
    font-size: inherit;
    color: #ffffff;
    padding: 0;
    margin: 0;
  }
  .p-autocomplete .p-autocomplete-multiple-container .p-autocomplete-token {
    padding: 0.25rem 0.5rem;
    background: #eeeeee;
    color: #ffffff;
    border-radius: 16px;
  }
  .p-autocomplete .p-autocomplete-multiple-container .p-autocomplete-token .p-autocomplete-token-icon {
    margin-left: 0.5rem;
  }
  .p-autocomplete .p-autocomplete-multiple-container .p-autocomplete-token.p-focus {
    background: var(--surface-d);
    color: #ffffff;
  }
  .p-autocomplete.p-invalid.p-component > .p-inputtext {
    border-color: #fdced4 #fdced4 #fdced4 #fdced4;
  }
  .p-autocomplete-panel {
    background: #424242;
    color: #ffffff;
    border: 0 none;
    border-radius: 3px;
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.1);
  }
  .p-autocomplete-panel .p-autocomplete-items {
    padding: 0.5rem 0;
  }
  .p-autocomplete-panel .p-autocomplete-items .p-autocomplete-item {
    margin: 0;
    padding: 0.5rem 1rem;
    border: 0 none;
    color: #ffffff;
    background: transparent;
    transition: background-color 0.2s, border-color 0.2s, box-shadow 0.2s;
    border-radius: 0;
  }
  .p-autocomplete-panel .p-autocomplete-items .p-autocomplete-item.p-highlight {
    color: #ffffff;
    background: #76b729;
  }
  .p-autocomplete-panel .p-autocomplete-items .p-autocomplete-item.p-highlight.p-focus {
    background: #76b729;
  }
  .p-autocomplete-panel .p-autocomplete-items .p-autocomplete-item:not(.p-highlight):not(.p-disabled).p-focus {
    color: #ffffff;
    background: #424242;
  }
  .p-autocomplete-panel .p-autocomplete-items .p-autocomplete-item:not(.p-highlight):not(.p-disabled):hover {
    color: #ffffff;
    background: #616061;
  }
  .p-autocomplete-panel .p-autocomplete-items .p-autocomplete-item-group {
    margin: 0;
    padding: 0.75rem 1rem;
    color: #ffffff;
    background: #ffffff;
    font-weight: 600;
  }
  p-autocomplete.ng-dirty.ng-invalid > .p-autocomplete > .p-inputtext {
    border-color: #fdced4 #fdced4 #fdced4 #fdced4;
  }
  p-autocomplete.p-autocomplete-clearable .p-inputtext {
    padding-right: 2rem;
  }
  p-autocomplete.p-autocomplete-clearable .p-autocomplete-clear-icon {
    color: #999999;
    right: 0.5rem;
  }
  p-autocomplete.p-autocomplete-clearable .p-autocomplete-dd .p-autocomplete-clear-icon {
    color: #999999;
    right: 2.857rem;
  }
  p-calendar.ng-dirty.ng-invalid > .p-calendar > .p-inputtext {
    border-color: #fdced4 #fdced4 #fdced4 #fdced4;
  }
  .p-calendar:not(.p-calendar-disabled).p-focus > .p-inputtext {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: none;
    border-color: #76b729;
  }
  .p-datepicker {
    padding: 0.5rem;
    background: #424242;
    color: #ffffff;
    border: none;
    border-radius: 3px;
  }
  .p-datepicker:not(.p-datepicker-inline) {
    background: #424242;
    border: 0 none;
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.1);
  }
  .p-datepicker:not(.p-datepicker-inline) .p-datepicker-header {
    background: #616061;
  }
  .p-datepicker .p-datepicker-header {
    padding: 0.5rem;
    color: #ffffff;
    background: #424242;
    font-weight: 600;
    margin: 0;
    border-bottom: 1px solid #eeeeee;
    border-top-right-radius: 3px;
    border-top-left-radius: 3px;
  }
  .p-datepicker .p-datepicker-header .p-datepicker-prev,
  .p-datepicker .p-datepicker-header .p-datepicker-next {
    width: 2rem;
    height: 2rem;
    color: #ffffff;
    border: 0 none;
    background: transparent;
    border-radius: 50%;
    transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
  }
  .p-datepicker .p-datepicker-header .p-datepicker-prev:enabled:hover,
  .p-datepicker .p-datepicker-header .p-datepicker-next:enabled:hover {
    color: #ffffff;
    border-color: transparent;
    background: #616061;
  }
  .p-datepicker .p-datepicker-header .p-datepicker-prev:focus-visible,
  .p-datepicker .p-datepicker-header .p-datepicker-next:focus-visible {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: none;
  }
  .p-datepicker .p-datepicker-header .p-datepicker-title {
    line-height: 2rem;
  }
  .p-datepicker .p-datepicker-header .p-datepicker-title .p-datepicker-year,
  .p-datepicker .p-datepicker-header .p-datepicker-title .p-datepicker-month {
    color: #ffffff;
    transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
    font-weight: 600;
    padding: 0.5rem;
  }
  .p-datepicker .p-datepicker-header .p-datepicker-title .p-datepicker-year:enabled:hover,
  .p-datepicker .p-datepicker-header .p-datepicker-title .p-datepicker-month:enabled:hover {
    color: #76b729;
  }
  .p-datepicker .p-datepicker-header .p-datepicker-title .p-datepicker-month {
    margin-right: 0.5rem;
  }
  .p-datepicker table {
    font-size: 1rem;
    margin: 0.5rem 0;
  }
  .p-datepicker table th {
    padding: 0.5rem;
  }
  .p-datepicker table th > span {
    width: 2.5rem;
    height: 2.5rem;
  }
  .p-datepicker table td {
    padding: 0.5rem;
  }
  .p-datepicker table td > span {
    width: 2.5rem;
    height: 2.5rem;
    border-radius: 50%;
    transition: background-color 0.2s, border-color 0.2s, box-shadow 0.2s;
    border: 1px solid transparent;
  }
  .p-datepicker table td > span.p-highlight {
    color: #ffffff;
    background: #76b729;
  }
  .p-datepicker table td > span:focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: none;
  }
  .p-datepicker table td.p-datepicker-today > span {
    background: #616061;
    color: #ffffff;
    border-color: transparent;
  }
  .p-datepicker table td.p-datepicker-today > span.p-highlight {
    color: #ffffff;
    background: #76b729;
  }
  .p-datepicker .p-datepicker-buttonbar {
    padding: 1rem 0;
    border-top: 1px solid #999999;
  }
  .p-datepicker .p-datepicker-buttonbar .p-button {
    width: auto;
  }
  .p-datepicker .p-timepicker {
    border-top: 1px solid #999999;
    padding: 0.5rem;
  }
  .p-datepicker .p-timepicker button {
    width: 2rem;
    height: 2rem;
    color: #ffffff;
    border: 0 none;
    background: transparent;
    border-radius: 50%;
    transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
  }
  .p-datepicker .p-timepicker button:enabled:hover {
    color: #ffffff;
    border-color: transparent;
    background: #616061;
  }
  .p-datepicker .p-timepicker button:focus-visible {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: none;
  }
  .p-datepicker .p-timepicker button:last-child {
    margin-top: 0.2em;
  }
  .p-datepicker .p-timepicker span {
    font-size: 1.286rem;
  }
  .p-datepicker .p-timepicker > div {
    padding: 0 0.429rem;
  }
  .p-datepicker.p-datepicker-timeonly .p-timepicker {
    border-top: 0 none;
  }
  .p-datepicker .p-monthpicker {
    margin: 0.5rem 0;
  }
  .p-datepicker .p-monthpicker .p-monthpicker-month {
    padding: 0.5rem;
    transition: background-color 0.2s, border-color 0.2s, box-shadow 0.2s;
    border-radius: 3px;
  }
  .p-datepicker .p-monthpicker .p-monthpicker-month.p-highlight {
    color: #ffffff;
    background: #76b729;
  }
  .p-datepicker .p-yearpicker {
    margin: 0.5rem 0;
  }
  .p-datepicker .p-yearpicker .p-yearpicker-year {
    padding: 0.5rem;
    transition: background-color 0.2s, border-color 0.2s, box-shadow 0.2s;
    border-radius: 3px;
  }
  .p-datepicker .p-yearpicker .p-yearpicker-year.p-highlight {
    color: #ffffff;
    background: #76b729;
  }
  .p-datepicker.p-datepicker-multiple-month .p-datepicker-group {
    border-left: 1px solid #999999;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    padding-top: 0;
    padding-bottom: 0;
  }
  .p-datepicker.p-datepicker-multiple-month .p-datepicker-group:first-child {
    padding-left: 0;
    border-left: 0 none;
  }
  .p-datepicker.p-datepicker-multiple-month .p-datepicker-group:last-child {
    padding-right: 0;
  }
  .p-datepicker:not(.p-disabled) table td span:not(.p-highlight):not(.p-disabled):hover {
    background: #616061;
  }
  .p-datepicker:not(.p-disabled) table td span:not(.p-highlight):not(.p-disabled):focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: none;
  }
  .p-datepicker:not(.p-disabled) .p-monthpicker .p-monthpicker-month:not(.p-disabled):not(.p-highlight):hover {
    background: #616061;
  }
  .p-datepicker:not(.p-disabled) .p-monthpicker .p-monthpicker-month:not(.p-disabled):focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: none;
  }
  .p-datepicker:not(.p-disabled) .p-yearpicker .p-yearpicker-year:not(.p-disabled):not(.p-highlight):hover {
    background: #616061;
  }
  .p-datepicker:not(.p-disabled) .p-yearpicker .p-yearpicker-year:not(.p-disabled):focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: none;
  }
  p-calendar.p-calendar-clearable .p-inputtext {
    padding-right: 2rem;
  }
  p-calendar.p-calendar-clearable .p-calendar-clear-icon {
    color: #999999;
    right: 0.5rem;
  }
  p-calendar.p-calendar-clearable .p-calendar-w-btn .p-calendar-clear-icon {
    color: #999999;
    right: 2.857rem;
  }
  @media screen and (max-width: 769px) {
    .p-datepicker table th,
    .p-datepicker table td {
      padding: 0;
    }
  }
  .p-cascadeselect {
    background: transparent;
    border: 1px solid #c6c6c6;
    transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
    border-radius: 3px;
  }
  .p-cascadeselect:not(.p-disabled):hover {
    border-color: #76b729;
  }
  .p-cascadeselect:not(.p-disabled).p-focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: none;
    border-color: #76b729;
  }
  .p-cascadeselect .p-cascadeselect-label {
    background: transparent;
    border: 0 none;
    padding: 0.5rem 0.5rem;
  }
  .p-cascadeselect .p-cascadeselect-label.p-placeholder {
    color: #eeeeee;
  }
  .p-cascadeselect .p-cascadeselect-label:enabled:focus {
    outline: 0 none;
    box-shadow: none;
  }
  .p-cascadeselect .p-cascadeselect-trigger {
    background: transparent;
    color: #999999;
    width: 2.357rem;
    border-top-right-radius: 3px;
    border-bottom-right-radius: 3px;
  }
  .p-cascadeselect.p-invalid.p-component {
    border-color: #fdced4 #fdced4 #fdced4 #fdced4;
  }
  .p-cascadeselect-panel {
    background: #424242;
    color: #ffffff;
    border: 0 none;
    border-radius: 3px;
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.1);
  }
  .p-cascadeselect-panel .p-cascadeselect-items {
    padding: 0.5rem 0;
  }
  .p-cascadeselect-panel .p-cascadeselect-items .p-cascadeselect-item {
    margin: 0;
    border: 0 none;
    color: #ffffff;
    background: transparent;
    transition: background-color 0.2s, border-color 0.2s, box-shadow 0.2s;
    border-radius: 0;
  }
  .p-cascadeselect-panel .p-cascadeselect-items .p-cascadeselect-item.p-highlight {
    color: #ffffff;
    background: #76b729;
  }
  .p-cascadeselect-panel .p-cascadeselect-items .p-cascadeselect-item.p-highlight.p-focus {
    background: #76b729;
  }
  .p-cascadeselect-panel .p-cascadeselect-items .p-cascadeselect-item:not(.p-highlight):not(.p-disabled).p-focus {
    color: #ffffff;
    background: #424242;
  }
  .p-cascadeselect-panel .p-cascadeselect-items .p-cascadeselect-item:not(.p-highlight):not(.p-disabled):hover {
    color: #ffffff;
    background: #616061;
  }
  .p-cascadeselect-panel .p-cascadeselect-items .p-cascadeselect-item .p-cascadeselect-item-content {
    padding: 0.5rem 1rem;
  }
  .p-cascadeselect-panel .p-cascadeselect-items .p-cascadeselect-item .p-cascadeselect-group-icon {
    font-size: 0.875rem;
  }
  .p-input-filled .p-cascadeselect {
    background: #f8f9fa;
  }
  .p-input-filled .p-cascadeselect:not(.p-disabled):hover {
    background-color: #f8f9fa;
  }
  .p-input-filled .p-cascadeselect:not(.p-disabled).p-focus {
    background-color: #f8f9fa;
  }
  p-cascadeselect.ng-dirty.ng-invalid > .p-cascadeselect {
    border-color: #fdced4 #fdced4 #fdced4 #fdced4;
  }
  p-cascadeselect.p-cascadeselect-clearable .p-cascadeselect-label {
    padding-right: 0.5rem;
  }
  p-cascadeselect.p-cascadeselect-clearable .p-cascadeselect-clear-icon {
    color: #999999;
    right: 2.357rem;
  }
  .p-overlay-modal .p-cascadeselect-sublist .p-cascadeselect-panel {
    box-shadow: none;
    border-radius: 0;
    padding: 0.25rem 0 0.25rem 0.5rem;
  }
  .p-overlay-modal .p-cascadeselect-item-active > .p-cascadeselect-item-content .p-cascadeselect-group-icon {
    transform: rotate(90deg);
  }
  .p-checkbox {
    width: 20px;
    height: 20px;
  }
  .p-checkbox .p-checkbox-box {
    border: 2px solid #999999;
    background: transparent;
    width: 20px;
    height: 20px;
    color: #ffffff;
    border-radius: 3px;
    transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
  }
  .p-checkbox .p-checkbox-box .p-checkbox-icon {
    transition-duration: 0.2s;
    color: #ffffff;
    font-size: 14px;
  }
  .p-checkbox .p-checkbox-box .p-icon {
    width: 14px;
    height: 14px;
  }
  .p-checkbox .p-checkbox-box.p-highlight {
    border-color: #76b729;
    background: #76b729;
  }
  .p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box:hover {
    border-color: #76b729;
  }
  .p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box.p-focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: none;
    border-color: #76b729;
  }
  .p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box.p-highlight:hover {
    border-color: #76b729;
    background: #76b729;
    color: #ffffff;
  }
  p-checkbox.ng-dirty.ng-invalid > .p-checkbox > .p-checkbox-box {
    border-color: #fdced4 #fdced4 #fdced4 #fdced4;
  }
  .p-input-filled .p-checkbox .p-checkbox-box {
    background-color: #f8f9fa;
  }
  .p-input-filled .p-checkbox .p-checkbox-box.p-highlight {
    background: #76b729;
  }
  .p-input-filled .p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box:hover {
    background-color: #f8f9fa;
  }
  .p-input-filled .p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box.p-highlight:hover {
    background: #76b729;
  }
  .p-checkbox-label {
    margin-left: 0.5rem;
  }
  .p-highlight .p-checkbox .p-checkbox-box {
    border-color: #ffffff;
  }
  .p-chips:not(.p-disabled):hover .p-chips-multiple-container {
    border-color: #76b729;
  }
  .p-chips:not(.p-disabled).p-focus .p-chips-multiple-container {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: none;
    border-color: #76b729;
  }
  .p-chips .p-chips-multiple-container {
    padding: 0.25rem 0.5rem;
    gap: 0.5rem;
  }
  .p-chips .p-chips-multiple-container .p-chips-token {
    padding: 0.25rem 0.5rem;
    margin-right: 0.5rem;
    background: #eeeeee;
    color: #ffffff;
    border-radius: 16px;
  }
  .p-chips .p-chips-multiple-container .p-chips-token.p-focus {
    background: var(--surface-d);
    color: #ffffff;
  }
  .p-chips .p-chips-multiple-container .p-chips-token .p-chips-token-icon {
    margin-left: 0.5rem;
  }
  .p-chips .p-chips-multiple-container .p-chips-input-token {
    padding: 0.25rem 0;
  }
  .p-chips .p-chips-multiple-container .p-chips-input-token input {
    font-family: inherit;
    font-feature-settings: inherit;
    font-size: inherit;
    color: #ffffff;
    padding: 0;
    margin: 0;
  }
  p-chips.ng-dirty.ng-invalid > .p-chips > .p-inputtext {
    border-color: #fdced4 #fdced4 #fdced4 #fdced4;
  }
  p-chips.p-chips-clearable .p-inputtext {
    padding-right: 1.5rem;
  }
  p-chips.p-chips-clearable .p-chips-clear-icon {
    color: #999999;
    right: 0.5rem;
  }
  .p-colorpicker-preview,
  .p-fluid .p-colorpicker-preview.p-inputtext {
    width: 2rem;
    height: 2rem;
  }
  .p-colorpicker-panel {
    background: #616061;
    border: 1px solid #191919;
  }
  .p-colorpicker-panel .p-colorpicker-color-handle,
  .p-colorpicker-panel .p-colorpicker-hue-handle {
    border-color: #ffffff;
  }
  .p-colorpicker-overlay-panel {
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.1);
  }
  .p-dropdown {
    background: transparent;
    border: 1px solid #c6c6c6;
    transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
    border-radius: 3px;
  }
  .p-dropdown:not(.p-disabled):hover {
    border-color: #76b729;
  }
  .p-dropdown:not(.p-disabled).p-focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: none;
    border-color: #76b729;
  }
  .p-dropdown.p-dropdown-clearable .p-dropdown-label {
    padding-right: 1.5rem;
  }
  .p-dropdown .p-dropdown-label {
    background: transparent;
    border: 0 none;
  }
  .p-dropdown .p-dropdown-label.p-placeholder {
    color: #eeeeee;
  }
  .p-dropdown .p-dropdown-label:focus, .p-dropdown .p-dropdown-label:enabled:focus {
    outline: 0 none;
    box-shadow: none;
  }
  .p-dropdown .p-dropdown-trigger {
    background: transparent;
    color: #999999;
    width: 2.357rem;
    border-top-right-radius: 3px;
    border-bottom-right-radius: 3px;
  }
  .p-dropdown .p-dropdown-clear-icon {
    color: #999999;
    right: 2.357rem;
  }
  .p-dropdown.p-invalid.p-component {
    border-color: #fdced4 #fdced4 #fdced4 #fdced4;
  }
  .p-dropdown-panel {
    background: #424242;
    color: #ffffff;
    border: 0 none;
    border-radius: 3px;
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.1);
  }
  .p-dropdown-panel .p-dropdown-header {
    padding: 0.5rem 1rem;
    border-bottom: 0 none;
    color: #ffffff;
    background: #424242;
    margin: 0;
    border-top-right-radius: 3px;
    border-top-left-radius: 3px;
  }
  .p-dropdown-panel .p-dropdown-header .p-dropdown-filter {
    padding-right: 1.5rem;
    margin-right: -1.5rem;
  }
  .p-dropdown-panel .p-dropdown-header .p-dropdown-filter-icon {
    right: 0.5rem;
    color: #999999;
  }
  .p-dropdown-panel .p-dropdown-items {
    padding: 0.5rem 0;
  }
  .p-dropdown-panel .p-dropdown-items .p-dropdown-item {
    margin: 0;
    padding: 0.5rem 1rem;
    border: 0 none;
    color: #ffffff;
    background: transparent;
    transition: background-color 0.2s, border-color 0.2s, box-shadow 0.2s;
    border-radius: 0;
  }
  .p-dropdown-panel .p-dropdown-items .p-dropdown-item.p-highlight {
    color: #ffffff;
    background: #76b729;
  }
  .p-dropdown-panel .p-dropdown-items .p-dropdown-item.p-highlight.p-focus {
    background: #76b729;
  }
  .p-dropdown-panel .p-dropdown-items .p-dropdown-item:not(.p-highlight):not(.p-disabled).p-focus {
    color: #ffffff;
    background: #424242;
  }
  .p-dropdown-panel .p-dropdown-items .p-dropdown-item:not(.p-highlight):not(.p-disabled):hover {
    color: #ffffff;
    background: #616061;
  }
  .p-dropdown-panel .p-dropdown-items .p-dropdown-item-group {
    margin: 0;
    padding: 0.75rem 1rem;
    color: #ffffff;
    background: #ffffff;
    font-weight: 600;
  }
  .p-dropdown-panel .p-dropdown-items .p-dropdown-empty-message {
    padding: 0.5rem 1rem;
    color: #ffffff;
    background: transparent;
  }
  .p-input-filled .p-dropdown {
    background: #f8f9fa;
  }
  .p-input-filled .p-dropdown:not(.p-disabled):hover {
    background-color: #f8f9fa;
  }
  .p-input-filled .p-dropdown:not(.p-disabled).p-focus {
    background-color: #f8f9fa;
  }
  .p-input-filled .p-dropdown:not(.p-disabled).p-focus .p-inputtext {
    background-color: transparent;
  }
  .p-inputgroup-addon {
    background: #424242;
    color: #ffffff;
    border-top: 1px solid #c6c6c6;
    border-left: 1px solid #c6c6c6;
    border-bottom: 1px solid #c6c6c6;
    padding: 0.5rem 0.5rem;
    min-width: 2.357rem;
  }
  .p-inputgroup-addon:last-child {
    border-right: 1px solid #c6c6c6;
  }
  .p-inputgroup > .p-component,
  .p-inputgroup > .p-element,
  .p-inputgroup > .p-inputwrapper > .p-component > .p-inputtext,
  .p-inputgroup > .p-float-label > .p-component {
    border-radius: 0;
    margin: 0;
  }
  .p-inputgroup > .p-component + .p-inputgroup-addon,
  .p-inputgroup > .p-element + .p-inputgroup-addon,
  .p-inputgroup > .p-inputwrapper > .p-component > .p-inputtext + .p-inputgroup-addon,
  .p-inputgroup > .p-float-label > .p-component + .p-inputgroup-addon {
    border-left: 0 none;
  }
  .p-inputgroup > .p-component:focus,
  .p-inputgroup > .p-element:focus,
  .p-inputgroup > .p-inputwrapper > .p-component > .p-inputtext:focus,
  .p-inputgroup > .p-float-label > .p-component:focus {
    z-index: 1;
  }
  .p-inputgroup > .p-component:focus ~ label,
  .p-inputgroup > .p-element:focus ~ label,
  .p-inputgroup > .p-inputwrapper > .p-component > .p-inputtext:focus ~ label,
  .p-inputgroup > .p-float-label > .p-component:focus ~ label {
    z-index: 1;
  }
  .p-inputgroup-addon:first-child,
  .p-inputgroup button:first-child,
  .p-inputgroup input:first-child,
  .p-inputgroup > .p-inputwrapper:first-child > .p-component,
  .p-inputgroup > .p-inputwrapper:first-child > .p-component > .p-inputtext {
    border-top-left-radius: 3px;
    border-bottom-left-radius: 3px;
  }
  .p-inputgroup .p-float-label:first-child input {
    border-top-left-radius: 3px;
    border-bottom-left-radius: 3px;
  }
  .p-inputgroup-addon:last-child,
  .p-inputgroup button:last-child,
  .p-inputgroup input:last-child,
  .p-inputgroup > .p-inputwrapper:last-child > .p-component,
  .p-inputgroup > .p-inputwrapper:last-child > .p-component > .p-inputtext {
    border-top-right-radius: 3px;
    border-bottom-right-radius: 3px;
  }
  .p-inputgroup .p-float-label:last-child input {
    border-top-right-radius: 3px;
    border-bottom-right-radius: 3px;
  }
  .p-fluid .p-inputgroup .p-button {
    width: auto;
  }
  .p-fluid .p-inputgroup .p-button.p-button-icon-only {
    width: 2.357rem;
  }
  p-inputmask.ng-dirty.ng-invalid > .p-inputtext {
    border-color: #fdced4 #fdced4 #fdced4 #fdced4;
  }
  p-inputmask.p-inputmask-clearable .p-inputtext {
    padding-right: 2rem;
  }
  p-inputmask.p-inputmask-clearable .p-inputmask-clear-icon {
    color: #999999;
    right: 0.5rem;
  }
  p-inputnumber.ng-dirty.ng-invalid > .p-inputnumber > .p-inputtext {
    border-color: #fdced4 #fdced4 #fdced4 #fdced4;
  }
  p-inputnumber.p-inputnumber-clearable .p-inputnumber-input {
    padding-right: 2rem;
  }
  p-inputnumber.p-inputnumber-clearable .p-inputnumber-clear-icon {
    color: #999999;
    right: 0.5rem;
  }
  p-inputnumber.p-inputnumber-clearable .p-inputnumber-buttons-stacked .p-inputnumber-clear-icon {
    right: 2.857rem;
  }
  p-inputnumber.p-inputnumber-clearable .p-inputnumber-buttons-horizontal .p-inputnumber-clear-icon {
    right: 2.857rem;
  }
  .p-inputswitch {
    width: 3rem;
    height: 1.75rem;
  }
  .p-inputswitch .p-inputswitch-slider {
    background: #ced4da;
    transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
    border-radius: 30px;
  }
  .p-inputswitch .p-inputswitch-slider:before {
    background: #ffffff;
    width: 1.25rem;
    height: 1.25rem;
    left: 0.25rem;
    margin-top: -0.625rem;
    border-radius: 50%;
    transition-duration: 0.2s;
  }
  .p-inputswitch.p-inputswitch-checked .p-inputswitch-slider:before {
    transform: translateX(1.25rem);
  }
  .p-inputswitch.p-focus .p-inputswitch-slider {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: none;
  }
  .p-inputswitch:not(.p-disabled):hover .p-inputswitch-slider {
    background: #c3cad2;
  }
  .p-inputswitch.p-inputswitch-checked .p-inputswitch-slider {
    background: #76b729;
  }
  .p-inputswitch.p-inputswitch-checked .p-inputswitch-slider:before {
    background: #ffffff;
  }
  .p-inputswitch.p-inputswitch-checked:not(.p-disabled):hover .p-inputswitch-slider {
    background: #6aa424;
  }
  p-inputswitch.ng-dirty.ng-invalid > .p-inputswitch {
    border-color: #fdced4 #fdced4 #fdced4 #fdced4;
  }
  .p-inputtext {
    font-family: var(--font-family);
    font-feature-settings: var(--font-feature-settings, normal);
    font-size: 1rem;
    color: #ffffff;
    background: transparent;
    padding: 0.5rem 0.5rem;
    border: 1px solid #c6c6c6;
    transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
    appearance: none;
    border-radius: 3px;
  }
  .p-inputtext:enabled:hover {
    border-color: #76b729;
  }
  .p-inputtext:enabled:focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: none;
    border-color: #76b729;
  }
  .p-inputtext.ng-dirty.ng-invalid {
    border-color: #fdced4 #fdced4 #fdced4 #fdced4;
  }
  .p-inputtext.p-inputtext-sm {
    font-size: 0.875rem;
    padding: 0.4375rem 0.4375rem;
  }
  .p-inputtext.p-inputtext-lg {
    font-size: 1.25rem;
    padding: 0.625rem 0.625rem;
  }
  .p-float-label > label {
    left: 0.5rem;
    color: #eeeeee;
    transition-duration: 0.2s;
  }
  .p-float-label > .ng-invalid.ng-dirty + label {
    color: #fdced4 #fdced4 #fdced4 #fdced4;
  }
  .p-input-icon-left > .p-icon-wrapper.p-icon,
  .p-input-icon-left > i:first-of-type {
    left: 0.5rem;
    color: #999999;
  }
  .p-input-icon-left > .p-inputtext {
    padding-left: 2rem;
  }
  .p-input-icon-left.p-float-label > label {
    left: 2rem;
  }
  .p-input-icon-right > .p-icon-wrapper,
  .p-input-icon-right > i:last-of-type {
    right: 0.5rem;
    color: #999999;
  }
  .p-input-icon-right > .p-inputtext {
    padding-right: 2rem;
  }
  ::-webkit-input-placeholder {
    color: #eeeeee;
  }
  :-moz-placeholder {
    color: #eeeeee;
  }
  ::-moz-placeholder {
    color: #eeeeee;
  }
  :-ms-input-placeholder {
    color: #eeeeee;
  }
  .p-input-filled .p-inputtext {
    background-color: #f8f9fa;
  }
  .p-input-filled .p-inputtext:enabled:hover {
    background-color: #f8f9fa;
  }
  .p-input-filled .p-inputtext:enabled:focus {
    background-color: #f8f9fa;
  }
  .p-inputtext-sm .p-inputtext {
    font-size: 0.875rem;
    padding: 0.4375rem 0.4375rem;
  }
  .p-inputtext-lg .p-inputtext {
    font-size: 1.25rem;
    padding: 0.625rem 0.625rem;
  }
  .p-listbox {
    background: #424242;
    color: #ffffff;
    border: none;
    border-radius: 3px;
    transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
  }
  .p-listbox .p-listbox-header {
    padding: 0.5rem 1rem;
    border-bottom: 0 none;
    color: #ffffff;
    background: #424242;
    margin: 0;
    border-top-right-radius: 3px;
    border-top-left-radius: 3px;
  }
  .p-listbox .p-listbox-header .p-listbox-filter {
    padding-right: 1.5rem;
  }
  .p-listbox .p-listbox-header .p-listbox-filter-icon {
    right: 0.5rem;
    color: #999999;
  }
  .p-listbox .p-listbox-header .p-checkbox {
    margin-right: 0.5rem;
  }
  .p-listbox .p-listbox-list {
    padding: 0.5rem 0;
    outline: 0 none;
  }
  .p-listbox .p-listbox-list .p-listbox-item {
    margin: 0;
    padding: 0.5rem 1rem;
    border: 0 none;
    color: #ffffff;
    transition: background-color 0.2s, border-color 0.2s, box-shadow 0.2s;
    border-radius: 0;
  }
  .p-listbox .p-listbox-list .p-listbox-item.p-highlight {
    color: #ffffff;
    background: #76b729;
  }
  .p-listbox .p-listbox-list .p-listbox-item .p-checkbox {
    margin-right: 0.5rem;
  }
  .p-listbox .p-listbox-list .p-listbox-item-group {
    margin: 0;
    padding: 0.75rem 1rem;
    color: #ffffff;
    background: #ffffff;
    font-weight: 600;
  }
  .p-listbox .p-listbox-list .p-listbox-empty-message {
    padding: 0.5rem 1rem;
    color: #ffffff;
    background: transparent;
  }
  .p-listbox:not(.p-disabled) .p-listbox-item.p-highlight.p-focus {
    background: #76b729;
  }
  .p-listbox:not(.p-disabled) .p-listbox-item:not(.p-highlight):not(.p-disabled).p-focus {
    color: #ffffff;
    background: #424242;
  }
  .p-listbox:not(.p-disabled) .p-listbox-item:not(.p-highlight):not(.p-disabled):hover {
    color: #ffffff;
    background: #616061;
  }
  .p-listbox.p-focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: none;
    border-color: #76b729;
  }
  p-listbox.ng-dirty.ng-invalid > .p-listbox {
    border-color: #fdced4 #fdced4 #fdced4 #fdced4;
  }
  .p-multiselect {
    background: transparent;
    border: 1px solid #c6c6c6;
    transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
    border-radius: 3px;
  }
  .p-multiselect:not(.p-disabled):hover {
    border-color: #76b729;
  }
  .p-multiselect:not(.p-disabled).p-focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: none;
    border-color: #76b729;
  }
  .p-multiselect .p-multiselect-label {
    padding: 0.5rem 0.5rem;
    transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
  }
  .p-multiselect .p-multiselect-label.p-placeholder {
    color: #eeeeee;
  }
  .p-multiselect.p-multiselect-chip .p-multiselect-token {
    padding: 0.25rem 0.5rem;
    margin-right: 0.5rem;
    background: #eeeeee;
    color: #ffffff;
    border-radius: 16px;
  }
  .p-multiselect.p-multiselect-chip .p-multiselect-token .p-multiselect-token-icon {
    margin-left: 0.5rem;
  }
  .p-multiselect .p-multiselect-trigger {
    background: transparent;
    color: #999999;
    width: 2.357rem;
    border-top-right-radius: 3px;
    border-bottom-right-radius: 3px;
  }
  .p-inputwrapper-filled.p-multiselect.p-multiselect-chip .p-multiselect-label {
    padding: 0.25rem 0.5rem;
  }
  .p-multiselect-clearable .p-multiselect-label-container {
    padding-right: 1.5rem;
  }
  .p-multiselect-clearable .p-multiselect-clear-icon {
    color: #999999;
    right: 2.357rem;
  }
  .p-multiselect-panel {
    background: #424242;
    color: #ffffff;
    border: 0 none;
    border-radius: 3px;
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.1);
  }
  .p-multiselect-panel .p-multiselect-header {
    padding: 0.5rem 1rem;
    border-bottom: 0 none;
    color: #ffffff;
    background: #424242;
    margin: 0;
    border-top-right-radius: 3px;
    border-top-left-radius: 3px;
  }
  .p-multiselect-panel .p-multiselect-header .p-multiselect-filter-container .p-inputtext {
    padding-right: 1.5rem;
  }
  .p-multiselect-panel .p-multiselect-header .p-multiselect-filter-container .p-multiselect-filter-icon {
    right: 0.5rem;
    color: #999999;
  }
  .p-multiselect-panel .p-multiselect-header .p-checkbox {
    margin-right: 0.5rem;
  }
  .p-multiselect-panel .p-multiselect-header .p-multiselect-close {
    margin-left: 0.5rem;
    width: 2rem;
    height: 2rem;
    color: #ffffff;
    border: 0 none;
    background: transparent;
    border-radius: 50%;
    transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
  }
  .p-multiselect-panel .p-multiselect-header .p-multiselect-close:enabled:hover {
    color: #ffffff;
    border-color: transparent;
    background: #616061;
  }
  .p-multiselect-panel .p-multiselect-header .p-multiselect-close:focus-visible {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: none;
  }
  .p-multiselect-panel .p-multiselect-items {
    padding: 0.5rem 0;
  }
  .p-multiselect-panel .p-multiselect-items .p-multiselect-item {
    margin: 0;
    padding: 0.5rem 1rem;
    border: 0 none;
    color: #ffffff;
    background: transparent;
    transition: background-color 0.2s, border-color 0.2s, box-shadow 0.2s;
    border-radius: 0;
  }
  .p-multiselect-panel .p-multiselect-items .p-multiselect-item.p-highlight {
    color: #ffffff;
    background: #76b729;
  }
  .p-multiselect-panel .p-multiselect-items .p-multiselect-item.p-highlight.p-focus {
    background: #76b729;
  }
  .p-multiselect-panel .p-multiselect-items .p-multiselect-item:not(.p-highlight):not(.p-disabled).p-focus {
    color: #ffffff;
    background: #424242;
  }
  .p-multiselect-panel .p-multiselect-items .p-multiselect-item:not(.p-highlight):not(.p-disabled):hover {
    color: #ffffff;
    background: #616061;
  }
  .p-multiselect-panel .p-multiselect-items .p-multiselect-item .p-checkbox {
    margin-right: 0.5rem;
  }
  .p-multiselect-panel .p-multiselect-items .p-multiselect-item-group {
    margin: 0;
    padding: 0.75rem 1rem;
    color: #ffffff;
    background: #ffffff;
    font-weight: 600;
  }
  .p-multiselect-panel .p-multiselect-items .p-multiselect-empty-message {
    padding: 0.5rem 1rem;
    color: #ffffff;
    background: transparent;
  }
  .p-input-filled .p-multiselect {
    background: #f8f9fa;
  }
  .p-input-filled .p-multiselect:not(.p-disabled):hover {
    background-color: #f8f9fa;
  }
  .p-input-filled .p-multiselect:not(.p-disabled).p-focus {
    background-color: #f8f9fa;
  }
  p-multiselect.ng-dirty.ng-invalid > .p-multiselect {
    border-color: #fdced4 #fdced4 #fdced4 #fdced4;
  }
  p-password.ng-invalid.ng-dirty > .p-password > .p-inputtext {
    border-color: #fdced4 #fdced4 #fdced4 #fdced4;
  }
  .p-password-panel {
    padding: 1rem;
    background: none;
    color: #ffffff;
    border: 0 none;
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.1);
    border-radius: 3px;
  }
  .p-password-panel .p-password-meter {
    margin-bottom: 0.5rem;
    background: #eeeeee;
  }
  .p-password-panel .p-password-meter .p-password-strength.weak {
    background: #fdced4;
  }
  .p-password-panel .p-password-meter .p-password-strength.medium {
    background: #a56609;
  }
  .p-password-panel .p-password-meter .p-password-strength.strong {
    background: #76b729;
  }
  p-password.p-password-clearable .p-password-input {
    padding-right: 2rem;
  }
  p-password.p-password-clearable .p-password-clear-icon {
    color: #999999;
    right: 0.5rem;
  }
  p-password.p-password-clearable.p-password-mask .p-password-input {
    padding-right: 3.5rem;
  }
  p-password.p-password-clearable.p-password-mask .p-password-clear-icon {
    color: #999999;
    right: 2rem;
  }
  .p-radiobutton {
    width: 20px;
    height: 20px;
  }
  .p-radiobutton .p-radiobutton-box {
    border: 2px solid #999999;
    background: transparent;
    width: 20px;
    height: 20px;
    color: #ffffff;
    border-radius: 50%;
    transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
  }
  .p-radiobutton .p-radiobutton-box:not(.p-disabled):not(.p-highlight):hover {
    border-color: #76b729;
  }
  .p-radiobutton .p-radiobutton-box:not(.p-disabled).p-focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: none;
    border-color: #76b729;
  }
  .p-radiobutton .p-radiobutton-box .p-radiobutton-icon {
    width: 12px;
    height: 12px;
    transition-duration: 0.2s;
    background-color: #eeeeee;
  }
  .p-radiobutton .p-radiobutton-box.p-highlight {
    border-color: #76b729;
    background: #76b729;
  }
  .p-radiobutton .p-radiobutton-box.p-highlight:not(.p-disabled):hover {
    border-color: #76b729;
    background: #76b729;
    color: #eeeeee;
  }
  p-radiobutton.ng-dirty.ng-invalid > .p-radiobutton > .p-radiobutton-box {
    border-color: #fdced4 #fdced4 #fdced4 #fdced4;
  }
  .p-input-filled .p-radiobutton .p-radiobutton-box {
    background-color: #f8f9fa;
  }
  .p-input-filled .p-radiobutton .p-radiobutton-box:not(.p-disabled):hover {
    background-color: #f8f9fa;
  }
  .p-input-filled .p-radiobutton .p-radiobutton-box.p-highlight {
    background: #76b729;
  }
  .p-input-filled .p-radiobutton .p-radiobutton-box.p-highlight:not(.p-disabled):hover {
    background: #76b729;
  }
  .p-radiobutton-label {
    margin-left: 0.5rem;
  }
  .p-highlight .p-radiobutton .p-radiobutton-box {
    border-color: #eeeeee;
  }
  .p-rating {
    gap: 0.5rem;
  }
  .p-rating .p-rating-item .p-rating-icon {
    color: #ffffff;
    transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
    font-size: 1.143rem;
  }
  .p-rating .p-rating-item .p-rating-icon.p-icon {
    width: 1.143rem;
    height: 1.143rem;
  }
  .p-rating .p-rating-item .p-rating-icon.p-rating-cancel {
    color: #fdced4;
  }
  .p-rating .p-rating-item.p-focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: none;
    border-color: #76b729;
  }
  .p-rating .p-rating-item.p-rating-item-active .p-rating-icon {
    color: #76b729;
  }
  .p-rating:not(.p-disabled):not(.p-readonly) .p-rating-item:hover .p-rating-icon {
    color: #76b729;
  }
  .p-rating:not(.p-disabled):not(.p-readonly) .p-rating-item:hover .p-rating-icon.p-rating-cancel {
    color: #fcb6bf;
  }
  .p-highlight .p-rating .p-rating-item.p-rating-item-active .p-rating-icon {
    color: #ffffff;
  }
  .p-selectbutton .p-button {
    background: none;
    border: 1px solid #eeeeee;
    color: #ffffff;
    transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
  }
  .p-selectbutton .p-button .p-button-icon-left,
  .p-selectbutton .p-button .p-button-icon-right {
    color: #eeeeee;
  }
  .p-selectbutton .p-button:not(.p-disabled):not(.p-highlight):hover {
    background: #616061;
    border-color: #616061;
    color: #ffffff;
  }
  .p-selectbutton .p-button:not(.p-disabled):not(.p-highlight):hover .p-button-icon-left,
  .p-selectbutton .p-button:not(.p-disabled):not(.p-highlight):hover .p-button-icon-right {
    color: #eeeeee;
  }
  .p-selectbutton .p-button.p-highlight {
    background: #76b729;
    border-color: #76b729;
    color: #ffffff;
  }
  .p-selectbutton .p-button.p-highlight .p-button-icon-left,
  .p-selectbutton .p-button.p-highlight .p-button-icon-right {
    color: #ffffff;
  }
  .p-selectbutton .p-button.p-highlight:hover {
    background: #6aa424;
    border-color: #6aa424;
    color: #ffffff;
  }
  .p-selectbutton .p-button.p-highlight:hover .p-button-icon-left,
  .p-selectbutton .p-button.p-highlight:hover .p-button-icon-right {
    color: #ffffff;
  }
  p-selectbutton.ng-dirty.ng-invalid > .p-selectbutton > .p-button {
    border-color: #fdced4 #fdced4 #fdced4 #fdced4;
  }
  .p-slider {
    background: #eeeeee;
    border: 0 none;
    border-radius: 3px;
  }
  .p-slider.p-slider-horizontal {
    height: 0.286rem;
  }
  .p-slider.p-slider-horizontal .p-slider-handle {
    margin-top: -0.5715rem;
    margin-left: -0.5715rem;
  }
  .p-slider.p-slider-vertical {
    width: 0.286rem;
  }
  .p-slider.p-slider-vertical .p-slider-handle {
    margin-left: -0.5715rem;
    margin-bottom: -0.5715rem;
  }
  .p-slider .p-slider-handle {
    height: 1.143rem;
    width: 1.143rem;
    background: #ffffff;
    border: 2px solid #76b729;
    border-radius: 50%;
    transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
  }
  .p-slider .p-slider-handle:focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: none;
  }
  .p-slider .p-slider-range {
    background: #76b729;
  }
  .p-slider:not(.p-disabled) .p-slider-handle:hover {
    background: #76b729;
    border-color: #76b729;
  }
  .p-slider.p-slider-animate.p-slider-horizontal .p-slider-handle {
    transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s, left 0.2s;
  }
  .p-slider.p-slider-animate.p-slider-horizontal .p-slider-range {
    transition: width 0.2s;
  }
  .p-slider.p-slider-animate.p-slider-vertical .p-slider-handle {
    transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s, bottom 0.2s;
  }
  .p-slider.p-slider-animate.p-slider-vertical .p-slider-range {
    transition: height 0.2s;
  }
  .p-togglebutton.p-button {
    background: none;
    border: 1px solid #eeeeee;
    color: #ffffff;
    transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
  }
  .p-togglebutton.p-button .p-button-icon-left,
  .p-togglebutton.p-button .p-button-icon-right {
    color: #eeeeee;
  }
  .p-togglebutton.p-button:not(.p-disabled):not(.p-highlight):hover {
    background: #616061;
    border-color: #616061;
    color: #ffffff;
  }
  .p-togglebutton.p-button:not(.p-disabled):not(.p-highlight):hover .p-button-icon-left,
  .p-togglebutton.p-button:not(.p-disabled):not(.p-highlight):hover .p-button-icon-right {
    color: #eeeeee;
  }
  .p-togglebutton.p-button.p-highlight {
    background: #76b729;
    border-color: #76b729;
    color: #ffffff;
  }
  .p-togglebutton.p-button.p-highlight .p-button-icon-left,
  .p-togglebutton.p-button.p-highlight .p-button-icon-right {
    color: #ffffff;
  }
  .p-togglebutton.p-button.p-highlight:hover {
    background: #6aa424;
    border-color: #6aa424;
    color: #ffffff;
  }
  .p-togglebutton.p-button.p-highlight:hover .p-button-icon-left,
  .p-togglebutton.p-button.p-highlight:hover .p-button-icon-right {
    color: #ffffff;
  }
  p-togglebutton.ng-dirty.ng-invalid > .p-togglebutton.p-button {
    border-color: #fdced4 #fdced4 #fdced4 #fdced4;
  }
  .p-treeselect {
    background: transparent;
    border: 1px solid #c6c6c6;
    transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
    border-radius: 3px;
  }
  .p-treeselect:not(.p-disabled):hover {
    border-color: #76b729;
  }
  .p-treeselect:not(.p-disabled).p-focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: none;
    border-color: #76b729;
  }
  .p-treeselect .p-treeselect-label {
    padding: 0.5rem 0.5rem;
    transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
  }
  .p-treeselect .p-treeselect-label.p-placeholder {
    color: #eeeeee;
  }
  .p-treeselect.p-treeselect-chip .p-treeselect-token {
    padding: 0.25rem 0.5rem;
    margin-right: 0.5rem;
    background: #eeeeee;
    color: #ffffff;
    border-radius: 16px;
  }
  .p-treeselect .p-treeselect-trigger {
    background: transparent;
    color: #999999;
    width: 2.357rem;
    border-top-right-radius: 3px;
    border-bottom-right-radius: 3px;
  }
  p-treeselect.ng-invalid.ng-dirty > .p-treeselect {
    border-color: #fdced4 #fdced4 #fdced4 #fdced4;
  }
  .p-inputwrapper-filled .p-treeselect.p-treeselect-chip .p-treeselect-label {
    padding: 0.25rem 0.5rem;
  }
  .p-treeselect-panel {
    background: #424242;
    color: #ffffff;
    border: 0 none;
    border-radius: 3px;
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.1);
  }
  .p-treeselect-panel .p-treeselect-header {
    padding: 0.5rem 1rem;
    border-bottom: 0 none;
    color: #ffffff;
    background: #424242;
    margin: 0;
    border-top-right-radius: 3px;
    border-top-left-radius: 3px;
  }
  .p-treeselect-panel .p-treeselect-header .p-treeselect-filter-container {
    margin-right: 0.5rem;
  }
  .p-treeselect-panel .p-treeselect-header .p-treeselect-filter-container .p-treeselect-filter {
    padding-right: 1.5rem;
  }
  .p-treeselect-panel .p-treeselect-header .p-treeselect-filter-container .p-treeselect-filter-icon {
    right: 0.5rem;
    color: #999999;
  }
  .p-treeselect-panel .p-treeselect-header .p-treeselect-filter-container.p-treeselect-clearable-filter .p-treeselect-filter {
    padding-right: 3rem;
  }
  .p-treeselect-panel .p-treeselect-header .p-treeselect-filter-container.p-treeselect-clearable-filter .p-treeselect-filter-clear-icon {
    right: 2rem;
  }
  .p-treeselect-panel .p-treeselect-header .p-treeselect-close {
    width: 2rem;
    height: 2rem;
    color: #ffffff;
    border: 0 none;
    background: transparent;
    border-radius: 50%;
    transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
  }
  .p-treeselect-panel .p-treeselect-header .p-treeselect-close:enabled:hover {
    color: #ffffff;
    border-color: transparent;
    background: #616061;
  }
  .p-treeselect-panel .p-treeselect-header .p-treeselect-close:focus-visible {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: none;
  }
  .p-treeselect-panel .p-treeselect-items-wrapper .p-tree {
    border: 0 none;
  }
  .p-treeselect-panel .p-treeselect-items-wrapper .p-treeselect-empty-message {
    padding: 0.5rem 1rem;
    color: #ffffff;
    background: transparent;
  }
  .p-input-filled .p-treeselect {
    background: #f8f9fa;
  }
  .p-input-filled .p-treeselect:not(.p-disabled):hover {
    background-color: #f8f9fa;
  }
  .p-input-filled .p-treeselect:not(.p-disabled).p-focus {
    background-color: #f8f9fa;
  }
  p-treeselect.p-treeselect-clearable .p-treeselect-label-container {
    padding-right: 1.5rem;
  }
  p-treeselect.p-treeselect-clearable .p-treeselect-clear-icon {
    color: #999999;
    right: 2.357rem;
  }
  .p-button {
    color: #ffffff;
    background: #76b729;
    border: 1px solid #76b729;
    padding: 0.5rem 1rem;
    font-size: 1rem;
    transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
    border-radius: 3px;
  }
  .p-button:enabled:hover {
    background: #6aa424;
    color: #ffffff;
    border-color: #6aa424;
  }
  .p-button:enabled:active {
    background: #6aa424;
    color: #ffffff;
    border-color: #6aa424;
  }
  .p-button.p-button-outlined {
    background-color: transparent;
    color: #76b729;
    border: 1px solid;
  }
  .p-button.p-button-outlined:enabled:hover {
    background: rgba(118, 183, 41, 0.04);
    color: #76b729;
    border: 1px solid;
  }
  .p-button.p-button-outlined:enabled:active {
    background: rgba(118, 183, 41, 0.16);
    color: #76b729;
    border: 1px solid;
  }
  .p-button.p-button-outlined.p-button-plain {
    color: #ffffff;
    border-color: #ffffff;
  }
  .p-button.p-button-outlined.p-button-plain:enabled:hover {
    background: #616061;
    color: #ffffff;
  }
  .p-button.p-button-outlined.p-button-plain:enabled:active {
    background: #616061;
    color: #ffffff;
  }
  .p-button.p-button-text {
    background-color: transparent;
    color: #76b729;
    border-color: transparent;
  }
  .p-button.p-button-text:enabled:hover {
    background: rgba(118, 183, 41, 0.04);
    color: #76b729;
    border-color: transparent;
  }
  .p-button.p-button-text:enabled:active {
    background: rgba(118, 183, 41, 0.16);
    color: #76b729;
    border-color: transparent;
  }
  .p-button.p-button-text.p-button-plain {
    color: #ffffff;
  }
  .p-button.p-button-text.p-button-plain:enabled:hover {
    background: #616061;
    color: #ffffff;
  }
  .p-button.p-button-text.p-button-plain:enabled:active {
    background: #616061;
    color: #ffffff;
  }
  .p-button:focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: none;
  }
  .p-button .p-button-label {
    transition-duration: 0.2s;
  }
  .p-button .p-button-icon-left {
    margin-right: 0.5rem;
  }
  .p-button .p-button-icon-right {
    margin-left: 0.5rem;
  }
  .p-button .p-button-icon-bottom {
    margin-top: 0.5rem;
  }
  .p-button .p-button-icon-top {
    margin-bottom: 0.5rem;
  }
  .p-button .p-badge {
    margin-left: 0.5rem;
    min-width: 1rem;
    height: 1rem;
    line-height: 1rem;
    color: #76b729;
    background-color: #ffffff;
  }
  .p-button.p-button-raised {
    box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
  }
  .p-button.p-button-rounded {
    border-radius: 2rem;
  }
  .p-button.p-button-icon-only {
    width: 2.357rem;
    padding: 0.5rem 0;
  }
  .p-button.p-button-icon-only .p-button-icon-left,
  .p-button.p-button-icon-only .p-button-icon-right {
    margin: 0;
  }
  .p-button.p-button-icon-only.p-button-rounded {
    border-radius: 50%;
    height: 2.357rem;
  }
  .p-button.p-button-sm {
    font-size: 0.875rem;
    padding: 0.4375rem 0.875rem;
  }
  .p-button.p-button-sm .p-button-icon {
    font-size: 0.875rem;
  }
  .p-button.p-button-lg {
    font-size: 1.25rem;
    padding: 0.625rem 1.25rem;
  }
  .p-button.p-button-lg .p-button-icon {
    font-size: 1.25rem;
  }
  .p-button.p-button-loading-label-only .p-button-label {
    margin-left: 0.5rem;
  }
  .p-button.p-button-loading-label-only .p-button-loading-icon {
    margin-right: 0;
  }
  .p-fluid .p-button {
    width: 100%;
  }
  .p-fluid .p-button-icon-only {
    width: 2.357rem;
  }
  .p-fluid .p-buttonset {
    display: flex;
  }
  .p-fluid .p-buttonset .p-button {
    flex: 1;
  }
  .p-button.p-button-secondary,
  .p-buttonset.p-button-secondary > .p-button,
  .p-splitbutton.p-button-secondary > .p-button {
    color: #ffffff;
    background: #ffffff;
    border: 1px solid #616061;
  }
  .p-button.p-button-secondary:enabled:hover,
  .p-buttonset.p-button-secondary > .p-button:enabled:hover,
  .p-splitbutton.p-button-secondary > .p-button:enabled:hover {
    background: #545354;
    color: #ffffff;
    border-color: #545354;
  }
  .p-button.p-button-secondary:enabled:focus,
  .p-buttonset.p-button-secondary > .p-button:enabled:focus,
  .p-splitbutton.p-button-secondary > .p-button:enabled:focus {
    box-shadow: 0 0 0 0.2rem #616061;
  }
  .p-button.p-button-secondary:enabled:active,
  .p-buttonset.p-button-secondary > .p-button:enabled:active,
  .p-splitbutton.p-button-secondary > .p-button:enabled:active {
    background: #545354;
    color: #ffffff;
    border-color: #545354;
  }
  .p-button.p-button-secondary.p-button-outlined,
  .p-buttonset.p-button-secondary > .p-button.p-button-outlined,
  .p-splitbutton.p-button-secondary > .p-button.p-button-outlined {
    background-color: transparent;
    color: #ffffff;
    border: 1px solid;
  }
  .p-button.p-button-secondary.p-button-outlined:enabled:hover,
  .p-buttonset.p-button-secondary > .p-button.p-button-outlined:enabled:hover,
  .p-splitbutton.p-button-secondary > .p-button.p-button-outlined:enabled:hover {
    background: rgba(255, 255, 255, 0.04);
    color: #ffffff;
    border: 1px solid;
  }
  .p-button.p-button-secondary.p-button-outlined:enabled:active,
  .p-buttonset.p-button-secondary > .p-button.p-button-outlined:enabled:active,
  .p-splitbutton.p-button-secondary > .p-button.p-button-outlined:enabled:active {
    background: rgba(255, 255, 255, 0.16);
    color: #ffffff;
    border: 1px solid;
  }
  .p-button.p-button-secondary.p-button-text,
  .p-buttonset.p-button-secondary > .p-button.p-button-text,
  .p-splitbutton.p-button-secondary > .p-button.p-button-text {
    background-color: transparent;
    color: #ffffff;
    border-color: transparent;
  }
  .p-button.p-button-secondary.p-button-text:enabled:hover,
  .p-buttonset.p-button-secondary > .p-button.p-button-text:enabled:hover,
  .p-splitbutton.p-button-secondary > .p-button.p-button-text:enabled:hover {
    background: rgba(255, 255, 255, 0.04);
    border-color: transparent;
    color: #ffffff;
  }
  .p-button.p-button-secondary.p-button-text:enabled:active,
  .p-buttonset.p-button-secondary > .p-button.p-button-text:enabled:active,
  .p-splitbutton.p-button-secondary > .p-button.p-button-text:enabled:active {
    background: rgba(255, 255, 255, 0.16);
    border-color: transparent;
    color: #ffffff;
  }
  .p-button.p-button-info,
  .p-buttonset.p-button-info > .p-button,
  .p-splitbutton.p-button-info > .p-button {
    color: #ffffff;
    background: #b3e5fc;
    border: 1px solid #b3e5fc;
  }
  .p-button.p-button-info:enabled:hover,
  .p-buttonset.p-button-info > .p-button:enabled:hover,
  .p-splitbutton.p-button-info > .p-button:enabled:hover {
    background: #9addfb;
    color: #ffffff;
    border-color: #9addfb;
  }
  .p-button.p-button-info:enabled:focus,
  .p-buttonset.p-button-info > .p-button:enabled:focus,
  .p-splitbutton.p-button-info > .p-button:enabled:focus {
    box-shadow: 0 0 0 0.2rem white;
  }
  .p-button.p-button-info:enabled:active,
  .p-buttonset.p-button-info > .p-button:enabled:active,
  .p-splitbutton.p-button-info > .p-button:enabled:active {
    background: #9addfb;
    color: #ffffff;
    border-color: #9addfb;
  }
  .p-button.p-button-info.p-button-outlined,
  .p-buttonset.p-button-info > .p-button.p-button-outlined,
  .p-splitbutton.p-button-info > .p-button.p-button-outlined {
    background-color: transparent;
    color: #b3e5fc;
    border: 1px solid;
  }
  .p-button.p-button-info.p-button-outlined:enabled:hover,
  .p-buttonset.p-button-info > .p-button.p-button-outlined:enabled:hover,
  .p-splitbutton.p-button-info > .p-button.p-button-outlined:enabled:hover {
    background: rgba(179, 229, 252, 0.04);
    color: #b3e5fc;
    border: 1px solid;
  }
  .p-button.p-button-info.p-button-outlined:enabled:active,
  .p-buttonset.p-button-info > .p-button.p-button-outlined:enabled:active,
  .p-splitbutton.p-button-info > .p-button.p-button-outlined:enabled:active {
    background: rgba(179, 229, 252, 0.16);
    color: #b3e5fc;
    border: 1px solid;
  }
  .p-button.p-button-info.p-button-text,
  .p-buttonset.p-button-info > .p-button.p-button-text,
  .p-splitbutton.p-button-info > .p-button.p-button-text {
    background-color: transparent;
    color: #b3e5fc;
    border-color: transparent;
  }
  .p-button.p-button-info.p-button-text:enabled:hover,
  .p-buttonset.p-button-info > .p-button.p-button-text:enabled:hover,
  .p-splitbutton.p-button-info > .p-button.p-button-text:enabled:hover {
    background: rgba(179, 229, 252, 0.04);
    border-color: transparent;
    color: #b3e5fc;
  }
  .p-button.p-button-info.p-button-text:enabled:active,
  .p-buttonset.p-button-info > .p-button.p-button-text:enabled:active,
  .p-splitbutton.p-button-info > .p-button.p-button-text:enabled:active {
    background: rgba(179, 229, 252, 0.16);
    border-color: transparent;
    color: #b3e5fc;
  }
  .p-button.p-button-success,
  .p-buttonset.p-button-success > .p-button,
  .p-splitbutton.p-button-success > .p-button {
    color: #ffffff;
    background: #76b729;
    border: 1px solid #76b729;
  }
  .p-button.p-button-success:enabled:hover,
  .p-buttonset.p-button-success > .p-button:enabled:hover,
  .p-splitbutton.p-button-success > .p-button:enabled:hover {
    background: #69a224;
    color: #ffffff;
    border-color: #69a224;
  }
  .p-button.p-button-success:enabled:focus,
  .p-buttonset.p-button-success > .p-button:enabled:focus,
  .p-splitbutton.p-button-success > .p-button:enabled:focus {
    box-shadow: 0 0 0 0.2rem #cceba7;
  }
  .p-button.p-button-success:enabled:active,
  .p-buttonset.p-button-success > .p-button:enabled:active,
  .p-splitbutton.p-button-success > .p-button:enabled:active {
    background: #69a224;
    color: #ffffff;
    border-color: #69a224;
  }
  .p-button.p-button-success.p-button-outlined,
  .p-buttonset.p-button-success > .p-button.p-button-outlined,
  .p-splitbutton.p-button-success > .p-button.p-button-outlined {
    background-color: transparent;
    color: #76b729;
    border: 1px solid;
  }
  .p-button.p-button-success.p-button-outlined:enabled:hover,
  .p-buttonset.p-button-success > .p-button.p-button-outlined:enabled:hover,
  .p-splitbutton.p-button-success > .p-button.p-button-outlined:enabled:hover {
    background: rgba(118, 183, 41, 0.04);
    color: #76b729;
    border: 1px solid;
  }
  .p-button.p-button-success.p-button-outlined:enabled:active,
  .p-buttonset.p-button-success > .p-button.p-button-outlined:enabled:active,
  .p-splitbutton.p-button-success > .p-button.p-button-outlined:enabled:active {
    background: rgba(118, 183, 41, 0.16);
    color: #76b729;
    border: 1px solid;
  }
  .p-button.p-button-success.p-button-text,
  .p-buttonset.p-button-success > .p-button.p-button-text,
  .p-splitbutton.p-button-success > .p-button.p-button-text {
    background-color: transparent;
    color: #76b729;
    border-color: transparent;
  }
  .p-button.p-button-success.p-button-text:enabled:hover,
  .p-buttonset.p-button-success > .p-button.p-button-text:enabled:hover,
  .p-splitbutton.p-button-success > .p-button.p-button-text:enabled:hover {
    background: rgba(118, 183, 41, 0.04);
    border-color: transparent;
    color: #76b729;
  }
  .p-button.p-button-success.p-button-text:enabled:active,
  .p-buttonset.p-button-success > .p-button.p-button-text:enabled:active,
  .p-splitbutton.p-button-success > .p-button.p-button-text:enabled:active {
    background: rgba(118, 183, 41, 0.16);
    border-color: transparent;
    color: #76b729;
  }
  .p-button.p-button-warning,
  .p-buttonset.p-button-warning > .p-button,
  .p-splitbutton.p-button-warning > .p-button {
    color: #ffffff;
    background: #a56609;
    border: 1px solid #a56609;
  }
  .p-button.p-button-warning:enabled:hover,
  .p-buttonset.p-button-warning > .p-button:enabled:hover,
  .p-splitbutton.p-button-warning > .p-button:enabled:hover {
    background: #8d5708;
    color: #ffffff;
    border-color: #8d5708;
  }
  .p-button.p-button-warning:enabled:focus,
  .p-buttonset.p-button-warning > .p-button:enabled:focus,
  .p-splitbutton.p-button-warning > .p-button:enabled:focus {
    box-shadow: 0 0 0 0.2rem #f7be6a;
  }
  .p-button.p-button-warning:enabled:active,
  .p-buttonset.p-button-warning > .p-button:enabled:active,
  .p-splitbutton.p-button-warning > .p-button:enabled:active {
    background: #8d5708;
    color: #ffffff;
    border-color: #8d5708;
  }
  .p-button.p-button-warning.p-button-outlined,
  .p-buttonset.p-button-warning > .p-button.p-button-outlined,
  .p-splitbutton.p-button-warning > .p-button.p-button-outlined {
    background-color: transparent;
    color: #a56609;
    border: 1px solid;
  }
  .p-button.p-button-warning.p-button-outlined:enabled:hover,
  .p-buttonset.p-button-warning > .p-button.p-button-outlined:enabled:hover,
  .p-splitbutton.p-button-warning > .p-button.p-button-outlined:enabled:hover {
    background: rgba(165, 102, 9, 0.04);
    color: #a56609;
    border: 1px solid;
  }
  .p-button.p-button-warning.p-button-outlined:enabled:active,
  .p-buttonset.p-button-warning > .p-button.p-button-outlined:enabled:active,
  .p-splitbutton.p-button-warning > .p-button.p-button-outlined:enabled:active {
    background: rgba(165, 102, 9, 0.16);
    color: #a56609;
    border: 1px solid;
  }
  .p-button.p-button-warning.p-button-text,
  .p-buttonset.p-button-warning > .p-button.p-button-text,
  .p-splitbutton.p-button-warning > .p-button.p-button-text {
    background-color: transparent;
    color: #a56609;
    border-color: transparent;
  }
  .p-button.p-button-warning.p-button-text:enabled:hover,
  .p-buttonset.p-button-warning > .p-button.p-button-text:enabled:hover,
  .p-splitbutton.p-button-warning > .p-button.p-button-text:enabled:hover {
    background: rgba(165, 102, 9, 0.04);
    border-color: transparent;
    color: #a56609;
  }
  .p-button.p-button-warning.p-button-text:enabled:active,
  .p-buttonset.p-button-warning > .p-button.p-button-text:enabled:active,
  .p-splitbutton.p-button-warning > .p-button.p-button-text:enabled:active {
    background: rgba(165, 102, 9, 0.16);
    border-color: transparent;
    color: #a56609;
  }
  .p-button.p-button-help,
  .p-buttonset.p-button-help > .p-button,
  .p-splitbutton.p-button-help > .p-button {
    color: #ffffff;
    background: #8d71bb;
    border: 1px solid #8d71bb;
  }
  .p-button.p-button-help:enabled:hover,
  .p-buttonset.p-button-help > .p-button:enabled:hover,
  .p-splitbutton.p-button-help > .p-button:enabled:hover {
    background: #7f60b3;
    color: #ffffff;
    border-color: #7f60b3;
  }
  .p-button.p-button-help:enabled:focus,
  .p-buttonset.p-button-help > .p-button:enabled:focus,
  .p-splitbutton.p-button-help > .p-button:enabled:focus {
    box-shadow: 0 0 0 0.2rem #eeeaf5;
  }
  .p-button.p-button-help:enabled:active,
  .p-buttonset.p-button-help > .p-button:enabled:active,
  .p-splitbutton.p-button-help > .p-button:enabled:active {
    background: #7f60b3;
    color: #ffffff;
    border-color: #7f60b3;
  }
  .p-button.p-button-help.p-button-outlined,
  .p-buttonset.p-button-help > .p-button.p-button-outlined,
  .p-splitbutton.p-button-help > .p-button.p-button-outlined {
    background-color: transparent;
    color: #8d71bb;
    border: 1px solid;
  }
  .p-button.p-button-help.p-button-outlined:enabled:hover,
  .p-buttonset.p-button-help > .p-button.p-button-outlined:enabled:hover,
  .p-splitbutton.p-button-help > .p-button.p-button-outlined:enabled:hover {
    background: rgba(141, 113, 187, 0.04);
    color: #8d71bb;
    border: 1px solid;
  }
  .p-button.p-button-help.p-button-outlined:enabled:active,
  .p-buttonset.p-button-help > .p-button.p-button-outlined:enabled:active,
  .p-splitbutton.p-button-help > .p-button.p-button-outlined:enabled:active {
    background: rgba(141, 113, 187, 0.16);
    color: #8d71bb;
    border: 1px solid;
  }
  .p-button.p-button-help.p-button-text,
  .p-buttonset.p-button-help > .p-button.p-button-text,
  .p-splitbutton.p-button-help > .p-button.p-button-text {
    background-color: transparent;
    color: #8d71bb;
    border-color: transparent;
  }
  .p-button.p-button-help.p-button-text:enabled:hover,
  .p-buttonset.p-button-help > .p-button.p-button-text:enabled:hover,
  .p-splitbutton.p-button-help > .p-button.p-button-text:enabled:hover {
    background: rgba(141, 113, 187, 0.04);
    border-color: transparent;
    color: #8d71bb;
  }
  .p-button.p-button-help.p-button-text:enabled:active,
  .p-buttonset.p-button-help > .p-button.p-button-text:enabled:active,
  .p-splitbutton.p-button-help > .p-button.p-button-text:enabled:active {
    background: rgba(141, 113, 187, 0.16);
    border-color: transparent;
    color: #8d71bb;
  }
  .p-button.p-button-danger,
  .p-buttonset.p-button-danger > .p-button,
  .p-splitbutton.p-button-danger > .p-button {
    color: #ffffff;
    background: #fdced4;
    border: 1px solid #fdced4;
  }
  .p-button.p-button-danger:enabled:hover,
  .p-buttonset.p-button-danger > .p-button:enabled:hover,
  .p-splitbutton.p-button-danger > .p-button:enabled:hover {
    background: #fcb6bf;
    color: #ffffff;
    border-color: #fcb6bf;
  }
  .p-button.p-button-danger:enabled:focus,
  .p-buttonset.p-button-danger > .p-button:enabled:focus,
  .p-splitbutton.p-button-danger > .p-button:enabled:focus {
    box-shadow: 0 0 0 0.2rem white;
  }
  .p-button.p-button-danger:enabled:active,
  .p-buttonset.p-button-danger > .p-button:enabled:active,
  .p-splitbutton.p-button-danger > .p-button:enabled:active {
    background: #fcb6bf;
    color: #ffffff;
    border-color: #fcb6bf;
  }
  .p-button.p-button-danger.p-button-outlined,
  .p-buttonset.p-button-danger > .p-button.p-button-outlined,
  .p-splitbutton.p-button-danger > .p-button.p-button-outlined {
    background-color: transparent;
    color: #fdced4;
    border: 1px solid;
  }
  .p-button.p-button-danger.p-button-outlined:enabled:hover,
  .p-buttonset.p-button-danger > .p-button.p-button-outlined:enabled:hover,
  .p-splitbutton.p-button-danger > .p-button.p-button-outlined:enabled:hover {
    background: rgba(253, 206, 212, 0.04);
    color: #fdced4;
    border: 1px solid;
  }
  .p-button.p-button-danger.p-button-outlined:enabled:active,
  .p-buttonset.p-button-danger > .p-button.p-button-outlined:enabled:active,
  .p-splitbutton.p-button-danger > .p-button.p-button-outlined:enabled:active {
    background: rgba(253, 206, 212, 0.16);
    color: #fdced4;
    border: 1px solid;
  }
  .p-button.p-button-danger.p-button-text,
  .p-buttonset.p-button-danger > .p-button.p-button-text,
  .p-splitbutton.p-button-danger > .p-button.p-button-text {
    background-color: transparent;
    color: #fdced4;
    border-color: transparent;
  }
  .p-button.p-button-danger.p-button-text:enabled:hover,
  .p-buttonset.p-button-danger > .p-button.p-button-text:enabled:hover,
  .p-splitbutton.p-button-danger > .p-button.p-button-text:enabled:hover {
    background: rgba(253, 206, 212, 0.04);
    border-color: transparent;
    color: #fdced4;
  }
  .p-button.p-button-danger.p-button-text:enabled:active,
  .p-buttonset.p-button-danger > .p-button.p-button-text:enabled:active,
  .p-splitbutton.p-button-danger > .p-button.p-button-text:enabled:active {
    background: rgba(253, 206, 212, 0.16);
    border-color: transparent;
    color: #fdced4;
  }
  .p-button.p-button-link {
    color: #616061;
    background: transparent;
    border: transparent;
  }
  .p-button.p-button-link:enabled:hover {
    background: transparent;
    color: #616061;
    border-color: transparent;
  }
  .p-button.p-button-link:enabled:hover .p-button-label {
    text-decoration: underline;
  }
  .p-button.p-button-link:enabled:focus {
    background: transparent;
    box-shadow: 0 0 0 0.2rem rgba(118, 183, 41, 0.4);
    border-color: transparent;
  }
  .p-button.p-button-link:enabled:active {
    background: transparent;
    color: #616061;
    border-color: transparent;
  }
  .p-speeddial-button.p-button.p-button-icon-only {
    width: 4rem;
    height: 4rem;
  }
  .p-speeddial-button.p-button.p-button-icon-only .p-button-icon {
    font-size: 1.3rem;
  }
  .p-speeddial-button.p-button.p-button-icon-only .p-icon {
    width: 1.3rem;
    height: 1.3rem;
  }
  .p-speeddial-list {
    outline: 0 none;
  }
  .p-speeddial-item.p-focus > .p-speeddial-action {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: none;
  }
  .p-speeddial-action {
    width: 3rem;
    height: 3rem;
    background: #495057;
    color: #fff;
  }
  .p-speeddial-action:hover {
    background: #343a40;
    color: #fff;
  }
  .p-speeddial-direction-up .p-speeddial-item {
    margin: 0.25rem 0;
  }
  .p-speeddial-direction-up .p-speeddial-item:first-child {
    margin-bottom: 0.5rem;
  }
  .p-speeddial-direction-down .p-speeddial-item {
    margin: 0.25rem 0;
  }
  .p-speeddial-direction-down .p-speeddial-item:first-child {
    margin-top: 0.5rem;
  }
  .p-speeddial-direction-left .p-speeddial-item {
    margin: 0 0.25rem;
  }
  .p-speeddial-direction-left .p-speeddial-item:first-child {
    margin-right: 0.5rem;
  }
  .p-speeddial-direction-right .p-speeddial-item {
    margin: 0 0.25rem;
  }
  .p-speeddial-direction-right .p-speeddial-item:first-child {
    margin-left: 0.5rem;
  }
  .p-speeddial-circle .p-speeddial-item,
  .p-speeddial-semi-circle .p-speeddial-item,
  .p-speeddial-quarter-circle .p-speeddial-item {
    margin: 0;
  }
  .p-speeddial-circle .p-speeddial-item:first-child, .p-speeddial-circle .p-speeddial-item:last-child,
  .p-speeddial-semi-circle .p-speeddial-item:first-child,
  .p-speeddial-semi-circle .p-speeddial-item:last-child,
  .p-speeddial-quarter-circle .p-speeddial-item:first-child,
  .p-speeddial-quarter-circle .p-speeddial-item:last-child {
    margin: 0;
  }
  .p-speeddial-mask {
    background-color: rgba(0, 0, 0, 0.4);
  }
  .p-splitbutton {
    border-radius: 3px;
  }
  .p-splitbutton.p-button-outlined > .p-button {
    background-color: transparent;
    color: #76b729;
    border: 1px solid;
  }
  .p-splitbutton.p-button-outlined > .p-button:enabled:hover, .p-splitbutton.p-button-outlined > .p-button:not(button):not(a):not(.p-disabled):hover {
    background: rgba(118, 183, 41, 0.04);
    color: #76b729;
  }
  .p-splitbutton.p-button-outlined > .p-button:enabled:active, .p-splitbutton.p-button-outlined > .p-button:not(button):not(a):not(.p-disabled):active {
    background: rgba(118, 183, 41, 0.16);
    color: #76b729;
  }
  .p-splitbutton.p-button-outlined.p-button-plain > .p-button {
    color: #ffffff;
    border-color: #ffffff;
  }
  .p-splitbutton.p-button-outlined.p-button-plain > .p-button:enabled:hover, .p-splitbutton.p-button-outlined.p-button-plain > .p-button:not(button):not(a):not(.p-disabled):hover {
    background: #616061;
    color: #ffffff;
  }
  .p-splitbutton.p-button-outlined.p-button-plain > .p-button:enabled:active, .p-splitbutton.p-button-outlined.p-button-plain > .p-button:not(button):not(a):not(.p-disabled):active {
    background: #616061;
    color: #ffffff;
  }
  .p-splitbutton.p-button-text > .p-button {
    background-color: transparent;
    color: #76b729;
    border-color: transparent;
  }
  .p-splitbutton.p-button-text > .p-button:enabled:hover, .p-splitbutton.p-button-text > .p-button:not(button):not(a):not(.p-disabled):hover {
    background: rgba(118, 183, 41, 0.04);
    color: #76b729;
    border-color: transparent;
  }
  .p-splitbutton.p-button-text > .p-button:enabled:active, .p-splitbutton.p-button-text > .p-button:not(button):not(a):not(.p-disabled):active {
    background: rgba(118, 183, 41, 0.16);
    color: #76b729;
    border-color: transparent;
  }
  .p-splitbutton.p-button-text.p-button-plain > .p-button {
    color: #ffffff;
  }
  .p-splitbutton.p-button-text.p-button-plain > .p-button:enabled:hover, .p-splitbutton.p-button-text.p-button-plain > .p-button:not(button):not(a):not(.p-disabled):hover {
    background: #616061;
    color: #ffffff;
  }
  .p-splitbutton.p-button-text.p-button-plain > .p-button:enabled:active, .p-splitbutton.p-button-text.p-button-plain > .p-button:not(button):not(a):not(.p-disabled):active {
    background: #616061;
    color: #ffffff;
  }
  .p-splitbutton.p-button-raised {
    box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
  }
  .p-splitbutton.p-button-rounded {
    border-radius: 2rem;
  }
  .p-splitbutton.p-button-rounded > .p-button {
    border-radius: 2rem;
  }
  .p-splitbutton.p-button-sm > .p-button {
    font-size: 0.875rem;
    padding: 0.4375rem 0.875rem;
  }
  .p-splitbutton.p-button-sm > .p-button .p-button-icon {
    font-size: 0.875rem;
  }
  .p-splitbutton.p-button-lg > .p-button {
    font-size: 1.25rem;
    padding: 0.625rem 1.25rem;
  }
  .p-splitbutton.p-button-lg > .p-button .p-button-icon {
    font-size: 1.25rem;
  }
  .p-splitbutton.p-button-secondary.p-button-outlined > .p-button {
    background-color: transparent;
    color: #ffffff;
    border: 1px solid;
  }
  .p-splitbutton.p-button-secondary.p-button-outlined > .p-button:enabled:hover, .p-splitbutton.p-button-secondary.p-button-outlined > .p-button:not(button):not(a):not(.p-disabled):hover {
    background: rgba(255, 255, 255, 0.04);
    color: #ffffff;
  }
  .p-splitbutton.p-button-secondary.p-button-outlined > .p-button:enabled:active, .p-splitbutton.p-button-secondary.p-button-outlined > .p-button:not(button):not(a):not(.p-disabled):active {
    background: rgba(255, 255, 255, 0.16);
    color: #ffffff;
  }
  .p-splitbutton.p-button-secondary.p-button-text > .p-button {
    background-color: transparent;
    color: #ffffff;
    border-color: transparent;
  }
  .p-splitbutton.p-button-secondary.p-button-text > .p-button:enabled:hover, .p-splitbutton.p-button-secondary.p-button-text > .p-button:not(button):not(a):not(.p-disabled):hover {
    background: rgba(255, 255, 255, 0.04);
    border-color: transparent;
    color: #ffffff;
  }
  .p-splitbutton.p-button-secondary.p-button-text > .p-button:enabled:active, .p-splitbutton.p-button-secondary.p-button-text > .p-button:not(button):not(a):not(.p-disabled):active {
    background: rgba(255, 255, 255, 0.16);
    border-color: transparent;
    color: #ffffff;
  }
  .p-splitbutton.p-button-info.p-button-outlined > .p-button {
    background-color: transparent;
    color: #b3e5fc;
    border: 1px solid;
  }
  .p-splitbutton.p-button-info.p-button-outlined > .p-button:enabled:hover, .p-splitbutton.p-button-info.p-button-outlined > .p-button:not(button):not(a):not(.p-disabled):hover {
    background: rgba(179, 229, 252, 0.04);
    color: #b3e5fc;
  }
  .p-splitbutton.p-button-info.p-button-outlined > .p-button:enabled:active, .p-splitbutton.p-button-info.p-button-outlined > .p-button:not(button):not(a):not(.p-disabled):active {
    background: rgba(179, 229, 252, 0.16);
    color: #b3e5fc;
  }
  .p-splitbutton.p-button-info.p-button-text > .p-button {
    background-color: transparent;
    color: #b3e5fc;
    border-color: transparent;
  }
  .p-splitbutton.p-button-info.p-button-text > .p-button:enabled:hover, .p-splitbutton.p-button-info.p-button-text > .p-button:not(button):not(a):not(.p-disabled):hover {
    background: rgba(179, 229, 252, 0.04);
    border-color: transparent;
    color: #b3e5fc;
  }
  .p-splitbutton.p-button-info.p-button-text > .p-button:enabled:active, .p-splitbutton.p-button-info.p-button-text > .p-button:not(button):not(a):not(.p-disabled):active {
    background: rgba(179, 229, 252, 0.16);
    border-color: transparent;
    color: #b3e5fc;
  }
  .p-splitbutton.p-button-success.p-button-outlined > .p-button {
    background-color: transparent;
    color: #76b729;
    border: 1px solid;
  }
  .p-splitbutton.p-button-success.p-button-outlined > .p-button:enabled:hover, .p-splitbutton.p-button-success.p-button-outlined > .p-button:not(button):not(a):not(.p-disabled):hover {
    background: rgba(118, 183, 41, 0.04);
    color: #76b729;
  }
  .p-splitbutton.p-button-success.p-button-outlined > .p-button:enabled:active, .p-splitbutton.p-button-success.p-button-outlined > .p-button:not(button):not(a):not(.p-disabled):active {
    background: rgba(118, 183, 41, 0.16);
    color: #76b729;
  }
  .p-splitbutton.p-button-success.p-button-text > .p-button {
    background-color: transparent;
    color: #76b729;
    border-color: transparent;
  }
  .p-splitbutton.p-button-success.p-button-text > .p-button:enabled:hover, .p-splitbutton.p-button-success.p-button-text > .p-button:not(button):not(a):not(.p-disabled):hover {
    background: rgba(118, 183, 41, 0.04);
    border-color: transparent;
    color: #76b729;
  }
  .p-splitbutton.p-button-success.p-button-text > .p-button:enabled:active, .p-splitbutton.p-button-success.p-button-text > .p-button:not(button):not(a):not(.p-disabled):active {
    background: rgba(118, 183, 41, 0.16);
    border-color: transparent;
    color: #76b729;
  }
  .p-splitbutton.p-button-warning.p-button-outlined > .p-button {
    background-color: transparent;
    color: #a56609;
    border: 1px solid;
  }
  .p-splitbutton.p-button-warning.p-button-outlined > .p-button:enabled:hover, .p-splitbutton.p-button-warning.p-button-outlined > .p-button:not(button):not(a):not(.p-disabled):hover {
    background: rgba(165, 102, 9, 0.04);
    color: #a56609;
  }
  .p-splitbutton.p-button-warning.p-button-outlined > .p-button:enabled:active, .p-splitbutton.p-button-warning.p-button-outlined > .p-button:not(button):not(a):not(.p-disabled):active {
    background: rgba(165, 102, 9, 0.16);
    color: #a56609;
  }
  .p-splitbutton.p-button-warning.p-button-text > .p-button {
    background-color: transparent;
    color: #a56609;
    border-color: transparent;
  }
  .p-splitbutton.p-button-warning.p-button-text > .p-button:enabled:hover, .p-splitbutton.p-button-warning.p-button-text > .p-button:not(button):not(a):not(.p-disabled):hover {
    background: rgba(165, 102, 9, 0.04);
    border-color: transparent;
    color: #a56609;
  }
  .p-splitbutton.p-button-warning.p-button-text > .p-button:enabled:active, .p-splitbutton.p-button-warning.p-button-text > .p-button:not(button):not(a):not(.p-disabled):active {
    background: rgba(165, 102, 9, 0.16);
    border-color: transparent;
    color: #a56609;
  }
  .p-splitbutton.p-button-help.p-button-outlined > .p-button {
    background-color: transparent;
    color: #8d71bb;
    border: 1px solid;
  }
  .p-splitbutton.p-button-help.p-button-outlined > .p-button:enabled:hover, .p-splitbutton.p-button-help.p-button-outlined > .p-button:not(button):not(a):not(.p-disabled):hover {
    background: rgba(141, 113, 187, 0.04);
    color: #8d71bb;
  }
  .p-splitbutton.p-button-help.p-button-outlined > .p-button:enabled:active, .p-splitbutton.p-button-help.p-button-outlined > .p-button:not(button):not(a):not(.p-disabled):active {
    background: rgba(141, 113, 187, 0.16);
    color: #8d71bb;
  }
  .p-splitbutton.p-button-help.p-button-text > .p-button {
    background-color: transparent;
    color: #8d71bb;
    border-color: transparent;
  }
  .p-splitbutton.p-button-help.p-button-text > .p-button:enabled:hover, .p-splitbutton.p-button-help.p-button-text > .p-button:not(button):not(a):not(.p-disabled):hover {
    background: rgba(141, 113, 187, 0.04);
    border-color: transparent;
    color: #8d71bb;
  }
  .p-splitbutton.p-button-help.p-button-text > .p-button:enabled:active, .p-splitbutton.p-button-help.p-button-text > .p-button:not(button):not(a):not(.p-disabled):active {
    background: rgba(141, 113, 187, 0.16);
    border-color: transparent;
    color: #8d71bb;
  }
  .p-splitbutton.p-button-danger.p-button-outlined > .p-button {
    background-color: transparent;
    color: #fdced4;
    border: 1px solid;
  }
  .p-splitbutton.p-button-danger.p-button-outlined > .p-button:enabled:hover, .p-splitbutton.p-button-danger.p-button-outlined > .p-button:not(button):not(a):not(.p-disabled):hover {
    background: rgba(253, 206, 212, 0.04);
    color: #fdced4;
  }
  .p-splitbutton.p-button-danger.p-button-outlined > .p-button:enabled:active, .p-splitbutton.p-button-danger.p-button-outlined > .p-button:not(button):not(a):not(.p-disabled):active {
    background: rgba(253, 206, 212, 0.16);
    color: #fdced4;
  }
  .p-splitbutton.p-button-danger.p-button-text > .p-button {
    background-color: transparent;
    color: #fdced4;
    border-color: transparent;
  }
  .p-splitbutton.p-button-danger.p-button-text > .p-button:enabled:hover, .p-splitbutton.p-button-danger.p-button-text > .p-button:not(button):not(a):not(.p-disabled):hover {
    background: rgba(253, 206, 212, 0.04);
    border-color: transparent;
    color: #fdced4;
  }
  .p-splitbutton.p-button-danger.p-button-text > .p-button:enabled:active, .p-splitbutton.p-button-danger.p-button-text > .p-button:not(button):not(a):not(.p-disabled):active {
    background: rgba(253, 206, 212, 0.16);
    border-color: transparent;
    color: #fdced4;
  }
  .p-carousel .p-carousel-content .p-carousel-prev,
  .p-carousel .p-carousel-content .p-carousel-next {
    width: 2rem;
    height: 2rem;
    color: #ffffff;
    border: 0 none;
    background: transparent;
    border-radius: 50%;
    transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
    margin: 0.5rem;
  }
  .p-carousel .p-carousel-content .p-carousel-prev:enabled:hover,
  .p-carousel .p-carousel-content .p-carousel-next:enabled:hover {
    color: #ffffff;
    border-color: transparent;
    background: #616061;
  }
  .p-carousel .p-carousel-content .p-carousel-prev:focus-visible,
  .p-carousel .p-carousel-content .p-carousel-next:focus-visible {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: none;
  }
  .p-carousel .p-carousel-indicators {
    padding: 1rem;
  }
  .p-carousel .p-carousel-indicators .p-carousel-indicator {
    margin-right: 0.5rem;
    margin-bottom: 0.5rem;
  }
  .p-carousel .p-carousel-indicators .p-carousel-indicator button {
    background-color: #eeeeee;
    width: 2rem;
    height: 0.5rem;
    transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
    border-radius: 0;
  }
  .p-carousel .p-carousel-indicators .p-carousel-indicator button:hover {
    background: #999999;
  }
  .p-carousel .p-carousel-indicators .p-carousel-indicator.p-highlight button {
    background: #76b729;
    color: #ffffff;
  }
  .p-datatable .p-paginator-top {
    border-width: 1px 0 1px 0;
    border-radius: 0;
  }
  .p-datatable .p-paginator-bottom {
    border-width: 0 0 1px 0;
    border-radius: 0;
  }
  .p-datatable .p-datatable-header {
    background: #303030;
    color: #ffffff;
    border: 1px solid #616061;
    border-width: 0 0 1px 0;
    padding: 1rem 0;
    font-weight: 400;
  }
  .p-datatable .p-datatable-footer {
    background: #f8f9fa;
    color: #ffffff;
    border: 1px solid #e9ecef;
    border-width: 0 0 1px 0;
    padding: 1rem 1rem;
    font-weight: 600;
  }
  .p-datatable .p-datatable-thead > tr > th {
    text-align: left;
    padding: 1rem 1rem;
    border: 1px solid #616061;
    border-width: 0 0 1px 0;
    font-weight: 600;
    color: #ffffff;
    background: #616061;
    transition: background-color 0.2s, border-color 0.2s, box-shadow 0.2s;
  }
  .p-datatable .p-datatable-tfoot > tr > td {
    text-align: left;
    padding: 1rem 1rem;
    border: 1px solid #e9ecef;
    border-width: 0 0 1px 0;
    font-weight: 600;
    color: #ffffff;
    background: #f8f9fa;
  }
  .p-datatable .p-sortable-column .p-sortable-column-icon {
    color: #ffffff;
    margin-left: 0.5rem;
  }
  .p-datatable .p-sortable-column .p-sortable-column-badge {
    border-radius: 50%;
    height: 1.143rem;
    min-width: 1.143rem;
    line-height: 1.143rem;
    color: #ffffff;
    background: #76b729;
    margin-left: 0.5rem;
  }
  .p-datatable .p-sortable-column:not(.p-highlight):hover {
    background: #616061;
    color: #ffffff;
  }
  .p-datatable .p-sortable-column:not(.p-highlight):hover .p-sortable-column-icon {
    color: #ffffff;
  }
  .p-datatable .p-sortable-column.p-highlight {
    background: #616061;
    color: #76b729;
  }
  .p-datatable .p-sortable-column.p-highlight .p-sortable-column-icon {
    color: #76b729;
  }
  .p-datatable .p-sortable-column.p-highlight:hover {
    background: #616061;
    color: #76b729;
  }
  .p-datatable .p-sortable-column.p-highlight:hover .p-sortable-column-icon {
    color: #76b729;
  }
  .p-datatable .p-sortable-column:focus-visible {
    box-shadow: inset 0 0 0 0.15rem transparent;
    outline: 0 none;
  }
  .p-datatable .p-datatable-tbody > tr {
    background: #303030;
    color: #ffffff;
    transition: background-color 0.2s, border-color 0.2s, box-shadow 0.2s;
  }
  .p-datatable .p-datatable-tbody > tr > td {
    text-align: left;
    border: 1px solid #eeeeee;
    border-width: 0 0 1px 0;
    padding: 1rem 1rem;
  }
  .p-datatable .p-datatable-tbody > tr > td .p-row-toggler,
  .p-datatable .p-datatable-tbody > tr > td .p-row-editor-init,
  .p-datatable .p-datatable-tbody > tr > td .p-row-editor-save,
  .p-datatable .p-datatable-tbody > tr > td .p-row-editor-cancel {
    width: 2rem;
    height: 2rem;
    color: #ffffff;
    border: 0 none;
    background: transparent;
    border-radius: 50%;
    transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
  }
  .p-datatable .p-datatable-tbody > tr > td .p-row-toggler:enabled:hover,
  .p-datatable .p-datatable-tbody > tr > td .p-row-editor-init:enabled:hover,
  .p-datatable .p-datatable-tbody > tr > td .p-row-editor-save:enabled:hover,
  .p-datatable .p-datatable-tbody > tr > td .p-row-editor-cancel:enabled:hover {
    color: #ffffff;
    border-color: transparent;
    background: #616061;
  }
  .p-datatable .p-datatable-tbody > tr > td .p-row-toggler:focus-visible,
  .p-datatable .p-datatable-tbody > tr > td .p-row-editor-init:focus-visible,
  .p-datatable .p-datatable-tbody > tr > td .p-row-editor-save:focus-visible,
  .p-datatable .p-datatable-tbody > tr > td .p-row-editor-cancel:focus-visible {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: none;
  }
  .p-datatable .p-datatable-tbody > tr > td .p-row-editor-save {
    margin-right: 0.5rem;
  }
  .p-datatable .p-datatable-tbody > tr:focus-visible {
    outline: 0.15rem solid rgba(118, 183, 41, 0.4);
    outline-offset: -0.15rem;
  }
  .p-datatable .p-datatable-tbody > tr.p-highlight {
    background: #76b729;
    color: #ffffff;
  }
  .p-datatable .p-datatable-tbody > tr.p-datatable-dragpoint-top > td {
    box-shadow: inset 0 2px 0 0 #76b729;
  }
  .p-datatable .p-datatable-tbody > tr.p-datatable-dragpoint-bottom > td {
    box-shadow: inset 0 -2px 0 0 #76b729;
  }
  .p-datatable.p-datatable-hoverable-rows .p-datatable-tbody > tr:not(.p-highlight):hover {
    background: #616061;
    color: #ffffff;
  }
  .p-datatable .p-column-resizer-helper {
    background: #76b729;
  }
  .p-datatable .p-datatable-scrollable-header,
  .p-datatable .p-datatable-scrollable-footer {
    background: #616061;
  }
  .p-datatable.p-datatable-scrollable > .p-datatable-wrapper > .p-datatable-table > .p-datatable-thead,
  .p-datatable.p-datatable-scrollable > .p-datatable-wrapper > .p-datatable-table > .p-datatable-tfoot, .p-datatable.p-datatable-scrollable > .p-datatable-wrapper > .p-scroller-viewport > .p-scroller > .p-datatable-table > .p-datatable-thead,
  .p-datatable.p-datatable-scrollable > .p-datatable-wrapper > .p-scroller-viewport > .p-scroller > .p-datatable-table > .p-datatable-tfoot {
    background-color: #616061;
  }
  .p-datatable .p-datatable-loading-icon {
    font-size: 2rem;
  }
  .p-datatable.p-datatable-gridlines .p-datatable-header {
    border-width: 1px 1px 0 1px;
  }
  .p-datatable.p-datatable-gridlines .p-datatable-footer {
    border-width: 0 1px 1px 1px;
  }
  .p-datatable.p-datatable-gridlines .p-paginator-top {
    border-width: 0 1px 0 1px;
  }
  .p-datatable.p-datatable-gridlines .p-paginator-bottom {
    border-width: 0 1px 1px 1px;
  }
  .p-datatable.p-datatable-gridlines .p-datatable-thead > tr > th {
    border-width: 1px 0 1px 1px;
  }
  .p-datatable.p-datatable-gridlines .p-datatable-thead > tr > th:last-child {
    border-width: 1px;
  }
  .p-datatable.p-datatable-gridlines .p-datatable-tbody > tr > td {
    border-width: 1px 0 0 1px;
  }
  .p-datatable.p-datatable-gridlines .p-datatable-tbody > tr > td:last-child {
    border-width: 1px 1px 0 1px;
  }
  .p-datatable.p-datatable-gridlines .p-datatable-tbody > tr:last-child > td {
    border-width: 1px 0 1px 1px;
  }
  .p-datatable.p-datatable-gridlines .p-datatable-tbody > tr:last-child > td:last-child {
    border-width: 1px;
  }
  .p-datatable.p-datatable-gridlines .p-datatable-tfoot > tr > td {
    border-width: 1px 0 1px 1px;
  }
  .p-datatable.p-datatable-gridlines .p-datatable-tfoot > tr > td:last-child {
    border-width: 1px 1px 1px 1px;
  }
  .p-datatable.p-datatable-gridlines .p-datatable-thead + .p-datatable-tfoot > tr > td {
    border-width: 0 0 1px 1px;
  }
  .p-datatable.p-datatable-gridlines .p-datatable-thead + .p-datatable-tfoot > tr > td:last-child {
    border-width: 0 1px 1px 1px;
  }
  .p-datatable.p-datatable-gridlines:has(.p-datatable-thead):has(.p-datatable-tbody) .p-datatable-tbody > tr > td {
    border-width: 0 0 1px 1px;
  }
  .p-datatable.p-datatable-gridlines:has(.p-datatable-thead):has(.p-datatable-tbody) .p-datatable-tbody > tr > td:last-child {
    border-width: 0 1px 1px 1px;
  }
  .p-datatable.p-datatable-gridlines:has(.p-datatable-tbody):has(.p-datatable-tfoot) .p-datatable-tbody > tr:last-child > td {
    border-width: 0 0 0 1px;
  }
  .p-datatable.p-datatable-gridlines:has(.p-datatable-tbody):has(.p-datatable-tfoot) .p-datatable-tbody > tr:last-child > td:last-child {
    border-width: 0 1px 0 1px;
  }
  .p-datatable.p-datatable-striped .p-datatable-tbody > tr:nth-child(even) {
    background: #ffffff;
  }
  .p-datatable.p-datatable-striped .p-datatable-tbody > tr:nth-child(even).p-highlight {
    background: #76b729;
    color: #ffffff;
  }
  .p-datatable.p-datatable-striped .p-datatable-tbody > tr:nth-child(even).p-highlight .p-row-toggler {
    color: #ffffff;
  }
  .p-datatable.p-datatable-striped .p-datatable-tbody > tr:nth-child(even).p-highlight .p-row-toggler:hover {
    color: #ffffff;
  }
  .p-datatable.p-datatable-sm .p-datatable-header {
    padding: 0.5rem 0;
  }
  .p-datatable.p-datatable-sm .p-datatable-thead > tr > th {
    padding: 0.5rem 0.5rem;
  }
  .p-datatable.p-datatable-sm .p-datatable-tbody > tr > td {
    padding: 0.5rem 0.5rem;
  }
  .p-datatable.p-datatable-sm .p-datatable-tfoot > tr > td {
    padding: 0.5rem 0.5rem;
  }
  .p-datatable.p-datatable-sm .p-datatable-footer {
    padding: 0.5rem 0.5rem;
  }
  .p-datatable.p-datatable-lg .p-datatable-header {
    padding: 1.25rem 0;
  }
  .p-datatable.p-datatable-lg .p-datatable-thead > tr > th {
    padding: 1.25rem 1.25rem;
  }
  .p-datatable.p-datatable-lg .p-datatable-tbody > tr > td {
    padding: 1.25rem 1.25rem;
  }
  .p-datatable.p-datatable-lg .p-datatable-tfoot > tr > td {
    padding: 1.25rem 1.25rem;
  }
  .p-datatable.p-datatable-lg .p-datatable-footer {
    padding: 1.25rem 1.25rem;
  }
  .p-dataview .p-paginator-top {
    border-width: 1px 0 1px 0;
    border-radius: 0;
  }
  .p-dataview .p-paginator-bottom {
    border-width: 0 0 1px 0;
    border-radius: 0;
  }
  .p-dataview .p-dataview-header {
    background: #303030;
    color: #ffffff;
    border: 1px solid #616061;
    border-width: 0 0 1px 0;
    padding: 1rem 0;
    font-weight: 400;
  }
  .p-dataview .p-dataview-content {
    background: #303030;
    color: #ffffff;
    border: 0 none;
    padding: 0;
  }
  .p-dataview.p-dataview-list .p-dataview-content > .p-grid > div {
    border: solid #eeeeee;
    border-width: 0 0 1px 0;
  }
  .p-dataview .p-dataview-footer {
    background: #f8f9fa;
    color: #ffffff;
    border: 1px solid #e9ecef;
    border-width: 0 0 1px 0;
    padding: 1rem 1rem;
    font-weight: 600;
    border-bottom-left-radius: 3px;
    border-bottom-right-radius: 3px;
  }
  .p-dataview .p-dataview-loading-icon {
    font-size: 2rem;
  }
  .p-dataview .p-dataview-emptymessage {
    padding: 1rem;
  }
  .p-column-filter-row .p-column-filter-menu-button,
  .p-column-filter-row .p-column-filter-clear-button {
    margin-left: 0.5rem;
  }
  .p-column-filter-menu-button {
    width: 2rem;
    height: 2rem;
    color: #ffffff;
    border: 0 none;
    background: transparent;
    border-radius: 50%;
    transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
  }
  .p-column-filter-menu-button:hover {
    color: #ffffff;
    border-color: transparent;
    background: #616061;
  }
  .p-column-filter-menu-button.p-column-filter-menu-button-open, .p-column-filter-menu-button.p-column-filter-menu-button-open:hover {
    background: #616061;
    color: #ffffff;
  }
  .p-column-filter-menu-button.p-column-filter-menu-button-active, .p-column-filter-menu-button.p-column-filter-menu-button-active:hover {
    background: #76b729;
    color: #ffffff;
  }
  .p-column-filter-menu-button:focus-visible {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: none;
  }
  .p-column-filter-clear-button {
    width: 2rem;
    height: 2rem;
    color: #ffffff;
    border: 0 none;
    background: transparent;
    border-radius: 50%;
    transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
  }
  .p-column-filter-clear-button:hover {
    color: #ffffff;
    border-color: transparent;
    background: #616061;
  }
  .p-column-filter-clear-button:focus-visible {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: none;
  }
  .p-column-filter-overlay {
    background: #424242;
    color: #ffffff;
    border: 0 none;
    border-radius: 3px;
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.1);
    min-width: 12.5rem;
  }
  .p-column-filter-overlay .p-column-filter-row-items {
    padding: 0.5rem 0;
  }
  .p-column-filter-overlay .p-column-filter-row-items .p-column-filter-row-item {
    margin: 0;
    padding: 0.5rem 1rem;
    border: 0 none;
    color: #ffffff;
    background: transparent;
    transition: background-color 0.2s, border-color 0.2s, box-shadow 0.2s;
    border-radius: 0;
  }
  .p-column-filter-overlay .p-column-filter-row-items .p-column-filter-row-item.p-highlight {
    color: #ffffff;
    background: #76b729;
  }
  .p-column-filter-overlay .p-column-filter-row-items .p-column-filter-row-item:not(.p-highlight):not(.p-disabled):hover {
    color: #ffffff;
    background: #616061;
  }
  .p-column-filter-overlay .p-column-filter-row-items .p-column-filter-row-item:focus-visible {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: inset 0 0 0 0.15rem transparent;
  }
  .p-column-filter-overlay .p-column-filter-row-items .p-column-filter-separator {
    border-top: 1px solid #999999;
    margin: 0.25rem 0;
  }
  .p-column-filter-overlay-menu .p-column-filter-operator {
    padding: 0.5rem 1rem;
    border-bottom: 0 none;
    color: #ffffff;
    background: #424242;
    margin: 0;
    border-top-right-radius: 3px;
    border-top-left-radius: 3px;
  }
  .p-column-filter-overlay-menu .p-column-filter-constraint {
    padding: 1rem;
    border-bottom: 1px solid #999999;
  }
  .p-column-filter-overlay-menu .p-column-filter-constraint .p-column-filter-matchmode-dropdown {
    margin-bottom: 0.5rem;
  }
  .p-column-filter-overlay-menu .p-column-filter-constraint .p-column-filter-remove-button {
    margin-top: 0.5rem;
  }
  .p-column-filter-overlay-menu .p-column-filter-constraint:last-child {
    border-bottom: 0 none;
  }
  .p-column-filter-overlay-menu .p-column-filter-add-rule {
    padding: 0.5rem 1rem;
  }
  .p-column-filter-overlay-menu .p-column-filter-buttonbar {
    padding: 1rem;
  }
  .p-orderlist .p-orderlist-controls {
    padding: 1rem;
  }
  .p-orderlist .p-orderlist-controls .p-button {
    margin-bottom: 0.5rem;
  }
  .p-orderlist .p-orderlist-header {
    background: #616061;
    color: #ffffff;
    border: 1px solid #999999;
    padding: 1rem;
    border-bottom: 0 none;
    border-top-right-radius: 3px;
    border-top-left-radius: 3px;
  }
  .p-orderlist .p-orderlist-header .p-orderlist-title {
    font-weight: 400;
  }
  .p-orderlist .p-orderlist-filter-container {
    padding: 1rem;
    background: none;
    border: 1px solid #999999;
    border-bottom: 0 none;
  }
  .p-orderlist .p-orderlist-filter-container .p-orderlist-filter-input {
    padding-right: 1.5rem;
  }
  .p-orderlist .p-orderlist-filter-container .p-orderlist-filter-icon {
    right: 0.5rem;
    color: #999999;
  }
  .p-orderlist .p-orderlist-list {
    border: 1px solid #999999;
    background: none;
    color: #ffffff;
    padding: 0.5rem 0;
    border-bottom-right-radius: 3px;
    border-bottom-left-radius: 3px;
    outline: 0 none;
  }
  .p-orderlist .p-orderlist-list .p-orderlist-item {
    padding: 0.5rem 1rem;
    margin: 0;
    border: 0 none;
    color: #ffffff;
    background: transparent;
    transition: background-color 0.2s, border-color 0.2s, box-shadow 0.2s;
  }
  .p-orderlist .p-orderlist-list .p-orderlist-item:not(.p-highlight):hover {
    background: #616061;
    color: #ffffff;
  }
  .p-orderlist .p-orderlist-list .p-orderlist-item.p-focus {
    color: #ffffff;
    background: #424242;
  }
  .p-orderlist .p-orderlist-list .p-orderlist-item.p-highlight {
    color: #ffffff;
    background: #76b729;
  }
  .p-orderlist .p-orderlist-list .p-orderlist-item.p-highlight.p-focus {
    background: #76b729;
  }
  .p-orderlist .p-orderlist-list .p-orderlist-empty-message {
    padding: 0.5rem 1rem;
    color: #ffffff;
  }
  .p-orderlist .p-orderlist-list:not(.cdk-drop-list-dragging) .p-orderlist-item:not(.p-highlight):hover {
    background: #616061;
    color: #ffffff;
  }
  .p-orderlist.p-orderlist-striped .p-orderlist-list .p-orderlist-item:nth-child(even) {
    background: #eeeeee;
  }
  .p-orderlist.p-orderlist-striped .p-orderlist-list .p-orderlist-item:nth-child(even):hover {
    background: #616061;
  }
  .p-orderlist-item.cdk-drag-preview {
    padding: 0.5rem 1rem;
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.1);
    border: 0 none;
    color: #ffffff;
    background: none;
    margin: 0;
  }
  .p-organizationchart .p-organizationchart-node-content.p-organizationchart-selectable-node:not(.p-highlight):hover {
    background: #616061;
    color: #ffffff;
  }
  .p-organizationchart .p-organizationchart-node-content.p-highlight {
    background: #76b729;
    color: #ffffff;
  }
  .p-organizationchart .p-organizationchart-node-content.p-highlight .p-node-toggler i {
    color: #334f12;
  }
  .p-organizationchart .p-organizationchart-line-down {
    background: #eeeeee;
  }
  .p-organizationchart .p-organizationchart-line-left {
    border-right: 1px solid #999999;
    border-color: #eeeeee;
  }
  .p-organizationchart .p-organizationchart-line-top {
    border-top: 1px solid #999999;
    border-color: #eeeeee;
  }
  .p-organizationchart .p-organizationchart-node-content {
    border: 1px solid #999999;
    background: none;
    color: #ffffff;
    padding: 1rem;
  }
  .p-organizationchart .p-organizationchart-node-content .p-node-toggler {
    background: inherit;
    color: inherit;
    border-radius: 50%;
  }
  .p-organizationchart .p-organizationchart-node-content .p-node-toggler:focus-visible {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: none;
  }
  .p-paginator {
    background: #303030;
    color: #eeeeee;
    border: solid #eeeeee;
    border-width: 0;
    padding: 0.5rem 1rem;
    border-radius: 3px;
  }
  .p-paginator .p-paginator-first,
  .p-paginator .p-paginator-prev,
  .p-paginator .p-paginator-next,
  .p-paginator .p-paginator-last {
    background-color: transparent;
    border: 0 none;
    color: #eeeeee;
    min-width: 2.357rem;
    height: 2.357rem;
    margin: 0.143rem;
    transition: background-color 0.2s, border-color 0.2s, box-shadow 0.2s;
    border-radius: 3px;
  }
  .p-paginator .p-paginator-first:not(.p-disabled):not(.p-highlight):hover,
  .p-paginator .p-paginator-prev:not(.p-disabled):not(.p-highlight):hover,
  .p-paginator .p-paginator-next:not(.p-disabled):not(.p-highlight):hover,
  .p-paginator .p-paginator-last:not(.p-disabled):not(.p-highlight):hover {
    background: #424242;
    border-color: transparent;
    color: #eeeeee;
  }
  .p-paginator .p-paginator-first {
    border-top-left-radius: 3px;
    border-bottom-left-radius: 3px;
  }
  .p-paginator .p-paginator-last {
    border-top-right-radius: 3px;
    border-bottom-right-radius: 3px;
  }
  .p-paginator .p-dropdown {
    margin-left: 0.5rem;
    margin-right: 0.5rem;
    height: 2.357rem;
  }
  .p-paginator .p-dropdown .p-dropdown-label {
    padding-right: 0;
  }
  .p-paginator .p-paginator-page-input {
    margin-left: 0.5rem;
    margin-right: 0.5rem;
  }
  .p-paginator .p-paginator-page-input .p-inputtext {
    max-width: 2.357rem;
  }
  .p-paginator .p-paginator-current {
    background-color: transparent;
    border: 0 none;
    color: #eeeeee;
    min-width: 2.357rem;
    height: 2.357rem;
    margin: 0.143rem;
    padding: 0 0.5rem;
  }
  .p-paginator .p-paginator-pages .p-paginator-page {
    background-color: transparent;
    border: 0 none;
    color: #eeeeee;
    min-width: 2.357rem;
    height: 2.357rem;
    margin: 0.143rem;
    transition: background-color 0.2s, border-color 0.2s, box-shadow 0.2s;
    border-radius: 3px;
  }
  .p-paginator .p-paginator-pages .p-paginator-page.p-highlight {
    background: #76b729;
    border-color: #76b729;
    color: #ffffff;
  }
  .p-paginator .p-paginator-pages .p-paginator-page:not(.p-highlight):hover {
    background: #424242;
    border-color: transparent;
    color: #eeeeee;
  }
  .p-picklist .p-picklist-buttons {
    padding: 1rem;
  }
  .p-picklist .p-picklist-buttons .p-button {
    margin-bottom: 0.5rem;
  }
  .p-picklist .p-picklist-header {
    background: #616061;
    color: #ffffff;
    border: 1px solid #999999;
    padding: 1rem;
    border-bottom: 0 none;
    border-top-right-radius: 3px;
    border-top-left-radius: 3px;
  }
  .p-picklist .p-picklist-header .p-picklist-title {
    font-weight: 400;
  }
  .p-picklist .p-picklist-filter-container {
    padding: 1rem;
    background: none;
    border: 1px solid #999999;
    border-bottom: 0 none;
  }
  .p-picklist .p-picklist-filter-container .p-picklist-filter-input {
    padding-right: 1.5rem;
  }
  .p-picklist .p-picklist-filter-container .p-picklist-filter-icon {
    right: 0.5rem;
    color: #999999;
  }
  .p-picklist .p-picklist-list {
    border: 1px solid #999999;
    background: none;
    color: #ffffff;
    padding: 0.5rem 0;
    border-bottom-right-radius: 3px;
    border-bottom-left-radius: 3px;
    outline: 0 none;
  }
  .p-picklist .p-picklist-list .p-picklist-item {
    padding: 0.5rem 1rem;
    margin: 0;
    border: 0 none;
    color: #ffffff;
    background: transparent;
    transition: background-color 0.2s, border-color 0.2s, box-shadow 0.2s;
  }
  .p-picklist .p-picklist-list .p-picklist-item:not(.p-highlight):hover {
    background: #616061;
    color: #ffffff;
  }
  .p-picklist .p-picklist-list .p-picklist-item.p-focus {
    color: #ffffff;
    background: #424242;
  }
  .p-picklist .p-picklist-list .p-picklist-item.p-highlight {
    color: #ffffff;
    background: #76b729;
  }
  .p-picklist .p-picklist-list .p-picklist-item.p-highlight.p-focus {
    background: #76b729;
  }
  .p-picklist .p-picklist-list .p-picklist-empty-message {
    padding: 0.5rem 1rem;
    color: #ffffff;
  }
  .p-picklist .p-picklist-list:not(.cdk-drop-list-dragging) .p-picklist-item:not(.p-highlight):hover {
    background: #616061;
    color: #ffffff;
  }
  .p-picklist.p-picklist-striped .p-picklist-list .p-picklist-item:nth-child(even) {
    background: #eeeeee;
  }
  .p-picklist.p-picklist-striped .p-picklist-list .p-picklist-item:nth-child(even):hover {
    background: #616061;
  }
  .p-picklist-item.cdk-drag-preview {
    padding: 0.5rem 1rem;
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.1);
    border: 0 none;
    color: #ffffff;
    background: none;
    margin: 0;
  }
  .p-timeline .p-timeline-event-marker {
    border: 2px solid #76b729;
    border-radius: 50%;
    width: 1rem;
    height: 1rem;
    background-color: #ffffff;
  }
  .p-timeline .p-timeline-event-connector {
    background-color: #eeeeee;
  }
  .p-timeline.p-timeline-vertical .p-timeline-event-opposite,
  .p-timeline.p-timeline-vertical .p-timeline-event-content {
    padding: 0 1rem;
  }
  .p-timeline.p-timeline-vertical .p-timeline-event-connector {
    width: 2px;
  }
  .p-timeline.p-timeline-horizontal .p-timeline-event-opposite,
  .p-timeline.p-timeline-horizontal .p-timeline-event-content {
    padding: 1rem 0;
  }
  .p-timeline.p-timeline-horizontal .p-timeline-event-connector {
    height: 2px;
  }
  .p-tree {
    border: 1px solid #999999;
    background: none;
    color: #ffffff;
    padding: 1rem;
    border-radius: 3px;
  }
  .p-tree .p-tree-container .p-treenode {
    padding: 0.143rem;
    outline: 0 none;
  }
  .p-tree .p-tree-container .p-treenode:focus > .p-treenode-content {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: inset 0 0 0 0.15rem transparent;
  }
  .p-tree .p-tree-container .p-treenode .p-treenode-content {
    border-radius: 3px;
    transition: background-color 0.2s, border-color 0.2s, box-shadow 0.2s;
    padding: 0.25rem 0;
  }
  .p-tree .p-tree-container .p-treenode .p-treenode-content .p-tree-toggler {
    margin-right: 0.5rem;
    width: 2rem;
    height: 2rem;
    color: #ffffff;
    border: 0 none;
    background: transparent;
    border-radius: 50%;
    transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
  }
  .p-tree .p-tree-container .p-treenode .p-treenode-content .p-tree-toggler:enabled:hover {
    color: #ffffff;
    border-color: transparent;
    background: #616061;
  }
  .p-tree .p-tree-container .p-treenode .p-treenode-content .p-tree-toggler:focus-visible {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: none;
  }
  .p-tree .p-tree-container .p-treenode .p-treenode-content .p-treenode-icon {
    margin-right: 0.5rem;
    color: #eeeeee;
  }
  .p-tree .p-tree-container .p-treenode .p-treenode-content .p-checkbox {
    margin-right: 0.5rem;
  }
  .p-tree .p-tree-container .p-treenode .p-treenode-content .p-checkbox .p-indeterminate .p-checkbox-icon {
    color: #ffffff;
  }
  .p-tree .p-tree-container .p-treenode .p-treenode-content.p-highlight {
    background: #76b729;
    color: #ffffff;
  }
  .p-tree .p-tree-container .p-treenode .p-treenode-content.p-highlight .p-tree-toggler,
  .p-tree .p-tree-container .p-treenode .p-treenode-content.p-highlight .p-treenode-icon {
    color: #ffffff;
  }
  .p-tree .p-tree-container .p-treenode .p-treenode-content.p-highlight .p-tree-toggler:hover,
  .p-tree .p-tree-container .p-treenode .p-treenode-content.p-highlight .p-treenode-icon:hover {
    color: #ffffff;
  }
  .p-tree .p-tree-container .p-treenode .p-treenode-content.p-treenode-selectable:not(.p-highlight):hover {
    background: #616061;
    color: #ffffff;
  }
  .p-tree .p-tree-container .p-treenode .p-treenode-content.p-treenode-dragover {
    background: #616061;
    color: #ffffff;
  }
  .p-tree .p-tree-filter-container {
    margin-bottom: 0.5rem;
  }
  .p-tree .p-tree-filter-container .p-tree-filter {
    width: 100%;
    padding-right: 1.5rem;
  }
  .p-tree .p-tree-filter-container .p-tree-filter-icon {
    right: 0.5rem;
    color: #999999;
  }
  .p-tree .p-treenode-children {
    padding: 0 0 0 0.5rem;
  }
  .p-tree .p-tree-loading-icon {
    font-size: 2rem;
  }
  .p-tree .p-tree-loading-icon.p-icon {
    width: 2rem;
    height: 2rem;
  }
  .p-tree .p-treenode-droppoint.p-treenode-droppoint-active {
    background-color: #5e9221;
  }
  .p-tree.p-tree-horizontal .p-treenode .p-treenode-content {
    border-radius: 3px;
    border: 1px solid #999999;
    background-color: none;
    color: #ffffff;
    padding: 0.25rem 0;
    transition: background-color 0.2s, border-color 0.2s, box-shadow 0.2s;
  }
  .p-tree.p-tree-horizontal .p-treenode .p-treenode-content.p-highlight {
    background-color: #76b729;
    color: #ffffff;
  }
  .p-tree.p-tree-horizontal .p-treenode .p-treenode-content.p-highlight .p-treenode-icon {
    color: #ffffff;
  }
  .p-tree.p-tree-horizontal .p-treenode .p-treenode-content .p-tree-toggler {
    margin-right: 0.5rem;
  }
  .p-tree.p-tree-horizontal .p-treenode .p-treenode-content .p-treenode-icon {
    color: #eeeeee;
    margin-right: 0.5rem;
  }
  .p-tree.p-tree-horizontal .p-treenode .p-treenode-content .p-checkbox {
    margin-right: 0.5rem;
  }
  .p-tree.p-tree-horizontal .p-treenode .p-treenode-content .p-treenode-label:not(.p-highlight):hover {
    background-color: inherit;
    color: inherit;
  }
  .p-tree.p-tree-horizontal .p-treenode .p-treenode-content.p-treenode-selectable:not(.p-highlight):hover {
    background: #616061;
    color: #ffffff;
  }
  .p-tree.p-tree-horizontal .p-treenode .p-treenode-content:focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: none;
  }
  .p-treetable .p-paginator-top {
    border-width: 1px 0 1px 0;
    border-radius: 0;
  }
  .p-treetable .p-paginator-bottom {
    border-width: 0 0 1px 0;
    border-radius: 0;
  }
  .p-treetable .p-treetable-header {
    background: #303030;
    color: #ffffff;
    border: 1px solid #616061;
    border-width: 0 0 1px 0;
    padding: 1rem 0;
    font-weight: 400;
  }
  .p-treetable .p-treetable-footer {
    background: #f8f9fa;
    color: #ffffff;
    border: 1px solid #e9ecef;
    border-width: 0 0 1px 0;
    padding: 1rem 1rem;
    font-weight: 600;
  }
  .p-treetable .p-treetable-thead > tr > th {
    text-align: left;
    padding: 1rem 1rem;
    border: 1px solid #616061;
    border-width: 0 0 1px 0;
    font-weight: 600;
    color: #ffffff;
    background: #616061;
    transition: background-color 0.2s, border-color 0.2s, box-shadow 0.2s;
  }
  .p-treetable .p-treetable-tfoot > tr > td {
    text-align: left;
    padding: 1rem 1rem;
    border: 1px solid #e9ecef;
    border-width: 0 0 1px 0;
    font-weight: 600;
    color: #ffffff;
    background: #f8f9fa;
  }
  .p-treetable .p-sortable-column {
    outline-color: rgba(118, 183, 41, 0.4);
  }
  .p-treetable .p-sortable-column .p-sortable-column-icon {
    color: #ffffff;
    margin-left: 0.5rem;
  }
  .p-treetable .p-sortable-column .p-sortable-column-badge {
    border-radius: 50%;
    height: 1.143rem;
    min-width: 1.143rem;
    line-height: 1.143rem;
    color: #ffffff;
    background: #76b729;
    margin-left: 0.5rem;
  }
  .p-treetable .p-sortable-column:not(.p-highlight):hover {
    background: #616061;
    color: #ffffff;
  }
  .p-treetable .p-sortable-column:not(.p-highlight):hover .p-sortable-column-icon {
    color: #ffffff;
  }
  .p-treetable .p-sortable-column.p-highlight {
    background: #616061;
    color: #76b729;
  }
  .p-treetable .p-sortable-column.p-highlight .p-sortable-column-icon {
    color: #76b729;
  }
  .p-treetable .p-treetable-tbody > tr {
    background: #303030;
    color: #ffffff;
    transition: background-color 0.2s, border-color 0.2s, box-shadow 0.2s;
  }
  .p-treetable .p-treetable-tbody > tr > td {
    text-align: left;
    border: 1px solid #eeeeee;
    border-width: 0 0 1px 0;
    padding: 1rem 1rem;
  }
  .p-treetable .p-treetable-tbody > tr > td .p-treetable-toggler {
    width: 2rem;
    height: 2rem;
    color: #ffffff;
    border: 0 none;
    background: transparent;
    border-radius: 50%;
    transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
    margin-right: 0.5rem;
  }
  .p-treetable .p-treetable-tbody > tr > td .p-treetable-toggler:enabled:hover {
    color: #ffffff;
    border-color: transparent;
    background: #616061;
  }
  .p-treetable .p-treetable-tbody > tr > td .p-treetable-toggler:focus-visible {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: none;
  }
  .p-treetable .p-treetable-tbody > tr > td .p-treetable-toggler.p-icon {
    width: 2rem;
    height: 2rem;
  }
  .p-treetable .p-treetable-tbody > tr > td p-treetablecheckbox .p-checkbox {
    margin-right: 0.5rem;
  }
  .p-treetable .p-treetable-tbody > tr > td p-treetablecheckbox .p-checkbox .p-indeterminate .p-checkbox-icon {
    color: #ffffff;
  }
  .p-treetable .p-treetable-tbody > tr:focus-visible {
    outline: 0.15rem solid rgba(118, 183, 41, 0.4);
    outline-offset: -0.15rem;
  }
  .p-treetable .p-treetable-tbody > tr.p-highlight {
    background: #76b729;
    color: #ffffff;
  }
  .p-treetable .p-treetable-tbody > tr.p-highlight .p-treetable-toggler {
    color: #ffffff;
  }
  .p-treetable .p-treetable-tbody > tr.p-highlight .p-treetable-toggler:hover {
    color: #ffffff;
  }
  .p-treetable.p-treetable-hoverable-rows .p-treetable-tbody > tr:not(.p-highlight):hover {
    background: #616061;
    color: #ffffff;
  }
  .p-treetable.p-treetable-hoverable-rows .p-treetable-tbody > tr:not(.p-highlight):hover .p-treetable-toggler {
    color: #ffffff;
  }
  .p-treetable .p-column-resizer-helper {
    background: #76b729;
  }
  .p-treetable .p-treetable-scrollable-header,
  .p-treetable .p-treetable-scrollable-footer {
    background: #616061;
  }
  .p-treetable .p-treetable-loading-icon {
    font-size: 2rem;
  }
  .p-treetable .p-treetable-loading-icon.p-icon {
    width: 2rem;
    height: 2rem;
  }
  .p-treetable.p-treetable-gridlines .p-datatable-header {
    border-width: 1px 1px 0 1px;
  }
  .p-treetable.p-treetable-gridlines .p-treetable-footer {
    border-width: 0 1px 1px 1px;
  }
  .p-treetable.p-treetable-gridlines .p-treetable-top {
    border-width: 0 1px 0 1px;
  }
  .p-treetable.p-treetable-gridlines .p-treetable-bottom {
    border-width: 0 1px 1px 1px;
  }
  .p-treetable.p-treetable-gridlines .p-treetable-thead > tr > th {
    border-width: 1px;
  }
  .p-treetable.p-treetable-gridlines .p-treetable-tbody > tr > td {
    border-width: 1px;
  }
  .p-treetable.p-treetable-gridlines .p-treetable-tfoot > tr > td {
    border-width: 1px;
  }
  .p-treetable.p-treetable-sm .p-treetable-header {
    padding: 0.875rem 0;
  }
  .p-treetable.p-treetable-sm .p-treetable-thead > tr > th {
    padding: 0.5rem 0.5rem;
  }
  .p-treetable.p-treetable-sm .p-treetable-tbody > tr > td {
    padding: 0.5rem 0.5rem;
  }
  .p-treetable.p-treetable-sm .p-treetable-tfoot > tr > td {
    padding: 0.5rem 0.5rem;
  }
  .p-treetable.p-treetable-sm .p-treetable-footer {
    padding: 0.5rem 0.5rem;
  }
  .p-treetable.p-treetable-lg .p-treetable-header {
    padding: 1.25rem 0;
  }
  .p-treetable.p-treetable-lg .p-treetable-thead > tr > th {
    padding: 1.25rem 1.25rem;
  }
  .p-treetable.p-treetable-lg .p-treetable-tbody > tr > td {
    padding: 1.25rem 1.25rem;
  }
  .p-treetable.p-treetable-lg .p-treetable-tfoot > tr > td {
    padding: 1.25rem 1.25rem;
  }
  .p-treetable.p-treetable-lg .p-treetable-footer {
    padding: 1.25rem 1.25rem;
  }
  .p-virtualscroller .p-virtualscroller-header {
    background: #303030;
    color: #ffffff;
    border: 1px solid #616061;
    border-width: 0 0 1px 0;
    padding: 1rem 0;
    font-weight: 400;
  }
  .p-virtualscroller .p-virtualscroller-content {
    background: #303030;
    color: #ffffff;
    border: 0 none;
    padding: 0;
  }
  .p-virtualscroller .p-virtualscroller-footer {
    background: #f8f9fa;
    color: #ffffff;
    border: 1px solid #e9ecef;
    border-width: 0 0 1px 0;
    padding: 1rem 1rem;
    font-weight: 600;
    border-bottom-left-radius: 3px;
    border-bottom-right-radius: 3px;
  }
  .p-accordion .p-accordion-header .p-accordion-header-link {
    padding: 1rem;
    border: 1px solid #999999;
    color: #ffffff;
    background: #616061;
    font-weight: 400;
    border-radius: 3px;
    transition: background-color 0.2s, border-color 0.2s, box-shadow 0.2s;
  }
  .p-accordion .p-accordion-header .p-accordion-header-link .p-accordion-toggle-icon {
    margin-right: 0.5rem;
  }
  .p-accordion .p-accordion-header:not(.p-disabled) .p-accordion-header-link:focus-visible {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: inset none;
  }
  .p-accordion .p-accordion-header:not(.p-highlight):not(.p-disabled):hover .p-accordion-header-link {
    background: #616061;
    border-color: transparent;
    color: #ffffff;
  }
  .p-accordion .p-accordion-header:not(.p-disabled).p-highlight .p-accordion-header-link {
    background: #616061;
    border-color: #616061;
    color: #ffffff;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
  }
  .p-accordion .p-accordion-header:not(.p-disabled).p-highlight:hover .p-accordion-header-link {
    border-color: transparent;
    background: #616061;
    color: #ffffff;
  }
  .p-accordion .p-accordion-content {
    padding: 1rem;
    border: 1px solid #999999;
    background: none;
    color: #ffffff;
    border-top: 0;
    border-top-right-radius: 0;
    border-top-left-radius: 0;
    border-bottom-right-radius: 3px;
    border-bottom-left-radius: 3px;
  }
  .p-accordion p-accordiontab .p-accordion-tab {
    margin-bottom: 0;
  }
  .p-accordion p-accordiontab .p-accordion-header .p-accordion-header-link {
    border-radius: 0;
  }
  .p-accordion p-accordiontab .p-accordion-content {
    border-radius: 0;
  }
  .p-accordion p-accordiontab:not(:first-child) .p-accordion-header .p-accordion-header-link {
    border-top: 0 none;
  }
  .p-accordion p-accordiontab:not(:first-child) .p-accordion-header:not(.p-highlight):not(.p-disabled):hover .p-accordion-header-link, .p-accordion p-accordiontab:not(:first-child) .p-accordion-header:not(.p-disabled).p-highlight:hover .p-accordion-header-link {
    border-top: 0 none;
  }
  .p-accordion p-accordiontab:first-child .p-accordion-header .p-accordion-header-link {
    border-top-right-radius: 3px;
    border-top-left-radius: 3px;
  }
  .p-accordion p-accordiontab:last-child .p-accordion-header:not(.p-highlight) .p-accordion-header-link {
    border-bottom-right-radius: 3px;
    border-bottom-left-radius: 3px;
  }
  .p-accordion p-accordiontab:last-child .p-accordion-content {
    border-bottom-right-radius: 3px;
    border-bottom-left-radius: 3px;
  }
  .p-card {
    background: none;
    color: #ffffff;
    box-shadow: none;
    border-radius: 3px;
  }
  .p-card .p-card-body {
    padding: 1rem;
  }
  .p-card .p-card-title {
    font-size: 1.5rem;
    font-weight: 700;
    margin-bottom: 0.5rem;
  }
  .p-card .p-card-subtitle {
    font-weight: 700;
    margin-bottom: 0.5rem;
    color: #eeeeee;
  }
  .p-card .p-card-content {
    padding: 1rem 0;
  }
  .p-card .p-card-footer {
    padding: 1rem 0 0 0;
  }
  .p-divider .p-divider-content {
    background-color: none;
  }
  .p-divider.p-divider-horizontal {
    margin: 1rem 0;
    padding: 0 1rem;
  }
  .p-divider.p-divider-horizontal:before {
    border-top: 1px #999999;
  }
  .p-divider.p-divider-horizontal .p-divider-content {
    padding: 0 0.5rem;
  }
  .p-divider.p-divider-vertical {
    margin: 0 1rem;
    padding: 1rem 0;
  }
  .p-divider.p-divider-vertical:before {
    border-left: 1px #999999;
  }
  .p-divider.p-divider-vertical .p-divider-content {
    padding: 0.5rem 0;
  }
  .p-fieldset {
    border: 1px solid #999999;
    background: none;
    color: #ffffff;
    border-radius: 3px;
  }
  .p-fieldset .p-fieldset-legend {
    padding: 1rem;
    border: 1px solid #999999;
    color: #ffffff;
    background: #616061;
    font-weight: 400;
    border-radius: 3px;
  }
  .p-fieldset.p-fieldset-toggleable .p-fieldset-legend {
    padding: 0;
    transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
  }
  .p-fieldset.p-fieldset-toggleable .p-fieldset-legend a {
    padding: 1rem;
    color: #ffffff;
    border-radius: 3px;
    transition: background-color 0.2s, border-color 0.2s, box-shadow 0.2s;
  }
  .p-fieldset.p-fieldset-toggleable .p-fieldset-legend a .p-fieldset-toggler {
    margin-right: 0.5rem;
  }
  .p-fieldset.p-fieldset-toggleable .p-fieldset-legend a:focus-visible {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: none;
  }
  .p-fieldset.p-fieldset-toggleable .p-fieldset-legend:hover {
    background: #e9ecef;
    border-color: #eeeeee;
    color: #ffffff;
  }
  .p-fieldset .p-fieldset-content {
    padding: 1rem;
  }
  .p-panel .p-panel-header {
    border: 1px solid #999999;
    padding: 1rem;
    background: #616061;
    color: #ffffff;
    border-top-right-radius: 3px;
    border-top-left-radius: 3px;
  }
  .p-panel .p-panel-header .p-panel-title {
    font-weight: 400;
  }
  .p-panel .p-panel-header .p-panel-header-icon {
    width: 2rem;
    height: 2rem;
    color: #ffffff;
    border: 0 none;
    background: transparent;
    border-radius: 50%;
    transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
  }
  .p-panel .p-panel-header .p-panel-header-icon:enabled:hover {
    color: #ffffff;
    border-color: transparent;
    background: #616061;
  }
  .p-panel .p-panel-header .p-panel-header-icon:focus-visible {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: none;
  }
  .p-panel.p-panel-toggleable .p-panel-header {
    padding: 0.5rem 1rem;
  }
  .p-panel .p-panel-content {
    padding: 1rem;
    border: 1px solid #999999;
    background: none;
    color: #ffffff;
    border-top: 0 none;
  }
  .p-panel .p-panel-content:last-child {
    border-bottom-right-radius: 3px;
    border-bottom-left-radius: 3px;
  }
  .p-panel .p-panel-footer {
    padding: 0.5rem 1rem;
    border: 1px solid #eeeeee;
    background: #ffffff;
    color: #ffffff;
    border-bottom-right-radius: 3px;
    border-bottom-left-radius: 3px;
    border-top: 0 none;
  }
  .p-panel .p-panel-icons-end {
    order: 2;
    margin-left: auto;
  }
  .p-panel .p-panel-icons-start {
    order: 0;
    margin-right: 0.5rem;
  }
  .p-panel .p-panel-icons-center {
    order: 2;
    width: 100%;
    text-align: center;
  }
  .p-scrollpanel .p-scrollpanel-bar {
    background: #eeeeee;
    border: 0 none;
  }
  .p-splitter {
    border: 1px solid #999999;
    background: none;
    border-radius: 3px;
    color: #ffffff;
  }
  .p-splitter .p-splitter-gutter {
    transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
    background: #eeeeee;
  }
  .p-splitter .p-splitter-gutter .p-splitter-gutter-handle {
    background: #eeeeee;
  }
  .p-splitter .p-splitter-gutter-resizing {
    background: #eeeeee;
  }
  .p-tabview .p-tabview-nav-content {
    scroll-padding-inline: 2.357rem;
  }
  .p-tabview .p-tabview-nav {
    background: none;
    border: 2px solid #616061;
    border-width: 0 0 2px 0;
  }
  .p-tabview .p-tabview-nav li {
    margin-right: 0;
  }
  .p-tabview .p-tabview-nav li .p-tabview-nav-link {
    border: 2px solid;
    border-width: 0 0 2px 0;
    border-color: transparent transparent #616061 transparent;
    background: none;
    color: #999999;
    padding: 1rem;
    font-weight: 600;
    border-top-right-radius: 3px;
    border-top-left-radius: 3px;
    transition: background-color 0.2s, border-color 0.2s, box-shadow 0.2s;
    margin: 0 0 -2px 0;
  }
  .p-tabview .p-tabview-nav li .p-tabview-nav-link:not(.p-disabled):focus-visible {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: inset none;
  }
  .p-tabview .p-tabview-nav li:not(.p-highlight):not(.p-disabled):hover .p-tabview-nav-link {
    background: none;
    border-color: #ffffff;
    color: #ffffff;
  }
  .p-tabview .p-tabview-nav li.p-highlight .p-tabview-nav-link {
    background: transparent;
    border-color: #ffffff;
    color: #eeeeee;
  }
  .p-tabview .p-tabview-left-icon {
    margin-right: 0.5rem;
  }
  .p-tabview .p-tabview-right-icon {
    margin-left: 0.5rem;
  }
  .p-tabview .p-tabview-close {
    margin-left: 0.5rem;
  }
  .p-tabview .p-tabview-nav-btn.p-link {
    background: transparent;
    color: #eeeeee;
    width: 2.357rem;
    box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
    border-radius: 0;
  }
  .p-tabview .p-tabview-nav-btn.p-link:focus-visible {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: inset none;
  }
  .p-tabview .p-tabview-panels {
    background: none;
    padding: 0;
    border: 0 none;
    color: #ffffff;
    border-bottom-right-radius: 3px;
    border-bottom-left-radius: 3px;
  }
  .p-toolbar {
    background: #616061;
    border: 1px solid #999999;
    padding: 1rem;
    border-radius: 3px;
    gap: 0.5rem;
  }
  .p-toolbar .p-toolbar-separator {
    margin: 0 0.5rem;
  }
  .p-confirm-popup {
    background: #424242;
    color: #ffffff;
    border: 0 none;
    border-radius: 3px;
    box-shadow: 0 0 14px 0 rgba(0, 0, 0, 0.1);
  }
  .p-confirm-popup .p-confirm-popup-content {
    padding: 1rem;
  }
  .p-confirm-popup .p-confirm-popup-footer {
    text-align: right;
    padding: 0.5rem 1rem;
  }
  .p-confirm-popup .p-confirm-popup-footer button {
    margin: 0 0.5rem 0 0;
    width: auto;
  }
  .p-confirm-popup .p-confirm-popup-footer button:last-child {
    margin: 0;
  }
  .p-confirm-popup:after {
    border: solid transparent;
    border-color: rgba(66, 66, 66, 0);
    border-bottom-color: #424242;
  }
  .p-confirm-popup:before {
    border: solid transparent;
    border-color: rgba(66, 66, 66, 0);
    border-bottom-color: #424242;
  }
  .p-confirm-popup.p-confirm-popup-flipped:after {
    border-top-color: #424242;
  }
  .p-confirm-popup.p-confirm-popup-flipped:before {
    border-top-color: #424242;
  }
  .p-confirm-popup .p-confirm-popup-icon {
    font-size: 1.5rem;
  }
  .p-confirm-popup .p-confirm-popup-icon.p-icon {
    width: 1.5rem;
    height: 1.5rem;
  }
  .p-confirm-popup .p-confirm-popup-message {
    margin-left: 1rem;
  }
  .p-dialog {
    border-radius: 3px;
    box-shadow: 0 0 14px 0 rgba(0, 0, 0, 0.1);
    border: 0 none;
  }
  .p-dialog .p-dialog-header {
    border-bottom: 1px solid #424242;
    background: #424242;
    color: #ffffff;
    padding: 1rem 1.5rem;
    border-top-right-radius: 3px;
    border-top-left-radius: 3px;
  }
  .p-dialog .p-dialog-header .p-dialog-title {
    font-weight: 400;
    font-size: 1.125rem;
  }
  .p-dialog .p-dialog-header .p-dialog-header-icon {
    width: 2rem;
    height: 2rem;
    color: #ffffff;
    border: 0 none;
    background: transparent;
    border-radius: 50%;
    transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
    margin-right: 0.5rem;
  }
  .p-dialog .p-dialog-header .p-dialog-header-icon:enabled:hover {
    color: #ffffff;
    border-color: transparent;
    background: #616061;
  }
  .p-dialog .p-dialog-header .p-dialog-header-icon:focus-visible {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: none;
  }
  .p-dialog .p-dialog-header .p-dialog-header-icon:last-child {
    margin-right: 0;
  }
  .p-dialog .p-dialog-content {
    background: #424242;
    color: #ffffff;
    padding: 0 1.5rem 1rem;
  }
  .p-dialog .p-dialog-content:last-of-type {
    border-bottom-right-radius: 3px;
    border-bottom-left-radius: 3px;
  }
  .p-dialog .p-dialog-footer {
    border-top: 1px solid #424242;
    background: #424242;
    color: #ffffff;
    padding: 0.5rem 1.5rem 1.5rem;
    text-align: right;
    border-bottom-right-radius: 3px;
    border-bottom-left-radius: 3px;
  }
  .p-dialog .p-dialog-footer button {
    margin: 0 0.5rem 0 0;
    width: auto;
  }
  .p-dialog.p-confirm-dialog .p-confirm-dialog-icon {
    font-size: 2rem;
  }
  .p-dialog.p-confirm-dialog .p-confirm-dialog-icon.p-icon {
    width: 2rem;
    height: 2rem;
  }
  .p-dialog.p-confirm-dialog .p-confirm-dialog-message {
    margin-left: 1rem;
  }
  .p-overlaypanel {
    background: #424242;
    color: #ffffff;
    border: 0 none;
    border-radius: 3px;
    box-shadow: 0 0 14px 0 rgba(0, 0, 0, 0.1);
  }
  .p-overlaypanel .p-overlaypanel-content {
    padding: 1rem;
  }
  .p-overlaypanel .p-overlaypanel-close {
    background: #76b729;
    color: #ffffff;
    width: 2rem;
    height: 2rem;
    transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
    border-radius: 50%;
    position: absolute;
    top: -1rem;
    right: -1rem;
  }
  .p-overlaypanel .p-overlaypanel-close:enabled:hover {
    background: #6aa424;
    color: #ffffff;
  }
  .p-overlaypanel:after {
    border: solid transparent;
    border-color: rgba(66, 66, 66, 0);
    border-bottom-color: #424242;
  }
  .p-overlaypanel:before {
    border: solid transparent;
    border-color: rgba(66, 66, 66, 0);
    border-bottom-color: #3f3f3f;
  }
  .p-overlaypanel.p-overlaypanel-flipped:after {
    border-top-color: #424242;
  }
  .p-overlaypanel.p-overlaypanel-flipped:before {
    border-top-color: #424242;
  }
  .p-sidebar {
    background: #424242;
    color: #ffffff;
    border: 0 none;
    box-shadow: 0 0 14px 0 rgba(0, 0, 0, 0.1);
  }
  .p-sidebar .p-sidebar-header {
    padding: 1rem;
  }
  .p-sidebar .p-sidebar-header .p-sidebar-close,
  .p-sidebar .p-sidebar-header .p-sidebar-icon {
    width: 2rem;
    height: 2rem;
    color: #ffffff;
    border: 0 none;
    background: transparent;
    border-radius: 50%;
    transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
  }
  .p-sidebar .p-sidebar-header .p-sidebar-close:enabled:hover,
  .p-sidebar .p-sidebar-header .p-sidebar-icon:enabled:hover {
    color: #ffffff;
    border-color: transparent;
    background: #616061;
  }
  .p-sidebar .p-sidebar-header .p-sidebar-close:focus-visible,
  .p-sidebar .p-sidebar-header .p-sidebar-icon:focus-visible {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: none;
  }
  .p-sidebar .p-sidebar-header + .p-sidebar-content {
    padding-top: 0;
  }
  .p-sidebar .p-sidebar-content {
    padding: 1rem;
  }
  .p-sidebar .p-sidebar-footer {
    padding: 1rem;
  }
  .p-sidebar .p-panelmenu .p-panelmenu-header-content > a {
    background-color: #424242;
    color: #ffffff !important;
    border: none;
    border-radius: 0 !important;
    font-weight: 400;
    font-size: 1rem;
  }
  .p-sidebar .p-panelmenu .p-panelmenu-header-content > a.p-menuitem-link-active {
    background-color: #ffffff;
    color: #121212 !important;
    border: none;
  }
  .desktop-sidebar .p-panelmenu .p-panelmenu-header-content > a:hover, .desktop-sidebar .p-panelmenu .p-panelmenu-header-content > a:active {
    color: #121212 !important;
    background-color: #ffffff;
    border: none;
  }
  .p-tooltip .p-tooltip-text {
    background: #616061;
    color: #ffffff;
    padding: 0.5rem 0.5rem;
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.1);
    border-radius: 3px;
  }
  .p-tooltip.p-tooltip-right .p-tooltip-arrow {
    border-right-color: #616061;
  }
  .p-tooltip.p-tooltip-left .p-tooltip-arrow {
    border-left-color: #616061;
  }
  .p-tooltip.p-tooltip-top .p-tooltip-arrow {
    border-top-color: #616061;
  }
  .p-tooltip.p-tooltip-bottom .p-tooltip-arrow {
    border-bottom-color: #616061;
  }
  .p-fileupload .p-fileupload-buttonbar {
    background: #616061;
    padding: 1rem;
    border: 1px solid #999999;
    color: #ffffff;
    border-bottom: 0 none;
    border-top-right-radius: 3px;
    border-top-left-radius: 3px;
  }
  .p-fileupload .p-fileupload-buttonbar .p-button {
    margin-right: 0.5rem;
  }
  .p-fileupload .p-fileupload-buttonbar .p-button.p-fileupload-choose.p-focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: none;
  }
  .p-fileupload .p-fileupload-content {
    background: none;
    padding: 2rem 1rem;
    border: 1px solid #999999;
    color: #ffffff;
    border-bottom-right-radius: 3px;
    border-bottom-left-radius: 3px;
  }
  .p-fileupload .p-fileupload-content.p-fileupload-highlight {
    border-color: 1px dashed #e9ecef;
    border-style: dashed;
    background-color: #76b729;
  }
  .p-fileupload .p-progressbar {
    height: 0.25rem;
  }
  .p-fileupload .p-fileupload-row > div {
    padding: 1rem 1rem;
  }
  .p-fileupload.p-fileupload-advanced .p-message {
    margin-top: 0;
  }
  .p-fileupload-choose:not(.p-disabled):hover {
    background: #6aa424;
    color: #ffffff;
    border-color: #6aa424;
  }
  .p-fileupload-choose:not(.p-disabled):active {
    background: #6aa424;
    color: #ffffff;
    border-color: #6aa424;
  }
  .p-breadcrumb {
    background: #616061;
    border: none;
    border-radius: 3px;
    padding: 0;
  }
  .p-breadcrumb .p-breadcrumb-list li .p-menuitem-link {
    transition: background-color 0.2s, border-color 0.2s, box-shadow 0.2s;
    border-radius: 3px;
  }
  .p-breadcrumb .p-breadcrumb-list li .p-menuitem-link:focus-visible {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: none;
  }
  .p-breadcrumb .p-breadcrumb-list li .p-menuitem-link .p-menuitem-text {
    color: #ffffff;
  }
  .p-breadcrumb .p-breadcrumb-list li .p-menuitem-link .p-menuitem-icon {
    color: #ffffff;
  }
  .p-breadcrumb .p-breadcrumb-list li.p-menuitem-separator {
    margin: 0 0.5rem 0 0.5rem;
    color: #ffffff;
  }
  .p-breadcrumb .p-breadcrumb-list li:last-child .p-menuitem-text {
    color: #ffffff;
  }
  .p-breadcrumb .p-breadcrumb-list li:last-child .p-menuitem-icon {
    color: #ffffff;
  }
  .p-contextmenu {
    padding: 0.5rem 0;
    background: #424242;
    color: #eeeeee;
    border: 0 none;
    box-shadow: 0 1px 6px 0 rgba(0, 0, 0, 0.1);
    border-radius: 3px;
    width: 12.5rem;
  }
  .p-contextmenu .p-contextmenu-root-list {
    outline: 0 none;
  }
  .p-contextmenu .p-submenu-list {
    padding: 0.5rem 0;
    background: #424242;
    border: 0 none;
    box-shadow: 0 1px 6px 0 rgba(0, 0, 0, 0.1);
    border-radius: 3px;
  }
  .p-contextmenu .p-menuitem > .p-menuitem-content {
    color: #eeeeee;
    transition: background-color 0.2s, border-color 0.2s, box-shadow 0.2s;
    border-radius: 0;
  }
  .p-contextmenu .p-menuitem > .p-menuitem-content .p-menuitem-link {
    color: #eeeeee;
    padding: 0.75rem 1rem;
    user-select: none;
  }
  .p-contextmenu .p-menuitem > .p-menuitem-content .p-menuitem-link .p-menuitem-text {
    color: #eeeeee;
  }
  .p-contextmenu .p-menuitem > .p-menuitem-content .p-menuitem-link .p-menuitem-icon {
    color: #eeeeee;
    margin-right: 0.5rem;
  }
  .p-contextmenu .p-menuitem > .p-menuitem-content .p-menuitem-link .p-submenu-icon {
    color: #eeeeee;
  }
  .p-contextmenu .p-menuitem.p-highlight > .p-menuitem-content {
    color: #eeeeee;
    background: #616061;
  }
  .p-contextmenu .p-menuitem.p-highlight > .p-menuitem-content .p-menuitem-link .p-menuitem-text {
    color: #eeeeee;
  }
  .p-contextmenu .p-menuitem.p-highlight > .p-menuitem-content .p-menuitem-link .p-menuitem-icon,
  .p-contextmenu .p-menuitem.p-highlight > .p-menuitem-content .p-menuitem-link .p-submenu-icon {
    color: #eeeeee;
  }
  .p-contextmenu .p-menuitem.p-highlight.p-focus > .p-menuitem-content {
    background: #616061;
  }
  .p-contextmenu .p-menuitem:not(.p-highlight):not(.p-disabled).p-focus > .p-menuitem-content {
    color: #eeeeee;
    background: #616061;
  }
  .p-contextmenu .p-menuitem:not(.p-highlight):not(.p-disabled).p-focus > .p-menuitem-content .p-menuitem-link .p-menuitem-text {
    color: #eeeeee;
  }
  .p-contextmenu .p-menuitem:not(.p-highlight):not(.p-disabled).p-focus > .p-menuitem-content .p-menuitem-link .p-menuitem-icon,
  .p-contextmenu .p-menuitem:not(.p-highlight):not(.p-disabled).p-focus > .p-menuitem-content .p-menuitem-link .p-submenu-icon {
    color: #eeeeee;
  }
  .p-contextmenu .p-menuitem:not(.p-highlight):not(.p-disabled) > .p-menuitem-content:hover {
    color: #eeeeee;
    background: #616061;
  }
  .p-contextmenu .p-menuitem:not(.p-highlight):not(.p-disabled) > .p-menuitem-content:hover .p-menuitem-link .p-menuitem-text {
    color: #eeeeee;
  }
  .p-contextmenu .p-menuitem:not(.p-highlight):not(.p-disabled) > .p-menuitem-content:hover .p-menuitem-link .p-menuitem-icon,
  .p-contextmenu .p-menuitem:not(.p-highlight):not(.p-disabled) > .p-menuitem-content:hover .p-menuitem-link .p-submenu-icon {
    color: #eeeeee;
  }
  .p-contextmenu .p-menuitem-separator {
    border-top: 1px solid #999999;
    margin: 0.25rem 0;
  }
  .p-contextmenu .p-submenu-icon {
    font-size: 0.875rem;
  }
  .p-contextmenu .p-submenu-icon.p-icon {
    width: 0.875rem;
    height: 0.875rem;
  }
  .p-dock .p-dock-list-container {
    background: rgba(255, 255, 255, 0.1);
    border: 1px solid rgba(255, 255, 255, 0.2);
    padding: 0.5rem 0.5rem;
    border-radius: 0.5rem;
  }
  .p-dock .p-dock-list-container .p-dock-list {
    outline: 0 none;
  }
  .p-dock .p-dock-item {
    padding: 0.5rem;
    border-radius: 3px;
  }
  .p-dock .p-dock-item.p-focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: inset 0 0 0 0.15rem transparent;
  }
  .p-dock .p-dock-link {
    width: 4rem;
    height: 4rem;
  }
  .p-dock.p-dock-top .p-dock-item-second-prev,
  .p-dock.p-dock-top .p-dock-item-second-next, .p-dock.p-dock-bottom .p-dock-item-second-prev,
  .p-dock.p-dock-bottom .p-dock-item-second-next {
    margin: 0 0.9rem;
  }
  .p-dock.p-dock-top .p-dock-item-prev,
  .p-dock.p-dock-top .p-dock-item-next, .p-dock.p-dock-bottom .p-dock-item-prev,
  .p-dock.p-dock-bottom .p-dock-item-next {
    margin: 0 1.3rem;
  }
  .p-dock.p-dock-top .p-dock-item-current, .p-dock.p-dock-bottom .p-dock-item-current {
    margin: 0 1.5rem;
  }
  .p-dock.p-dock-left .p-dock-item-second-prev,
  .p-dock.p-dock-left .p-dock-item-second-next, .p-dock.p-dock-right .p-dock-item-second-prev,
  .p-dock.p-dock-right .p-dock-item-second-next {
    margin: 0.9rem 0;
  }
  .p-dock.p-dock-left .p-dock-item-prev,
  .p-dock.p-dock-left .p-dock-item-next, .p-dock.p-dock-right .p-dock-item-prev,
  .p-dock.p-dock-right .p-dock-item-next {
    margin: 1.3rem 0;
  }
  .p-dock.p-dock-left .p-dock-item-current, .p-dock.p-dock-right .p-dock-item-current {
    margin: 1.5rem 0;
  }
  @media screen and (max-width: 960px) {
    .p-dock.p-dock-top .p-dock-list-container, .p-dock.p-dock-bottom .p-dock-list-container {
      overflow-x: auto;
      width: 100%;
    }
    .p-dock.p-dock-top .p-dock-list-container .p-dock-list, .p-dock.p-dock-bottom .p-dock-list-container .p-dock-list {
      margin: 0 auto;
    }
    .p-dock.p-dock-left .p-dock-list-container, .p-dock.p-dock-right .p-dock-list-container {
      overflow-y: auto;
      height: 100%;
    }
    .p-dock.p-dock-left .p-dock-list-container .p-dock-list, .p-dock.p-dock-right .p-dock-list-container .p-dock-list {
      margin: auto 0;
    }
    .p-dock .p-dock-list .p-dock-item {
      transform: none;
      margin: 0;
    }
  }
  .p-megamenu {
    padding: 0.5rem;
    background: #f8f9fa;
    color: #eeeeee;
    border: none;
    border-radius: 3px;
  }
  .p-megamenu .p-megamenu-root-list {
    outline: 0 none;
  }
  .p-megamenu .p-menuitem > .p-menuitem-content {
    color: #eeeeee;
    transition: background-color 0.2s, border-color 0.2s, box-shadow 0.2s;
    border-radius: 0;
  }
  .p-megamenu .p-menuitem > .p-menuitem-content .p-menuitem-link {
    color: #eeeeee;
    padding: 0.75rem 1rem;
    user-select: none;
  }
  .p-megamenu .p-menuitem > .p-menuitem-content .p-menuitem-link .p-menuitem-text {
    color: #eeeeee;
  }
  .p-megamenu .p-menuitem > .p-menuitem-content .p-menuitem-link .p-menuitem-icon {
    color: #eeeeee;
    margin-right: 0.5rem;
  }
  .p-megamenu .p-menuitem > .p-menuitem-content .p-menuitem-link .p-submenu-icon {
    color: #eeeeee;
  }
  .p-megamenu .p-menuitem.p-highlight > .p-menuitem-content {
    color: #eeeeee;
    background: #616061;
  }
  .p-megamenu .p-menuitem.p-highlight > .p-menuitem-content .p-menuitem-link .p-menuitem-text {
    color: #eeeeee;
  }
  .p-megamenu .p-menuitem.p-highlight > .p-menuitem-content .p-menuitem-link .p-menuitem-icon,
  .p-megamenu .p-menuitem.p-highlight > .p-menuitem-content .p-menuitem-link .p-submenu-icon {
    color: #eeeeee;
  }
  .p-megamenu .p-menuitem.p-highlight.p-focus > .p-menuitem-content {
    background: #616061;
  }
  .p-megamenu .p-menuitem:not(.p-highlight):not(.p-disabled).p-focus > .p-menuitem-content {
    color: #eeeeee;
    background: #616061;
  }
  .p-megamenu .p-menuitem:not(.p-highlight):not(.p-disabled).p-focus > .p-menuitem-content .p-menuitem-link .p-menuitem-text {
    color: #eeeeee;
  }
  .p-megamenu .p-menuitem:not(.p-highlight):not(.p-disabled).p-focus > .p-menuitem-content .p-menuitem-link .p-menuitem-icon,
  .p-megamenu .p-menuitem:not(.p-highlight):not(.p-disabled).p-focus > .p-menuitem-content .p-menuitem-link .p-submenu-icon {
    color: #eeeeee;
  }
  .p-megamenu .p-menuitem:not(.p-highlight):not(.p-disabled) > .p-menuitem-content:hover {
    color: #eeeeee;
    background: #616061;
  }
  .p-megamenu .p-menuitem:not(.p-highlight):not(.p-disabled) > .p-menuitem-content:hover .p-menuitem-link .p-menuitem-text {
    color: #eeeeee;
  }
  .p-megamenu .p-menuitem:not(.p-highlight):not(.p-disabled) > .p-menuitem-content:hover .p-menuitem-link .p-menuitem-icon,
  .p-megamenu .p-menuitem:not(.p-highlight):not(.p-disabled) > .p-menuitem-content:hover .p-menuitem-link .p-submenu-icon {
    color: #eeeeee;
  }
  .p-megamenu .p-megamenu-panel {
    background: #424242;
    color: #eeeeee;
    border: 0 none;
    box-shadow: 0 1px 6px 0 rgba(0, 0, 0, 0.1);
  }
  .p-megamenu .p-submenu-header {
    margin: 0;
    padding: 0.75rem 1rem;
    color: #ffffff;
    background: #ffffff;
    font-weight: 600;
    border-top-right-radius: 3px;
    border-top-left-radius: 3px;
  }
  .p-megamenu .p-submenu-list {
    padding: 0.5rem 0;
    width: 12.5rem;
  }
  .p-megamenu .p-submenu-list .p-menuitem-separator {
    border-top: 1px solid #999999;
    margin: 0.25rem 0;
  }
  .p-megamenu.p-megamenu-vertical {
    width: 12.5rem;
    padding: 0.5rem 0;
  }
  .p-megamenu.p-megamenu-horizontal .p-megamenu-root-list > .p-menuitem > .p-menuitem-content {
    color: #eeeeee;
    transition: background-color 0.2s, border-color 0.2s, box-shadow 0.2s;
    border-radius: 3px;
  }
  .p-megamenu.p-megamenu-horizontal .p-megamenu-root-list > .p-menuitem > .p-menuitem-content .p-menuitem-link {
    padding: 0.75rem 1rem;
    user-select: none;
  }
  .p-megamenu.p-megamenu-horizontal .p-megamenu-root-list > .p-menuitem > .p-menuitem-content .p-menuitem-link .p-menuitem-text {
    color: #eeeeee;
  }
  .p-megamenu.p-megamenu-horizontal .p-megamenu-root-list > .p-menuitem > .p-menuitem-content .p-menuitem-link .p-menuitem-icon {
    color: #eeeeee;
    margin-right: 0.5rem;
  }
  .p-megamenu.p-megamenu-horizontal .p-megamenu-root-list > .p-menuitem > .p-menuitem-content .p-menuitem-link .p-submenu-icon {
    color: #eeeeee;
    margin-left: 0.5rem;
  }
  .p-megamenu.p-megamenu-horizontal .p-megamenu-root-list > .p-menuitem:not(.p-highlight):not(.p-disabled) > .p-menuitem-content:hover {
    color: #eeeeee;
    background: #616061;
  }
  .p-megamenu.p-megamenu-horizontal .p-megamenu-root-list > .p-menuitem:not(.p-highlight):not(.p-disabled) > .p-menuitem-content:hover .p-menuitem-link .p-menuitem-text {
    color: #eeeeee;
  }
  .p-megamenu.p-megamenu-horizontal .p-megamenu-root-list > .p-menuitem:not(.p-highlight):not(.p-disabled) > .p-menuitem-content:hover .p-menuitem-link .p-menuitem-icon,
  .p-megamenu.p-megamenu-horizontal .p-megamenu-root-list > .p-menuitem:not(.p-highlight):not(.p-disabled) > .p-menuitem-content:hover .p-menuitem-link .p-submenu-icon {
    color: #eeeeee;
  }
  .p-menu {
    padding: 0.5rem 0;
    background: #424242;
    color: #eeeeee;
    border: none;
    border-radius: 3px;
    width: 12.5rem;
  }
  .p-menu .p-menuitem > .p-menuitem-content {
    color: #eeeeee;
    transition: background-color 0.2s, border-color 0.2s, box-shadow 0.2s;
    border-radius: 0;
  }
  .p-menu .p-menuitem > .p-menuitem-content .p-menuitem-link {
    color: #eeeeee;
    padding: 0.75rem 1rem;
    user-select: none;
  }
  .p-menu .p-menuitem > .p-menuitem-content .p-menuitem-link .p-menuitem-text {
    color: #eeeeee;
  }
  .p-menu .p-menuitem > .p-menuitem-content .p-menuitem-link .p-menuitem-icon {
    color: #eeeeee;
    margin-right: 0.5rem;
  }
  .p-menu .p-menuitem > .p-menuitem-content .p-menuitem-link .p-submenu-icon {
    color: #eeeeee;
  }
  .p-menu .p-menuitem.p-highlight > .p-menuitem-content {
    color: #eeeeee;
    background: #616061;
  }
  .p-menu .p-menuitem.p-highlight > .p-menuitem-content .p-menuitem-link .p-menuitem-text {
    color: #eeeeee;
  }
  .p-menu .p-menuitem.p-highlight > .p-menuitem-content .p-menuitem-link .p-menuitem-icon,
  .p-menu .p-menuitem.p-highlight > .p-menuitem-content .p-menuitem-link .p-submenu-icon {
    color: #eeeeee;
  }
  .p-menu .p-menuitem.p-highlight.p-focus > .p-menuitem-content {
    background: #616061;
  }
  .p-menu .p-menuitem:not(.p-highlight):not(.p-disabled).p-focus > .p-menuitem-content {
    color: #eeeeee;
    background: #616061;
  }
  .p-menu .p-menuitem:not(.p-highlight):not(.p-disabled).p-focus > .p-menuitem-content .p-menuitem-link .p-menuitem-text {
    color: #eeeeee;
  }
  .p-menu .p-menuitem:not(.p-highlight):not(.p-disabled).p-focus > .p-menuitem-content .p-menuitem-link .p-menuitem-icon,
  .p-menu .p-menuitem:not(.p-highlight):not(.p-disabled).p-focus > .p-menuitem-content .p-menuitem-link .p-submenu-icon {
    color: #eeeeee;
  }
  .p-menu .p-menuitem:not(.p-highlight):not(.p-disabled) > .p-menuitem-content:hover {
    color: #eeeeee;
    background: #616061;
  }
  .p-menu .p-menuitem:not(.p-highlight):not(.p-disabled) > .p-menuitem-content:hover .p-menuitem-link .p-menuitem-text {
    color: #eeeeee;
  }
  .p-menu .p-menuitem:not(.p-highlight):not(.p-disabled) > .p-menuitem-content:hover .p-menuitem-link .p-menuitem-icon,
  .p-menu .p-menuitem:not(.p-highlight):not(.p-disabled) > .p-menuitem-content:hover .p-menuitem-link .p-submenu-icon {
    color: #eeeeee;
  }
  .p-menu.p-menu-overlay {
    background: #424242;
    border: 0 none;
    box-shadow: 0 1px 6px 0 rgba(0, 0, 0, 0.1);
  }
  .p-menu .p-submenu-header {
    margin: 0;
    padding: 0.75rem 1rem;
    color: #ffffff;
    background: #ffffff;
    font-weight: 600;
    border-top-right-radius: 0;
    border-top-left-radius: 0;
  }
  .p-menu .p-menuitem-separator {
    border-top: 1px solid #999999;
    margin: 0.25rem 0;
  }
  .p-menu .p-menuitem-badge {
    background: #76b729;
    color: #ffffff;
    font-size: 0.75rem;
    font-weight: 700;
    min-width: 1.5rem;
    height: 1.5rem;
    line-height: 1.5rem;
    border-radius: 3px;
    margin-left: 0.5rem;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
  }
  .p-menubar {
    padding: 0.5rem;
    background: #f8f9fa;
    color: #eeeeee;
    border: none;
    border-radius: 3px;
  }
  .p-menubar .p-menubar-root-list {
    outline: 0 none;
  }
  .p-menubar .p-menubar-root-list > .p-menuitem > .p-menuitem-content {
    color: #eeeeee;
    transition: background-color 0.2s, border-color 0.2s, box-shadow 0.2s;
    border-radius: 3px;
  }
  .p-menubar .p-menubar-root-list > .p-menuitem > .p-menuitem-content .p-menuitem-link {
    padding: 0.75rem 1rem;
    user-select: none;
  }
  .p-menubar .p-menubar-root-list > .p-menuitem > .p-menuitem-content .p-menuitem-link .p-menuitem-text {
    color: #eeeeee;
  }
  .p-menubar .p-menubar-root-list > .p-menuitem > .p-menuitem-content .p-menuitem-link .p-menuitem-icon {
    color: #eeeeee;
    margin-right: 0.5rem;
  }
  .p-menubar .p-menubar-root-list > .p-menuitem > .p-menuitem-content .p-menuitem-link .p-submenu-icon {
    color: #eeeeee;
    margin-left: 0.5rem;
  }
  .p-menubar .p-menubar-root-list > .p-menuitem:not(.p-highlight):not(.p-disabled) > .p-menuitem-content:hover {
    color: #eeeeee;
    background: #616061;
  }
  .p-menubar .p-menubar-root-list > .p-menuitem:not(.p-highlight):not(.p-disabled) > .p-menuitem-content:hover .p-menuitem-link .p-menuitem-text {
    color: #eeeeee;
  }
  .p-menubar .p-menubar-root-list > .p-menuitem:not(.p-highlight):not(.p-disabled) > .p-menuitem-content:hover .p-menuitem-link .p-menuitem-icon,
  .p-menubar .p-menubar-root-list > .p-menuitem:not(.p-highlight):not(.p-disabled) > .p-menuitem-content:hover .p-menuitem-link .p-submenu-icon {
    color: #eeeeee;
  }
  .p-menubar .p-menuitem > .p-menuitem-content {
    color: #eeeeee;
    transition: background-color 0.2s, border-color 0.2s, box-shadow 0.2s;
    border-radius: 0;
  }
  .p-menubar .p-menuitem > .p-menuitem-content .p-menuitem-link {
    color: #eeeeee;
    padding: 0.75rem 1rem;
    user-select: none;
  }
  .p-menubar .p-menuitem > .p-menuitem-content .p-menuitem-link .p-menuitem-text {
    color: #eeeeee;
  }
  .p-menubar .p-menuitem > .p-menuitem-content .p-menuitem-link .p-menuitem-icon {
    color: #eeeeee;
    margin-right: 0.5rem;
  }
  .p-menubar .p-menuitem > .p-menuitem-content .p-menuitem-link .p-submenu-icon {
    color: #eeeeee;
  }
  .p-menubar .p-menuitem.p-highlight > .p-menuitem-content {
    color: #eeeeee;
    background: #616061;
  }
  .p-menubar .p-menuitem.p-highlight > .p-menuitem-content .p-menuitem-link .p-menuitem-text {
    color: #eeeeee;
  }
  .p-menubar .p-menuitem.p-highlight > .p-menuitem-content .p-menuitem-link .p-menuitem-icon,
  .p-menubar .p-menuitem.p-highlight > .p-menuitem-content .p-menuitem-link .p-submenu-icon {
    color: #eeeeee;
  }
  .p-menubar .p-menuitem.p-highlight.p-focus > .p-menuitem-content {
    background: #616061;
  }
  .p-menubar .p-menuitem:not(.p-highlight):not(.p-disabled).p-focus > .p-menuitem-content {
    color: #eeeeee;
    background: #616061;
  }
  .p-menubar .p-menuitem:not(.p-highlight):not(.p-disabled).p-focus > .p-menuitem-content .p-menuitem-link .p-menuitem-text {
    color: #eeeeee;
  }
  .p-menubar .p-menuitem:not(.p-highlight):not(.p-disabled).p-focus > .p-menuitem-content .p-menuitem-link .p-menuitem-icon,
  .p-menubar .p-menuitem:not(.p-highlight):not(.p-disabled).p-focus > .p-menuitem-content .p-menuitem-link .p-submenu-icon {
    color: #eeeeee;
  }
  .p-menubar .p-menuitem:not(.p-highlight):not(.p-disabled) > .p-menuitem-content:hover {
    color: #eeeeee;
    background: #616061;
  }
  .p-menubar .p-menuitem:not(.p-highlight):not(.p-disabled) > .p-menuitem-content:hover .p-menuitem-link .p-menuitem-text {
    color: #eeeeee;
  }
  .p-menubar .p-menuitem:not(.p-highlight):not(.p-disabled) > .p-menuitem-content:hover .p-menuitem-link .p-menuitem-icon,
  .p-menubar .p-menuitem:not(.p-highlight):not(.p-disabled) > .p-menuitem-content:hover .p-menuitem-link .p-submenu-icon {
    color: #eeeeee;
  }
  .p-menubar .p-submenu-list {
    padding: 0.5rem 0;
    background: #424242;
    border: 0 none;
    box-shadow: 0 1px 6px 0 rgba(0, 0, 0, 0.1);
    width: 12.5rem;
  }
  .p-menubar .p-submenu-list .p-menuitem-separator {
    border-top: 1px solid #999999;
    margin: 0.25rem 0;
  }
  .p-menubar .p-submenu-list .p-submenu-icon {
    font-size: 0.875rem;
  }
  @media screen and (max-width: 960px) {
    .p-menubar {
      position: relative;
    }
    .p-menubar .p-menubar-button {
      display: flex;
      width: 2rem;
      height: 2rem;
      color: #eeeeee;
      border-radius: 50%;
      transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
    }
    .p-menubar .p-menubar-button:hover {
      color: #eeeeee;
      background: #616061;
    }
    .p-menubar .p-menubar-button:focus {
      outline: 0 none;
      outline-offset: 0;
      box-shadow: none;
    }
    .p-menubar .p-menubar-root-list {
      position: absolute;
      display: none;
      padding: 0.5rem 0;
      background: #424242;
      border: 0 none;
      box-shadow: 0 1px 6px 0 rgba(0, 0, 0, 0.1);
      width: 100%;
    }
    .p-menubar .p-menubar-root-list .p-menuitem-separator {
      border-top: 1px solid #999999;
      margin: 0.25rem 0;
    }
    .p-menubar .p-menubar-root-list .p-submenu-icon {
      font-size: 0.875rem;
    }
    .p-menubar .p-menubar-root-list .p-menuitem {
      width: 100%;
      position: static;
    }
    .p-menubar .p-menubar-root-list .p-menuitem .p-menuitem-content .p-menuitem-link .p-submenu-icon {
      margin-left: auto;
      transition: transform 0.2s;
    }
    .p-menubar .p-menubar-root-list .p-menuitem.p-menuitem-active > .p-menuitem-content > .p-menuitem-link > .p-submenu-icon {
      transform: rotate(-180deg);
    }
    .p-menubar .p-menubar-root-list .p-submenu-list {
      width: 100%;
      position: static;
      box-shadow: none;
      border: 0 none;
    }
    .p-menubar .p-menubar-root-list .p-submenu-list .p-submenu-icon {
      transition: transform 0.2s;
      transform: rotate(90deg);
    }
    .p-menubar .p-menubar-root-list .p-submenu-list .p-menuitem-active > .p-menuitem-content > .p-menuitem-link > .p-submenu-icon {
      transform: rotate(-90deg);
    }
    .p-menubar .p-menubar-root-list .p-menuitem {
      width: 100%;
      position: static;
    }
    .p-menubar .p-menubar-root-list .p-submenu-list .p-menuitem .p-menuitem-content .p-menuitem-link {
      padding-left: 2.25rem;
    }
    .p-menubar .p-menubar-root-list .p-submenu-list .p-menuitem .p-submenu-list .p-menuitem .p-menuitem-content .p-menuitem-link {
      padding-left: 3.75rem;
    }
    .p-menubar .p-menubar-root-list .p-submenu-list .p-menuitem .p-submenu-list .p-menuitem .p-submenu-list .p-menuitem .p-menuitem-content .p-menuitem-link {
      padding-left: 5.25rem;
    }
    .p-menubar .p-menubar-root-list .p-submenu-list .p-menuitem .p-submenu-list .p-menuitem .p-submenu-list .p-menuitem .p-submenu-list .p-menuitem .p-menuitem-content .p-menuitem-link {
      padding-left: 6.75rem;
    }
    .p-menubar .p-menubar-root-list .p-submenu-list .p-menuitem .p-submenu-list .p-menuitem .p-submenu-list .p-menuitem .p-submenu-list .p-menuitem .p-submenu-list .p-menuitem .p-menuitem-content .p-menuitem-link {
      padding-left: 8.25rem;
    }
    .p-menubar.p-menubar-mobile-active .p-menubar-root-list {
      display: flex;
      flex-direction: column;
      top: 100%;
      left: 0;
      z-index: 1;
    }
  }
  .p-panelmenu .p-panelmenu-header {
    outline: 0 none;
  }
  .p-panelmenu .p-panelmenu-header .p-panelmenu-header-content {
    border: 1px solid #999999;
    color: #ffffff;
    background: #616061;
    border-radius: 3px;
    transition: background-color 0.2s, border-color 0.2s, box-shadow 0.2s;
  }
  .p-panelmenu .p-panelmenu-header .p-panelmenu-header-content .p-panelmenu-header-action {
    color: #ffffff;
    padding: 1rem;
    font-weight: 400;
  }
  .p-panelmenu .p-panelmenu-header .p-panelmenu-header-content .p-panelmenu-header-action .p-submenu-icon {
    margin-right: 0.5rem;
  }
  .p-panelmenu .p-panelmenu-header .p-panelmenu-header-content .p-panelmenu-header-action .p-menuitem-icon {
    margin-right: 0.5rem;
  }
  .p-panelmenu .p-panelmenu-header:not(.p-disabled):focus-visible .p-panelmenu-header-content {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: inset none;
  }
  .p-panelmenu .p-panelmenu-header:not(.p-highlight):not(.p-disabled):hover .p-panelmenu-header-content {
    background: #616061;
    border-color: transparent;
    color: #ffffff;
  }
  .p-panelmenu .p-panelmenu-header:not(.p-disabled).p-highlight .p-panelmenu-header-content {
    background: #616061;
    border-color: #616061;
    color: #ffffff;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
    margin-bottom: 0;
  }
  .p-panelmenu .p-panelmenu-header:not(.p-disabled).p-highlight:hover .p-panelmenu-header-content {
    border-color: transparent;
    background: #616061;
    color: #ffffff;
  }
  .p-panelmenu .p-panelmenu-content {
    padding: 0.5rem 0;
    border: 1px solid #999999;
    background: none;
    color: #ffffff;
    border-top: 0;
    border-top-right-radius: 0;
    border-top-left-radius: 0;
    border-bottom-right-radius: 3px;
    border-bottom-left-radius: 3px;
  }
  .p-panelmenu .p-panelmenu-content .p-panelmenu-root-list {
    outline: 0 none;
  }
  .p-panelmenu .p-panelmenu-content .p-menuitem > .p-menuitem-content {
    color: #eeeeee;
    transition: background-color 0.2s, border-color 0.2s, box-shadow 0.2s;
    border-radius: 0;
  }
  .p-panelmenu .p-panelmenu-content .p-menuitem > .p-menuitem-content .p-menuitem-link {
    color: #eeeeee;
    padding: 0.75rem 1rem;
    user-select: none;
  }
  .p-panelmenu .p-panelmenu-content .p-menuitem > .p-menuitem-content .p-menuitem-link .p-menuitem-text {
    color: #eeeeee;
  }
  .p-panelmenu .p-panelmenu-content .p-menuitem > .p-menuitem-content .p-menuitem-link .p-menuitem-icon {
    color: #eeeeee;
    margin-right: 0.5rem;
  }
  .p-panelmenu .p-panelmenu-content .p-menuitem > .p-menuitem-content .p-menuitem-link .p-submenu-icon {
    color: #eeeeee;
  }
  .p-panelmenu .p-panelmenu-content .p-menuitem.p-highlight > .p-menuitem-content {
    color: #eeeeee;
    background: #616061;
  }
  .p-panelmenu .p-panelmenu-content .p-menuitem.p-highlight > .p-menuitem-content .p-menuitem-link .p-menuitem-text {
    color: #eeeeee;
  }
  .p-panelmenu .p-panelmenu-content .p-menuitem.p-highlight > .p-menuitem-content .p-menuitem-link .p-menuitem-icon,
  .p-panelmenu .p-panelmenu-content .p-menuitem.p-highlight > .p-menuitem-content .p-menuitem-link .p-submenu-icon {
    color: #eeeeee;
  }
  .p-panelmenu .p-panelmenu-content .p-menuitem.p-highlight.p-focus > .p-menuitem-content {
    background: #616061;
  }
  .p-panelmenu .p-panelmenu-content .p-menuitem:not(.p-highlight):not(.p-disabled).p-focus > .p-menuitem-content {
    color: #eeeeee;
    background: #616061;
  }
  .p-panelmenu .p-panelmenu-content .p-menuitem:not(.p-highlight):not(.p-disabled).p-focus > .p-menuitem-content .p-menuitem-link .p-menuitem-text {
    color: #eeeeee;
  }
  .p-panelmenu .p-panelmenu-content .p-menuitem:not(.p-highlight):not(.p-disabled).p-focus > .p-menuitem-content .p-menuitem-link .p-menuitem-icon,
  .p-panelmenu .p-panelmenu-content .p-menuitem:not(.p-highlight):not(.p-disabled).p-focus > .p-menuitem-content .p-menuitem-link .p-submenu-icon {
    color: #eeeeee;
  }
  .p-panelmenu .p-panelmenu-content .p-menuitem:not(.p-highlight):not(.p-disabled) > .p-menuitem-content:hover {
    color: #eeeeee;
    background: #616061;
  }
  .p-panelmenu .p-panelmenu-content .p-menuitem:not(.p-highlight):not(.p-disabled) > .p-menuitem-content:hover .p-menuitem-link .p-menuitem-text {
    color: #eeeeee;
  }
  .p-panelmenu .p-panelmenu-content .p-menuitem:not(.p-highlight):not(.p-disabled) > .p-menuitem-content:hover .p-menuitem-link .p-menuitem-icon,
  .p-panelmenu .p-panelmenu-content .p-menuitem:not(.p-highlight):not(.p-disabled) > .p-menuitem-content:hover .p-menuitem-link .p-submenu-icon {
    color: #eeeeee;
  }
  .p-panelmenu .p-panelmenu-content .p-menuitem .p-menuitem-content .p-menuitem-link .p-submenu-icon {
    margin-right: 0.5rem;
  }
  .p-panelmenu .p-panelmenu-content .p-menuitem-separator {
    border-top: 1px solid #999999;
    margin: 0.25rem 0;
  }
  .p-panelmenu .p-panelmenu-content .p-submenu-list:not(.p-panelmenu-root-list) {
    padding: 0 0 0 0.5rem;
  }
  .p-panelmenu .p-panelmenu-panel {
    margin-bottom: 0;
  }
  .p-panelmenu .p-panelmenu-panel .p-panelmenu-header .p-panelmenu-header-content {
    border-radius: 0;
  }
  .p-panelmenu .p-panelmenu-panel .p-panelmenu-content {
    border-radius: 0;
  }
  .p-panelmenu .p-panelmenu-panel:not(:first-child) .p-panelmenu-header .p-panelmenu-header-content {
    border-top: 0 none;
  }
  .p-panelmenu .p-panelmenu-panel:not(:first-child) .p-panelmenu-header:not(.p-highlight):not(.p-disabled):hover .p-panelmenu-header-content, .p-panelmenu .p-panelmenu-panel:not(:first-child) .p-panelmenu-header:not(.p-disabled).p-highlight:hover .p-panelmenu-header-content {
    border-top: 0 none;
  }
  .p-panelmenu .p-panelmenu-panel:first-child .p-panelmenu-header .p-panelmenu-header-content {
    border-top-right-radius: 3px;
    border-top-left-radius: 3px;
  }
  .p-panelmenu .p-panelmenu-panel:last-child .p-panelmenu-header:not(.p-highlight) .p-panelmenu-header-content {
    border-bottom-right-radius: 3px;
    border-bottom-left-radius: 3px;
  }
  .p-panelmenu .p-panelmenu-panel:last-child .p-panelmenu-content {
    border-bottom-right-radius: 3px;
    border-bottom-left-radius: 3px;
  }
  .p-slidemenu {
    padding: 0.5rem 0;
    background: #424242;
    color: #eeeeee;
    border: none;
    border-radius: 3px;
    width: 12.5rem;
  }
  .p-slidemenu .p-slidemenu-root-list {
    outline: 0 none;
  }
  .p-slidemenu .p-submenu-list {
    outline: 0 none;
  }
  .p-slidemenu .p-menuitem > .p-menuitem-content {
    color: #eeeeee;
    transition: background-color 0.2s, border-color 0.2s, box-shadow 0.2s;
    border-radius: 0;
  }
  .p-slidemenu .p-menuitem > .p-menuitem-content .p-menuitem-link {
    color: #eeeeee;
    padding: 0.75rem 1rem;
    user-select: none;
  }
  .p-slidemenu .p-menuitem > .p-menuitem-content .p-menuitem-link .p-menuitem-text {
    color: #eeeeee;
  }
  .p-slidemenu .p-menuitem > .p-menuitem-content .p-menuitem-link .p-menuitem-icon {
    color: #eeeeee;
    margin-right: 0.5rem;
  }
  .p-slidemenu .p-menuitem > .p-menuitem-content .p-menuitem-link .p-submenu-icon {
    color: #eeeeee;
  }
  .p-slidemenu .p-menuitem.p-highlight > .p-menuitem-content {
    color: #eeeeee;
    background: #616061;
  }
  .p-slidemenu .p-menuitem.p-highlight > .p-menuitem-content .p-menuitem-link .p-menuitem-text {
    color: #eeeeee;
  }
  .p-slidemenu .p-menuitem.p-highlight > .p-menuitem-content .p-menuitem-link .p-menuitem-icon,
  .p-slidemenu .p-menuitem.p-highlight > .p-menuitem-content .p-menuitem-link .p-submenu-icon {
    color: #eeeeee;
  }
  .p-slidemenu .p-menuitem.p-highlight.p-focus > .p-menuitem-content {
    background: #616061;
  }
  .p-slidemenu .p-menuitem:not(.p-highlight):not(.p-disabled).p-focus > .p-menuitem-content {
    color: #eeeeee;
    background: #616061;
  }
  .p-slidemenu .p-menuitem:not(.p-highlight):not(.p-disabled).p-focus > .p-menuitem-content .p-menuitem-link .p-menuitem-text {
    color: #eeeeee;
  }
  .p-slidemenu .p-menuitem:not(.p-highlight):not(.p-disabled).p-focus > .p-menuitem-content .p-menuitem-link .p-menuitem-icon,
  .p-slidemenu .p-menuitem:not(.p-highlight):not(.p-disabled).p-focus > .p-menuitem-content .p-menuitem-link .p-submenu-icon {
    color: #eeeeee;
  }
  .p-slidemenu .p-menuitem:not(.p-highlight):not(.p-disabled) > .p-menuitem-content:hover {
    color: #eeeeee;
    background: #616061;
  }
  .p-slidemenu .p-menuitem:not(.p-highlight):not(.p-disabled) > .p-menuitem-content:hover .p-menuitem-link .p-menuitem-text {
    color: #eeeeee;
  }
  .p-slidemenu .p-menuitem:not(.p-highlight):not(.p-disabled) > .p-menuitem-content:hover .p-menuitem-link .p-menuitem-icon,
  .p-slidemenu .p-menuitem:not(.p-highlight):not(.p-disabled) > .p-menuitem-content:hover .p-menuitem-link .p-submenu-icon {
    color: #eeeeee;
  }
  .p-slidemenu.p-slidemenu-overlay {
    background: #424242;
    border: 0 none;
    box-shadow: 0 1px 6px 0 rgba(0, 0, 0, 0.1);
  }
  .p-slidemenu .p-slidemenu-list {
    padding: 0.5rem 0;
    background: #424242;
    border: 0 none;
    box-shadow: 0 1px 6px 0 rgba(0, 0, 0, 0.1);
  }
  .p-slidemenu .p-menuitem-separator {
    border-top: 1px solid #999999;
    margin: 0.25rem 0;
  }
  .p-slidemenu .p-slidemenu-icon {
    font-size: 0.875rem;
  }
  .p-slidemenu .p-icon {
    width: 0.875rem;
    height: 0.875rem;
  }
  .p-slidemenu .p-slidemenu-backward {
    padding: 0.75rem 1rem;
    color: #eeeeee;
  }
  .p-slidemenu .p-slidemenu-backward:not(.p-disabled):focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: inset none;
  }
  .p-slidemenu .p-menuitem-badge {
    background: #76b729;
    color: #ffffff;
    font-size: 0.75rem;
    font-weight: 700;
    min-width: 1.5rem;
    height: 1.5rem;
    line-height: 1.5rem;
    border-radius: 3px;
    margin-left: 0.5rem;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
  }
  .p-steps .p-steps-item .p-menuitem-link {
    background: transparent;
    transition: background-color 0.2s, border-color 0.2s, box-shadow 0.2s;
    border-radius: 3px;
    background: transparent;
  }
  .p-steps .p-steps-item .p-menuitem-link .p-steps-number {
    color: #ffffff;
    border: 1px solid #76b729;
    background: transparent;
    min-width: 2rem;
    height: 2rem;
    line-height: 2rem;
    font-size: 1.143rem;
    z-index: 1;
    border-radius: 12px 0;
  }
  .p-steps .p-steps-item .p-menuitem-link .p-steps-title {
    margin-top: 0.5rem;
    color: #eeeeee;
  }
  .p-steps .p-steps-item .p-menuitem-link:not(.p-disabled):focus-visible {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: none;
  }
  .p-steps .p-steps-item.p-highlight .p-steps-number {
    background: #76b729;
    color: #ffffff;
  }
  .p-steps .p-steps-item.p-highlight .p-steps-title {
    font-weight: 600;
    color: #ffffff;
  }
  .p-steps .p-steps-item:before {
    content: " ";
    border-top: 1px solid #999999;
    width: 100%;
    top: 50%;
    left: 0;
    display: block;
    position: absolute;
    margin-top: -1rem;
  }
  .p-tabmenu .p-tabmenu-nav {
    background: none;
    border: 2px solid #616061;
    border-width: 0 0 2px 0;
  }
  .p-tabmenu .p-tabmenu-nav .p-menuitem-badge {
    background: #76b729;
    color: #ffffff;
    font-size: 0.75rem;
    font-weight: 700;
    min-width: 1.5rem;
    height: 1.5rem;
    line-height: 1.5rem;
    border-radius: 3px;
    margin-left: 0.5rem;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
  }
  .p-tabmenu .p-tabmenu-nav .p-tabmenuitem {
    margin-right: 0;
  }
  .p-tabmenu .p-tabmenu-nav .p-tabmenuitem .p-menuitem-link {
    border: 2px solid;
    border-width: 0 0 2px 0;
    border-color: transparent transparent #616061 transparent;
    background: none;
    color: #999999;
    padding: 1rem;
    font-weight: 600;
    border-top-right-radius: 3px;
    border-top-left-radius: 3px;
    transition: background-color 0.2s, border-color 0.2s, box-shadow 0.2s;
    margin: 0 0 -2px 0;
  }
  .p-tabmenu .p-tabmenu-nav .p-tabmenuitem .p-menuitem-link .p-menuitem-icon {
    margin-right: 0.5rem;
  }
  .p-tabmenu .p-tabmenu-nav .p-tabmenuitem .p-menuitem-link:not(.p-disabled):focus-visible {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: inset none;
  }
  .p-tabmenu .p-tabmenu-nav .p-tabmenuitem:not(.p-highlight):not(.p-disabled):hover .p-menuitem-link {
    background: none;
    border-color: #ffffff;
    color: #ffffff;
  }
  .p-tabmenu .p-tabmenu-nav .p-tabmenuitem.p-highlight .p-menuitem-link {
    background: transparent;
    border-color: #ffffff;
    color: #eeeeee;
  }
  .p-tabmenu .p-tabmenu-left-icon {
    margin-right: 0.5rem;
  }
  .p-tabmenu .p-tabmenu-right-icon {
    margin-left: 0.5rem;
  }
  .p-tabmenu .p-tabmenu-nav-btn.p-link {
    background: transparent;
    color: #eeeeee;
    width: 2.357rem;
    box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
    border-radius: 0;
  }
  .p-tabmenu .p-tabmenu-nav-btn.p-link:focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: inset none;
  }
  .p-tieredmenu {
    padding: 0.5rem 0;
    background: #424242;
    color: #eeeeee;
    border: none;
    border-radius: 3px;
    width: 12.5rem;
  }
  .p-tieredmenu.p-tieredmenu-overlay {
    background: #424242;
    border: 0 none;
    box-shadow: 0 1px 6px 0 rgba(0, 0, 0, 0.1);
  }
  .p-tieredmenu .p-tieredmenu-root-list {
    outline: 0 none;
  }
  .p-tieredmenu .p-submenu-list {
    padding: 0.5rem 0;
    background: #424242;
    border: 0 none;
    box-shadow: 0 1px 6px 0 rgba(0, 0, 0, 0.1);
  }
  .p-tieredmenu .p-menuitem > .p-menuitem-content {
    color: #eeeeee;
    transition: background-color 0.2s, border-color 0.2s, box-shadow 0.2s;
    border-radius: 0;
  }
  .p-tieredmenu .p-menuitem > .p-menuitem-content .p-menuitem-link {
    color: #eeeeee;
    padding: 0.75rem 1rem;
    user-select: none;
  }
  .p-tieredmenu .p-menuitem > .p-menuitem-content .p-menuitem-link .p-menuitem-text {
    color: #eeeeee;
  }
  .p-tieredmenu .p-menuitem > .p-menuitem-content .p-menuitem-link .p-menuitem-icon {
    color: #eeeeee;
    margin-right: 0.5rem;
  }
  .p-tieredmenu .p-menuitem > .p-menuitem-content .p-menuitem-link .p-submenu-icon {
    color: #eeeeee;
  }
  .p-tieredmenu .p-menuitem.p-highlight > .p-menuitem-content {
    color: #eeeeee;
    background: #616061;
  }
  .p-tieredmenu .p-menuitem.p-highlight > .p-menuitem-content .p-menuitem-link .p-menuitem-text {
    color: #eeeeee;
  }
  .p-tieredmenu .p-menuitem.p-highlight > .p-menuitem-content .p-menuitem-link .p-menuitem-icon,
  .p-tieredmenu .p-menuitem.p-highlight > .p-menuitem-content .p-menuitem-link .p-submenu-icon {
    color: #eeeeee;
  }
  .p-tieredmenu .p-menuitem.p-highlight.p-focus > .p-menuitem-content {
    background: #616061;
  }
  .p-tieredmenu .p-menuitem:not(.p-highlight):not(.p-disabled).p-focus > .p-menuitem-content {
    color: #eeeeee;
    background: #616061;
  }
  .p-tieredmenu .p-menuitem:not(.p-highlight):not(.p-disabled).p-focus > .p-menuitem-content .p-menuitem-link .p-menuitem-text {
    color: #eeeeee;
  }
  .p-tieredmenu .p-menuitem:not(.p-highlight):not(.p-disabled).p-focus > .p-menuitem-content .p-menuitem-link .p-menuitem-icon,
  .p-tieredmenu .p-menuitem:not(.p-highlight):not(.p-disabled).p-focus > .p-menuitem-content .p-menuitem-link .p-submenu-icon {
    color: #eeeeee;
  }
  .p-tieredmenu .p-menuitem:not(.p-highlight):not(.p-disabled) > .p-menuitem-content:hover {
    color: #eeeeee;
    background: #616061;
  }
  .p-tieredmenu .p-menuitem:not(.p-highlight):not(.p-disabled) > .p-menuitem-content:hover .p-menuitem-link .p-menuitem-text {
    color: #eeeeee;
  }
  .p-tieredmenu .p-menuitem:not(.p-highlight):not(.p-disabled) > .p-menuitem-content:hover .p-menuitem-link .p-menuitem-icon,
  .p-tieredmenu .p-menuitem:not(.p-highlight):not(.p-disabled) > .p-menuitem-content:hover .p-menuitem-link .p-submenu-icon {
    color: #eeeeee;
  }
  .p-tieredmenu .p-menuitem-separator {
    border-top: 1px solid #999999;
    margin: 0.25rem 0;
  }
  .p-tieredmenu .p-submenu-icon {
    font-size: 0.875rem;
  }
  .p-tieredmenu .p-submenu-icon.p-icon {
    width: 0.875rem;
    height: 0.875rem;
  }
  .p-inline-message {
    padding: 0.5rem 0.5rem;
    margin: 0;
    border-radius: 3px;
  }
  .p-inline-message.p-inline-message-info {
    background: #b3e5fc;
    border: solid #0891cf;
    border-width: 1px;
    color: #044868;
  }
  .p-inline-message.p-inline-message-info .p-inline-message-icon {
    color: #044868;
  }
  .p-inline-message.p-inline-message-success {
    background: #c8e6c9;
    border: solid #439446;
    border-width: 1px;
    color: #224a23;
  }
  .p-inline-message.p-inline-message-success .p-inline-message-icon {
    color: #224a23;
  }
  .p-inline-message.p-inline-message-warn {
    background: #ffecb3;
    border: solid #d9a300;
    border-width: 1px;
    color: #6d5100;
  }
  .p-inline-message.p-inline-message-warn .p-inline-message-icon {
    color: #6d5100;
  }
  .p-inline-message.p-inline-message-error {
    background: #fdced4;
    border: solid #e60017;
    border-width: 1px;
    color: #73000c;
  }
  .p-inline-message.p-inline-message-error .p-inline-message-icon {
    color: #73000c;
  }
  .p-inline-message .p-inline-message-icon {
    font-size: 1rem;
    margin-right: 0.5rem;
  }
  .p-inline-message .p-icon {
    width: 1rem;
    height: 1rem;
  }
  .p-inline-message .p-inline-message-text {
    font-size: 1rem;
  }
  .p-inline-message.p-inline-message-icon-only .p-inline-message-icon {
    margin-right: 0;
  }
  .p-message {
    margin: 1rem 0;
    border-radius: 3px;
  }
  .p-message .p-message-wrapper {
    padding: 1rem 1.5rem;
  }
  .p-message .p-message-close {
    width: 2rem;
    height: 2rem;
    border-radius: 50%;
    background: transparent;
    transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
  }
  .p-message .p-message-close:hover {
    background: rgba(255, 255, 255, 0.3);
  }
  .p-message .p-message-close:focus-visible {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: none;
  }
  .p-message.p-message-info {
    background: #b3e5fc;
    border: solid #0891cf;
    border-width: 0 0 0 6px;
    color: #044868;
  }
  .p-message.p-message-info .p-message-icon {
    color: #044868;
  }
  .p-message.p-message-info .p-message-close {
    color: #044868;
  }
  .p-message.p-message-success {
    background: #c8e6c9;
    border: solid #439446;
    border-width: 0 0 0 6px;
    color: #224a23;
  }
  .p-message.p-message-success .p-message-icon {
    color: #224a23;
  }
  .p-message.p-message-success .p-message-close {
    color: #224a23;
  }
  .p-message.p-message-warn {
    background: #ffecb3;
    border: solid #d9a300;
    border-width: 0 0 0 6px;
    color: #6d5100;
  }
  .p-message.p-message-warn .p-message-icon {
    color: #6d5100;
  }
  .p-message.p-message-warn .p-message-close {
    color: #6d5100;
  }
  .p-message.p-message-error {
    background: #fdced4;
    border: solid #e60017;
    border-width: 0 0 0 6px;
    color: #73000c;
  }
  .p-message.p-message-error .p-message-icon {
    color: #73000c;
  }
  .p-message.p-message-error .p-message-close {
    color: #73000c;
  }
  .p-message .p-message-text {
    font-size: 1rem;
    font-weight: 400;
  }
  .p-message .p-message-icon {
    font-size: 1.5rem;
    margin-right: 0.5rem;
  }
  .p-message .p-icon {
    width: 1.5rem;
    height: 1.5rem;
  }
  .p-message .p-message-summary {
    font-weight: 700;
  }
  .p-message .p-message-detail {
    margin-left: 0.5rem;
  }
  .p-message-icon-hide .p-message .p-message-icon {
    display: none;
  }
  .p-toast {
    opacity: 0.9;
  }
  .p-toast .p-toast-message {
    margin: 0 0 1rem 0;
    box-shadow: 0 3px 14px 0 rgba(0, 0, 0, 0.3);
    border-radius: 3px;
  }
  .p-toast .p-toast-message .p-toast-message-content {
    padding: 1rem;
    border-width: 0 0 0 4px;
  }
  .p-toast .p-toast-message .p-toast-message-content .p-toast-message-text {
    margin: 0 0 0 1rem;
  }
  .p-toast .p-toast-message .p-toast-message-content .p-toast-message-icon {
    font-size: 2rem;
  }
  .p-toast .p-toast-message .p-toast-message-content .p-icon:not(.p-toast-icon-close-icon) {
    width: 2rem;
    height: 2rem;
  }
  .p-toast .p-toast-message .p-toast-message-content .p-toast-summary {
    font-weight: 700;
  }
  .p-toast .p-toast-message .p-toast-message-content .p-toast-detail {
    margin: 0.5rem 0 0 0;
  }
  .p-toast .p-toast-message .p-toast-icon-close {
    width: 2rem;
    height: 2rem;
    border-radius: 50%;
    background: transparent;
    transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
  }
  .p-toast .p-toast-message .p-toast-icon-close:hover {
    background: rgba(255, 255, 255, 0.3);
  }
  .p-toast .p-toast-message .p-toast-icon-close:focus-visible {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: none;
  }
  .p-toast .p-toast-message.p-toast-message-info {
    background: #b3e5fc;
    border: solid #0891cf;
    border-width: 0 0 0 6px;
    color: #044868;
  }
  .p-toast .p-toast-message.p-toast-message-info .p-toast-message-icon,
  .p-toast .p-toast-message.p-toast-message-info .p-toast-icon-close {
    color: #044868;
  }
  .p-toast .p-toast-message.p-toast-message-success {
    background: #c8e6c9;
    border: solid #439446;
    border-width: 0 0 0 6px;
    color: #224a23;
  }
  .p-toast .p-toast-message.p-toast-message-success .p-toast-message-icon,
  .p-toast .p-toast-message.p-toast-message-success .p-toast-icon-close {
    color: #224a23;
  }
  .p-toast .p-toast-message.p-toast-message-warn {
    background: #ffecb3;
    border: solid #d9a300;
    border-width: 0 0 0 6px;
    color: #6d5100;
  }
  .p-toast .p-toast-message.p-toast-message-warn .p-toast-message-icon,
  .p-toast .p-toast-message.p-toast-message-warn .p-toast-icon-close {
    color: #6d5100;
  }
  .p-toast .p-toast-message.p-toast-message-error {
    background: #fdced4;
    border: solid #e60017;
    border-width: 0 0 0 6px;
    color: #73000c;
  }
  .p-toast .p-toast-message.p-toast-message-error .p-toast-message-icon,
  .p-toast .p-toast-message.p-toast-message-error .p-toast-icon-close {
    color: #73000c;
  }
  .p-galleria .p-galleria-close {
    margin: 0.5rem;
    background: transparent;
    color: #ebedef;
    width: 4rem;
    height: 4rem;
    transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
    border-radius: 50%;
  }
  .p-galleria .p-galleria-close .p-galleria-close-icon {
    font-size: 2rem;
  }
  .p-galleria .p-galleria-close .p-icon-wrapper .p-icon {
    width: 2rem;
    height: 2rem;
  }
  .p-galleria .p-galleria-close:hover {
    background: rgba(255, 255, 255, 0.1);
    color: #ebedef;
  }
  .p-galleria .p-galleria-item-nav {
    background: rgba(0, 0, 0, 0.2);
    color: #aeb6bf;
    width: 4rem;
    height: 4rem;
    transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
    border-radius: 3px;
    margin: 0.5rem 0;
  }
  .p-galleria .p-galleria-item-nav .p-galleria-item-prev-icon,
  .p-galleria .p-galleria-item-nav .p-galleria-item-next-icon {
    font-size: 2rem;
  }
  .p-galleria .p-galleria-item-nav .p-icon-wrapper .p-icon {
    width: 2rem;
    height: 2rem;
  }
  .p-galleria .p-galleria-item-nav:not(.p-disabled):hover {
    background: rgba(0, 0, 0, 0.3);
    color: #ebedef;
  }
  .p-galleria .p-galleria-caption {
    background: rgba(0, 0, 0, 0.5);
    color: #ebedef;
    padding: 1rem;
  }
  .p-galleria .p-galleria-indicators {
    padding: 1rem;
  }
  .p-galleria .p-galleria-indicators .p-galleria-indicator button {
    background-color: #e9ecef;
    width: 1rem;
    height: 1rem;
    transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
    border-radius: 50%;
  }
  .p-galleria .p-galleria-indicators .p-galleria-indicator button:hover {
    background: #eeeeee;
  }
  .p-galleria .p-galleria-indicators .p-galleria-indicator.p-highlight button {
    background: #76b729;
    color: #ffffff;
  }
  .p-galleria.p-galleria-indicators-bottom .p-galleria-indicator, .p-galleria.p-galleria-indicators-top .p-galleria-indicator {
    margin-right: 0.5rem;
  }
  .p-galleria.p-galleria-indicators-left .p-galleria-indicator, .p-galleria.p-galleria-indicators-right .p-galleria-indicator {
    margin-bottom: 0.5rem;
  }
  .p-galleria.p-galleria-indicator-onitem .p-galleria-indicators {
    background: rgba(0, 0, 0, 0.5);
  }
  .p-galleria.p-galleria-indicator-onitem .p-galleria-indicators .p-galleria-indicator button {
    background: rgba(255, 255, 255, 0.4);
  }
  .p-galleria.p-galleria-indicator-onitem .p-galleria-indicators .p-galleria-indicator button:hover {
    background: rgba(255, 255, 255, 0.6);
  }
  .p-galleria.p-galleria-indicator-onitem .p-galleria-indicators .p-galleria-indicator.p-highlight button {
    background: #76b729;
    color: #ffffff;
  }
  .p-galleria .p-galleria-thumbnail-container {
    background: rgba(0, 0, 0, 0.9);
    padding: 1rem 0.25rem;
  }
  .p-galleria .p-galleria-thumbnail-container .p-galleria-thumbnail-prev,
  .p-galleria .p-galleria-thumbnail-container .p-galleria-thumbnail-next {
    margin: 0.5rem;
    background-color: transparent;
    color: #aeb6bf;
    width: 2rem;
    height: 2rem;
    transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
    border-radius: 50%;
  }
  .p-galleria .p-galleria-thumbnail-container .p-galleria-thumbnail-prev:hover,
  .p-galleria .p-galleria-thumbnail-container .p-galleria-thumbnail-next:hover {
    background: rgba(255, 255, 255, 0.1);
    color: #aeb6bf;
  }
  .p-galleria .p-galleria-thumbnail-container .p-galleria-thumbnail-item-content:focus-visible {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: none;
  }
  .p-galleria-mask {
    --maskbg: rgba(0, 0, 0, 0.9);
  }
  .p-image-mask {
    --maskbg: rgba(0, 0, 0, 0.9);
  }
  .p-image-preview-indicator {
    background-color: transparent;
    color: #f8f9fa;
    transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
  }
  .p-image-preview-container:hover > .p-image-preview-indicator {
    background-color: rgba(0, 0, 0, 0.5);
  }
  .p-image-toolbar {
    padding: 1rem;
  }
  .p-image-action.p-link {
    color: #f8f9fa;
    background-color: transparent;
    width: 3rem;
    height: 3rem;
    border-radius: 50%;
    transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
    margin-right: 0.5rem;
  }
  .p-image-action.p-link:last-child {
    margin-right: 0;
  }
  .p-image-action.p-link:hover {
    color: #f8f9fa;
    background-color: rgba(255, 255, 255, 0.6);
  }
  .p-image-action.p-link i {
    font-size: 1.5rem;
  }
  .p-image-action.p-link .p-icon {
    width: 1.5rem;
    height: 1.5rem;
  }
  .p-avatar {
    background-color: #eeeeee;
    border-radius: 3px;
  }
  .p-avatar.p-avatar-lg {
    width: 3rem;
    height: 3rem;
    font-size: 1.5rem;
  }
  .p-avatar.p-avatar-lg .p-avatar-icon {
    font-size: 1.5rem;
  }
  .p-avatar.p-avatar-xl {
    width: 4rem;
    height: 4rem;
    font-size: 2rem;
  }
  .p-avatar.p-avatar-xl .p-avatar-icon {
    font-size: 2rem;
  }
  .p-avatar-group .p-avatar {
    border: 2px solid none;
  }
  .p-badge {
    background: #76b729;
    color: #ffffff;
    font-size: 0.75rem;
    font-weight: 700;
    min-width: 1.5rem;
    height: 1.5rem;
    line-height: 1.5rem;
  }
  .p-badge.p-badge-secondary {
    background-color: #ffffff;
    color: #ffffff;
  }
  .p-badge.p-badge-success {
    background-color: #76b729;
    color: #ffffff;
  }
  .p-badge.p-badge-info {
    background-color: #b3e5fc;
    color: #ffffff;
  }
  .p-badge.p-badge-warning {
    background-color: #a56609;
    color: #ffffff;
  }
  .p-badge.p-badge-danger {
    background-color: #fdced4;
    color: #ffffff;
  }
  .p-badge.p-badge-lg {
    font-size: 1.125rem;
    min-width: 2.25rem;
    height: 2.25rem;
    line-height: 2.25rem;
  }
  .p-badge.p-badge-xl {
    font-size: 1.5rem;
    min-width: 3rem;
    height: 3rem;
    line-height: 3rem;
  }
  .p-chip {
    background-color: #eeeeee;
    color: #ffffff;
    border-radius: 16px;
    padding: 0 0.5rem;
  }
  .p-chip .p-chip-text {
    line-height: 1.5;
    margin-top: 0.25rem;
    margin-bottom: 0.25rem;
  }
  .p-chip .p-chip-icon {
    margin-right: 0.5rem;
  }
  .p-chip .pi-chip-remove-icon {
    margin-left: 0.5rem;
  }
  .p-chip img {
    width: 2rem;
    height: 2rem;
    margin-left: -0.5rem;
    margin-right: 0.5rem;
  }
  .p-chip .pi-chip-remove-icon {
    border-radius: 3px;
    transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
  }
  .p-chip .pi-chip-remove-icon:focus-visible {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: none;
  }
  .p-chip .pi-chip-remove-icon:focus {
    outline: 0 none;
  }
  .p-inplace .p-inplace-display {
    padding: 0.5rem 0.5rem;
    border-radius: 3px;
    transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
  }
  .p-inplace .p-inplace-display:not(.p-disabled):hover {
    background: #eeeeee;
    color: #ffffff;
  }
  .p-inplace .p-inplace-display:focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: none;
  }
  .p-progressbar {
    border: 0 none;
    height: 1.5rem;
    background: #616061;
    border-radius: 3px;
  }
  .p-progressbar .p-progressbar-value {
    border: 0 none;
    margin: 0;
    background: #76b729;
  }
  .p-progressbar .p-progressbar-label {
    color: #ffffff;
    line-height: 1.5rem;
  }
  .p-scrolltop {
    width: 3rem;
    height: 3rem;
    border-radius: 50%;
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.1);
    transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
  }
  .p-scrolltop.p-link {
    background: rgba(0, 0, 0, 0.7);
  }
  .p-scrolltop.p-link:hover {
    background: rgba(0, 0, 0, 0.8);
  }
  .p-scrolltop .p-scrolltop-icon {
    font-size: 1.5rem;
    color: #eeeeee;
  }
  .p-scrolltop .p-icon {
    width: 1.5rem;
    height: 1.5rem;
  }
  .p-skeleton {
    background-color: #eeeeee;
    border-radius: 3px;
  }
  .p-skeleton:after {
    background: linear-gradient(90deg, rgba(255, 255, 255, 0), rgba(255, 255, 255, 0.4), rgba(255, 255, 255, 0));
  }
  .p-tag {
    background: #76b729;
    color: #ffffff;
    font-size: 0.75rem;
    font-weight: 700;
    padding: 0.25rem 0.4rem;
    border-radius: 3px;
  }
  .p-tag.p-tag-success {
    background-color: #76b729;
    color: #ffffff;
  }
  .p-tag.p-tag-info {
    background-color: #b3e5fc;
    color: #ffffff;
  }
  .p-tag.p-tag-warning {
    background-color: #a56609;
    color: #ffffff;
  }
  .p-tag.p-tag-danger {
    background-color: #fdced4;
    color: #ffffff;
  }
  .p-tag .p-tag-icon {
    margin-right: 0.25rem;
    font-size: 0.75rem;
  }
  .p-tag .p-icon {
    width: 0.75rem;
    height: 0.75rem;
  }
  .p-terminal {
    background: none;
    color: #ffffff;
    border: 1px solid #999999;
    padding: 1rem;
  }
  .p-terminal .p-terminal-input {
    font-family: var(--font-family);
    font-feature-settings: var(--font-feature-settings, normal);
    font-size: 1rem;
  }
}
.unread-document-dot {
  height: 10px;
  width: 10px;
}

.p-button:focus {
  box-shadow: none;
}
.p-button.p-button-secondary.p-button-text:enabled:hover, .p-button.p-button-secondary.p-button-text:enabled:active {
  background-color: transparent;
}
.p-button.p-button-secondary.p-button-text:enabled:focus {
  box-shadow: none;
}
.p-button.mobile-button {
  width: max-content;
}
@media only screen and (max-width: 356px) {
  .p-button.mobile-button {
    padding-left: 8px;
    padding-right: 8px;
  }
}

.p-listbox .p-listbox-header {
  display: none;
}
.p-listbox .p-listbox-list .p-listbox-empty-message {
  padding-left: 0;
}

.p-toast .p-toast-message .p-toast-icon-close:hover {
  background: transparent;
}

.p-messages .p-message-wrapper a {
  color: #000000;
}
.p-messages .p-message-wrapper a:hover, .p-messages .p-message-wrapper a:focus {
  color: inherit;
}

.field label {
  margin-bottom: 0.25rem;
}

.p-dialog:has(.accept-right) .p-dialog-header-icons {
  display: none;
}
.p-dialog.p-confirm-dialog .p-confirm-dialog-message {
  margin-left: 0;
}
.p-dialog .p-dialog-header-icons {
  margin-left: auto;
}
.p-dialog .p-dialog-footer {
  display: flex;
}
.p-dialog .p-dialog-footer button {
  width: 50%;
}
.p-dialog .p-dialog-footer button.accept-right {
  justify-self: end;
  width: 25%;
}
.p-dialog .p-dialog-footer button:last-child {
  margin-right: 0;
}
.p-dialog .p-dialog-footer button .p-icon {
  display: none;
}
.p-dialog .p-dialog-footer:has(.accept-right) {
  display: grid;
}
.p-dialog .p-dialog-footer .p-footer {
  display: flex;
  width: 100%;
}

.p-panelmenu .p-panelmenu-panel:first-child .p-panelmenu-header-content > a {
  border-radius: 0;
}
.p-panelmenu .p-panelmenu-panel:last-child .p-panelmenu-header-content:not(.p-highlight) > a {
  border-radius: 0;
}

.p-tabview .p-tabview-nav-btn.p-link:focus {
  box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}
.p-tabview .p-tabview-nav li img {
  height: 20px;
}
.p-tabview .p-tabview-nav li .p-tabview-nav-link:not(.p-disabled):focus {
  box-shadow: none;
}
.p-tabview .p-tabview-nav .p-tabview-nav-link {
  color: var(--surface-700);
  font-weight: 400;
}
.p-tabview .p-tabview-nav .p-highlight .p-tabview-nav-link {
  font-weight: 600;
}

.p-multiselect .p-multiselect-label {
  color: #ffffff;
}

.p-treeselect .p-treeselect-label {
  color: #ffffff;
}

ngx-extended-pdf-viewer .html #mainContainer input {
  accent-color: #76b729;
}
ngx-extended-pdf-viewer .html #mainContainer #toolbarViewer button.toolbarButton:hover {
  color: #76b729;
}
ngx-extended-pdf-viewer .html #mainContainer #toolbarViewer button.toolbarButton:focus {
  border: 1px solid #76b729;
}
@media screen and (max-width: 436px), (max-width: 768px) {
  ngx-extended-pdf-viewer .html #mainContainer #toolbarViewer button.toolbarButton {
    width: 28px;
  }
}
ngx-extended-pdf-viewer .html #mainContainer #findbar button.toolbarButton:hover {
  color: #76b729;
}
ngx-extended-pdf-viewer .html #mainContainer #findbar button.toolbarButton:focus {
  border: 1px solid #76b729;
}
ngx-extended-pdf-viewer .html #mainContainer #findbar input.toolbarField[type=checkbox]:focus {
  outline: none;
}

@media screen and (max-width: 576px) {
  .toolbarField.pageNumber {
    width: 16px !important;
  }
}

@media screen and (max-width: 480px) {
  #toolbarViewerMiddle {
    position: inherit !important;
  }
}
.display-lg-block {
  display: none !important;
}

@media screen and (max-width: 992px) {
  .display-lg-block {
    display: block !important;
  }
  table {
    table-layout: fixed;
  }
  .show-on-mobile {
    display: inline-flex;
  }
  .p-datatable.p-datatable-sm .p-datatable-thead > tr > th,
  .p-datatable.p-datatable-sm .p-datatable-tfoot > tr > td {
    display: none !important;
  }
  .p-datatable.p-datatable-sm .p-datatable-tbody td.document-name {
    display: table-cell;
    width: 100%;
    align-self: stretch;
    align-content: center;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }
  .p-datatable.p-datatable-sm .p-datatable-tbody span.document-name {
    display: table-cell;
    width: 100%;
    align-self: stretch;
    align-content: center;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }
  .p-datatable.p-datatable-sm .p-datatable-tbody > tr > td {
    text-align: left;
    display: block;
    float: left;
    clear: left;
    border: 0 none;
  }
  .p-datatable.p-datatable-sm .p-datatable-tbody > tr > td.hide-on-mobile {
    display: none;
  }
  .p-datatable.p-datatable-sm .p-datatable-tbody > tr > td.context-expand-buttons-holder {
    display: flex;
  }
  .p-datatable.p-datatable-sm .p-datatable-tbody > tr {
    display: flex;
    align-items: center;
    border-bottom: 1px solid #eeeeee;
  }
  .p-datatable.p-datatable-sm .p-datatable-tbody > tr:last-child {
    border-bottom: 1px solid #999999;
  }
}
.p-datatable-thead th.p-sortable-column span.p-sortable-column-badge {
  display: none !important;
}

.p-datatable .p-sortable-column:focus {
  box-shadow: none;
}

.p-datatable .p-sortable-column.p-highlight {
  color: #76b729;
}
.p-datatable .p-sortable-column.p-highlight .p-sortable-column-icon {
  color: #76b729;
}

.p-button.p-button-success.p-button-text,
.p-buttonset.p-button-success > .p-button.p-button-text,
.p-button-success > .p-button.p-button-text {
  color: #76b729;
}
.p-button.p-button-success.p-button-text:hover,
.p-buttonset.p-button-success > .p-button.p-button-text:hover,
.p-button-success > .p-button.p-button-text:hover {
  color: #76b729;
}

.breadcrumb {
  display: flex;
  align-items: center;
  background: var(--headline-bg-color);
  height: 58px;
  overflow-y: auto;
}
.breadcrumb .p-breadcrumb {
  font-style: normal;
  overflow: hidden;
}
.breadcrumb .p-breadcrumb .p-breadcrumb-list {
  height: 100%;
}
.breadcrumb .p-breadcrumb ol > li {
  white-space: nowrap;
}
.breadcrumb .p-breadcrumb ol > li .p-menuitem-link {
  font-weight: 600;
  font-size: 1rem;
}
.breadcrumb .p-breadcrumb ol > li .p-menuitem-link:focus {
  box-shadow: none;
}
.breadcrumb .p-breadcrumb ol > li .p-menuitem-text {
  line-height: normal;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 9rem;
}
.breadcrumb .p-breadcrumb ol > li .p-icon {
  width: 20px;
  height: 20px;
}
.breadcrumb .p-breadcrumb-home homeicon {
  display: none;
}
.breadcrumb .p-breadcrumb-home .p-menuitem-text {
  font-size: 1.25rem;
  line-height: 1.25rem;
}
.breadcrumb .p-menuitem-separator:last-of-type {
  display: none !important;
}
@media (orientation: landscape) and (max-width: 992px) {
  .breadcrumb {
    height: 40px;
    overflow: hidden !important;
  }
}

@media screen and (max-width: 768px) {
  .breadCrumb .p-breadcrumb-home span {
    font-size: 14px;
    font-weight: 400;
  }
  .breadCrumb .p-breadcrumb ul li .p-menuitem-link .p-menuitem-text {
    font-size: 14px;
    font-weight: 400;
  }
}
@media screen and (max-width: 576px) {
  .breadCrumb .p-breadcrumb.p-component {
    height: 40px;
  }
}
.sign {
  display: inline-block;
  width: 12px;
  height: 12px;
  border-radius: 50%;
  margin-right: 3px;
}
.sign-red {
  background-color: #c7362b;
}
.sign-green {
  background-color: #76b729;
}

html {
  -webkit-text-size-adjust: 100%;
}
html #main-wrapper {
  min-height: 100%;
  height: 100%;
  min-width: 320px;
}

body {
  margin: 0;
  min-width: 320px;
  font-family: "Segoe UI" !important;
  background-color: var(--background-color);
  color: var(--text-color);
}
body a {
  color: var(--link-color);
  cursor: pointer;
}
body a:hover {
  color: var(--link-color-hover);
}
body .cursor-default a.p-menuitem-link {
  cursor: default;
}

.p-button.p-button-outlined:not(.p-button-success):not(.p-button-secondary):not(.p-button-danger):not(.menu-burger):not(.p-button-filled) .p-button-icon,
.p-button.p-button-outlined:not(.p-button-success):not(.p-button-secondary):not(.p-button-danger):not(.menu-burger):not(.p-button-filled) .p-button-label {
  color: var(--outlined-btn-color);
}
.p-button.p-button-outlined:not(.p-button-danger):not(.p-button-secondary):enabled:hover, .p-button.p-button-outlined:not(.p-button-danger):not(.p-button-secondary):enabled:active {
  border-color: var(--outlined-btn-color);
  background-color: transparent;
}
.p-button.p-button-filled {
  background-color: var(--surface-20) !important;
}
.p-button.p-button-filled .p-button-label {
  color: var(--surface-10);
}

.p-tree {
  border: none;
}
.p-tree .p-tree-container .p-treenode .p-treenode-content {
  border-bottom: 2px solid #eeeeee;
  border-radius: 0;
}

input .p-disabled {
  background-color: #eeeeee;
  color: #616061;
}

.max-w-600px {
  max-width: 600px;
}

.dynamic-optin-optout-dialog .p-dialog-header {
  background-color: var(--surface-f);
  border-bottom: 1px solid var(--surface-700);
}
.dynamic-optin-optout-dialog .p-dialog-header .p-dialog-title {
  color: var(--text-color);
}
.dynamic-optin-optout-dialog .p-dialog-content {
  padding-bottom: 0;
}

.p-dialog-header {
  align-items: flex-start;
}
.p-dialog-header .p-dialog-header-close.p-link {
  margin-top: -2px;
}

@media screen and (max-width: 768px) {
  .dynamic-optin-optout-dialog {
    width: 90%;
    max-height: 90%;
  }
}
@media (max-width: 480px) {
  .idnow-dialog.p-dialog {
    height: 100vh !important;
    max-height: 100vh;
  }
  .idnow-dialog.p-dialog .p-dialog-content {
    padding: 0;
  }
}
.idnow-dialog.p-dialog .p-dialog-header {
  padding: 0;
}

.npa-dialog .p-dialog-content {
  padding-top: 0.5rem;
}

.bk-dialog-header-min-height .p-dialog-header {
  min-height: 65px;
}

.p-card {
  border: 1px solid var(--surface-400);
}
.p-card .p-card-separator {
  border: 1px solid var(--surface-400);
}

@media screen and (max-width: 992px) {
  .p-calendar .p-datepicker {
    width: 19rem;
  }
  .p-calendar .p-datepicker:has(.p-timepicker) {
    width: auto;
  }
}
.db-register-container.wrapper {
  max-width: 1080px;
}

.custom-paragraph-reg-stepper {
  border: 1px solid #d3d3d3;
  border-radius: 5px;
  padding: 0.6rem;
  min-height: 43px;
}

.break {
  word-break: normal;
  overflow-wrap: anywhere;
}

.grid {
  display: flex !important;
}

.text-danger {
  color: var(--danger-color);
}

.text-success {
  color: var(--success-color);
}

.invert-image-colors {
  filter: invert(0.6);
}

.color-bk-dark-gray {
  color: #616061;
}

.read-only-data {
  background: var(--surface-f);
  color: var(--surface-800);
}
.read-only-data:hover {
  border-color: #c6c6c6;
}

.word-break-all {
  word-break: break-all;
}

.text-break {
  overflow-wrap: anywhere;
}

.white-space-pre-wrap {
  white-space: pre-wrap;
}

.white-space-break-spaces {
  white-space: break-spaces;
}

@media screen and (max-width: 768px) {
  p-toast .p-toast {
    width: 100%;
    top: 0;
    right: 0;
    left: 0;
  }
}
@media screen and (max-width: 768px) and (orientation: portrait) {
  p-toast.is-ios-native .p-toast {
    top: 50px;
  }
}
.truncate-text {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.break-word {
  word-break: break-word;
}

.visually-hidden,
.visually-hidden-focusable:not(:focus):not(:focus-within) {
  position: absolute !important;
  width: 1px !important;
  height: 1px !important;
  padding: 0 !important;
  margin: -1px !important;
  overflow: hidden !important;
  clip: rect(0, 0, 0, 0) !important;
  white-space: nowrap !important;
  border: 0 !important;
}

.p-inputtext::placeholder {
  color: var(--input-placeholder);
}

.p-tooltip.file-name {
  max-width: 50%;
}

.archive-storage-widget {
  background-color: var(--background-color);
  color: var(--text-color);
}

.file-upload-modal .drag-and-drop-area.draggable-area {
  border: 1px dashed #cccccc;
}
.file-upload-modal .drag-and-drop-area.draggable-area.error {
  border-color: var(--danger-color);
}
.file-upload-modal .p-dialog-header {
  border: none;
}
.file-upload-modal .p-fileupload .p-fileupload-buttonbar {
  background-color: transparent;
  color: var(--text-color);
}
.file-upload-modal .upload-icon path {
  fill: var(--text-color);
}
.file-upload-modal .error svg path {
  fill: var(--danger-color);
}
.file-upload-modal .error .error-text {
  color: var(--danger-color);
}
.file-upload-modal .warning-text {
  color: var(--warning-color);
}
.file-upload-modal .quote-red .p-progressbar .p-progressbar-value {
  background: var(--danger-color);
}
.file-upload-modal .quote-red .p-progressbar .p-progressbar-value .p-progressbar-label {
  color: var(--surface-50);
}

.error-page-title {
  color: var(--danger-color);
}